import React from "react";
import {
  IonItem,
  IonAvatar,
  IonImg,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonContent,
  IonChip,
  IonButton,
} from "@ionic/react";

import Section from "./Section";

export default function Description({
  title = "",
  content = "",
  cta = "",
  moreInfo = "",
}) {
  return (
    <Section title={title}>
      <p className="fb-section-content-p">
        {content}
        <p className="fb-section-content-cta">{moreInfo}</p>
      </p>
    </Section>
  );
}
