import { IonButton, IonContent, IonPage } from "@ionic/react";
import { LinkWithQuery } from "../components/LinkWithQuery/LinkWithQuery";
import useData from "../hooks/useData";
import useSearchQuery from "../hooks/useLocationSearch";

import "./pre.css";

const Pre: React.FC = () => {
  const search = useSearchQuery();
  const id = search.get("feed") || "1";
  let dataUrl = search.get("dataUrl");

  const { data } = useData({ id, dataUrl });

  console.log({ data });

  return (
    <IonPage>
      <IonContent fullscreen>
        <div className="pre-modal-container">
          <h1 className="title">Velkommen!</h1>
          {data?.description && (
            <p
              className="description"
              dangerouslySetInnerHTML={{ __html: data?.description }}
            ></p>
          )}
          <LinkWithQuery
            to="/feed"
            style={{ width: "100%", marginTop: "1rem" }}
          >
            <IonButton className="pre-continue instructions-cta">
              Fortsett
            </IonButton>
          </LinkWithQuery>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Pre;
