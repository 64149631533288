import React from "react";
import {
  IonItem,
  IonAvatar,
  IonImg,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonContent,
  IonChip,
  IonButton,
} from "@ionic/react";

import ChevronRight from "../../assets/icons/fb/chevron-right.png";
import "./AboutStore.css";

export default function AboutStore({
  title = "",
  username = "",
  description = "",
  avatar = "",
  name = "",
  followers = "",
  followButton = "",
  followedBy = "",
  viewShopLabel = "",
  viewShopButton = "",
}) {
  return (
    <IonGrid style={{ padding: "16px" }}>
      <IonRow
        className="ion-align-items-center ion-justify-content-between"
        style={{ marginBottom: "16px" }}
      >
        <IonRow className="ion-align-items-center">
          <IonImg className="fb-about-avatar" src={avatar} />

          <IonCol>
            <p className="fb-about-title m-0">{name}</p>

            <p className="fb-about-description m-0">{followers}</p>
          </IonCol>
        </IonRow>

        <IonImg src={ChevronRight} />
      </IonRow>

      <IonButton className="fb-about-cta" color="transparent">
        {viewShopButton}
      </IonButton>
    </IonGrid>
  );
}
