import React from "react";
import {
  IonItem,
  IonAvatar,
  IonImg,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonContent,
  IonChip,
  IonButton,
} from "@ionic/react";

import Section from "./Section";

export default function Description({
  title = "",
  content = "",
  cta = "",
  moreInfo = "",
}) {
  return (
    <Section title={title}>
      <p className="section-content-p">{content}</p>
      <p className="section-content-p">{moreInfo}</p>
      <IonButton className="insta-button secondary-cta" color="transparent">
        {cta}
      </IonButton>
    </Section>
  );
}
