import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonFooter,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonToolbar,
} from "@ionic/react";
import {
  globeOutline,
  ellipsisHorizontal,
  thumbsUpOutline,
  chatboxOutline,
  arrowRedoOutline,
} from "ionicons/icons";
import {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import type { Ad } from "../../global";
import Comments from "../CommentsItem/CommentsItem";

import LikeIcon from "../../assets/icons/like.png";
import LikeFillIcon from "../../assets/icons/like-fill.png";
import CommentIcon from "../../assets/icons/comment.png";
import SendIcon from "../../assets/icons/send.png";

import LikeIconSmall from "../../assets/icons/like-small.svg";
import HeartIconSmall from "../../assets/icons/heart-small.svg";
import CareIconSmall from "../../assets/icons/care-small.svg";
import GlobeIcon from "../../assets/icons/globe.png";

import "./AdItem.scss";
import { LinkWithQuery } from "../LinkWithQuery/LinkWithQuery";
import decorateHashAndMentions from "../../utils/decorateHashAndMentions";

import { FormattedMessage } from "react-intl";

interface AdItemProps {
  item: Ad;
  showComments?: boolean;
  performCommentAction?: boolean;
  disableComments?: boolean;
  onClick?: (e: SyntheticEvent) => void;
  onCommentClick?: (e: SyntheticEvent) => void;
  onLikeClick?: (e: SyntheticEvent) => void;
}

const AdItem: React.FC<AdItemProps> = ({
  item,
  showComments = true,
  performCommentAction = false,
  disableComments = true,
  onClick = () => void 0,
  onCommentClick = () => void 0,
  onLikeClick = () => void 0,
}: AdItemProps) => {
  const [isLiked, setIsLiked] = useState(false);

  useEffect(() => {}, []);

  const likes = useMemo(() => 5 + Math.round(110 * Math.random()), []);

  const clickHandler = useCallback(
    (event: SyntheticEvent) => event.preventDefault(),
    []
  );

  const onLike = useCallback(
    (event: SyntheticEvent) => {
      event.preventDefault();
      setIsLiked(!isLiked);
      if (!isLiked) {
        onLikeClick(event);
      }
    },
    [isLiked, onLikeClick]
  );

  const {
    id,
    title,
    avatar,
    createdTime,
    description,
    image,
    action,
    commentsText,
    comments,
    likeLabel,
    commentLabel,
    sendLabel,
  } = item;

  return (
    <IonItem lines="none" className="creative-item" id={`aoi_${id}_POST`}>
      <IonCard>
        <IonCardHeader>
          <IonToolbar>
            <IonItem>
              <IonAvatar slot="start">
                <IonImg src={avatar} />
              </IonAvatar>
              <IonLabel>
                <h2>{title}</h2>
                <p className="meta">
                  {createdTime} •{" "}
                  <IonImg className="globe-icon" src={GlobeIcon}></IonImg>
                </p>
              </IonLabel>
            </IonItem>
            <IonButton
              slot="end"
              fill="clear"
              onClick={clickHandler}
              color="dark"
            >
              <IonIcon icon={ellipsisHorizontal}></IonIcon>
            </IonButton>
          </IonToolbar>
        </IonCardHeader>
        <IonCardContent>
          {description && (
            <IonLabel
              dangerouslySetInnerHTML={{
                __html: decorateHashAndMentions(description),
              }}
            />
          )}
          <img
            src={image}
            alt={title}
            onClick={onClick}
            id={`aoi_${id}_CREATIVE`}
          />
          {action && (
            <div className="action">
              <aside>
                {action.target && (
                  <IonLabel color="dark" className="target">
                    {action.target}
                  </IonLabel>
                )}
                {action.title && (
                  <IonLabel className="title">{action.title}</IonLabel>
                )}
                {action.description && (
                  <IonLabel color="dark">{action.description}</IonLabel>
                )}
              </aside>
              <IonButton>{action.buttonText}</IonButton>
            </div>
          )}
        </IonCardContent>
        <IonFooter>
          <IonToolbar>
            <IonLabel slot="start">
              <IonIcon src={LikeIconSmall}></IonIcon>
              <IonIcon src={HeartIconSmall}></IonIcon>
              <IonIcon src={CareIconSmall}></IonIcon>
              {isLiked ? `You and ${likes} others` : likes}
              {/* Rosa padilla and 14 others */}
            </IonLabel>

            <IonLabel slot="end" onClick={onCommentClick}>
              {performCommentAction && !disableComments ? (
                <LinkWithQuery to={`/feed/${id}`}>{commentsText}</LinkWithQuery>
              ) : (
                commentsText
              )}
            </IonLabel>
          </IonToolbar>
          <IonButtons>
            <IonButton size="small" color="dark" onClick={onLike}>
              <IonImg
                slot="start"
                src={isLiked ? LikeFillIcon : LikeIcon}
                className={`${isLiked ? "like-animation" : ""}`}
              />
              <IonLabel className={`${isLiked ? "liked" : ""}`}>
                {likeLabel || "like"}
              </IonLabel>
            </IonButton>
            <IonButton size="small" onClick={onCommentClick} color="dark">
              {performCommentAction && !disableComments ? (
                <LinkWithQuery to={`/feed/${id}`}>
                  <IonImg slot="start" src={CommentIcon} />
                  <IonLabel>
                    <FormattedMessage defaultMessage="Comment" />
                    {commentLabel || "Comment"}
                  </IonLabel>
                </LinkWithQuery>
              ) : (
                <>
                  <IonImg slot="start" src={CommentIcon} />
                  <IonLabel>{commentLabel || "Comment"} </IonLabel>
                </>
              )}
            </IonButton>
            <IonButton size="small" onClick={clickHandler} color="dark">
              <IonImg slot="start" src={SendIcon}></IonImg>
              <IonLabel>{sendLabel || "Send"}</IonLabel>
            </IonButton>
          </IonButtons>
        </IonFooter>
        {showComments && (
          <div className="comments">
            <Comments comments={comments ?? []} />
          </div>
        )}
      </IonCard>
    </IonItem>
  );
};

export default AdItem;
