import React from "react";
import {
  IonItem,
  IonAvatar,
  IonImg,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonContent,
  IonChip,
  IonButton,
} from "@ionic/react";

import ChevronDownIcon from "../../assets/icons/fb/chevron-down.png";

import "./Section.css";

export default function Section({
  title = "",
  customIcon,
  children = null,
  isOpenDefault = true,
}) {
  const [isOpen, setIsOpen] = React.useState(isOpenDefault);

  const toggle = React.useCallback(() => {
    if (!customIcon) setIsOpen(!isOpen);
  }, [customIcon, isOpen]);

  return (
    <IonGrid style={{ padding: "16px" }}>
      {title && (
        <IonRow
          className="m-0 ion-align-items-center ion-justify-content-between"
          onClick={toggle}
        >
          <p className="fb-section-title">{title}</p>

          <p className="fb-section-see-all-bttn">
            {isOpen ? "Hide" : "See All"}
          </p>
        </IonRow>
      )}

      {isOpen && (
        <IonRow className="p-0" style={{ margin: "16px 0 0 0 " }}>
          <IonCol className="m-0 p-0">{children}</IonCol>
        </IonRow>
      )}
    </IonGrid>
  );
}
