import React from "react";
import yaml from "js-yaml";
import { useQuery } from "react-query";

import type { DataSchema } from "../global";
import type { DataSchema as ShopDataSchema } from "../shop";

interface UseData {
  id?: string | null;
  dataUrl?: string | null;
  folder?: string | null;
}

export const FEED_STORAGE_REF = "ACTIVE_FEED_ID";

export default function useData({ id, dataUrl, folder = "" }: UseData) {
  React.useEffect(() => {
    const prevFeed = localStorage.getItem(FEED_STORAGE_REF) as string;
    console.log({ id, prevFeed });

    if (prevFeed && id && prevFeed !== id) {
      localStorage.clear();
      localStorage.setItem(FEED_STORAGE_REF, String(id));
    }
  }, [id]);

  return useQuery(
    "data",
    async () => {
      if (id) {
        dataUrl = `/data${folder}/${id}.yaml`;
      }

      if (!id && !dataUrl) {
        throw new Error("Wrong id");
      }

      try {
        if (!dataUrl) {
          return;
        }

        let res = await fetch(dataUrl).catch((err) => null);

        console.log(res);

        if (!res?.ok) {
          dataUrl = `/data${folder}/1.yaml`;
          // @ts-ignore
          res = await fetch(dataUrl);
        }

        // @ts-ignore
        let doc = await res.text();

        const isShop = folder?.includes("shop");

        if (isShop) {
          return yaml.load(doc) as ShopDataSchema;
        } else {
          return yaml.load(doc) as DataSchema;
        }
      } catch (e) {
        throw e;
      }
    },
    { cacheTime: 50000 }
  );
}
