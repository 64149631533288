import React from "react";
import yaml from "js-yaml";
import { useQuery } from "react-query";

import type { DataSchema as ShopDataSchema } from "../shop";

interface UseShopData {
  pricepoint?: string | null;
  productId?: string | null;
}

export const META_FEED_STORAGE_REF = "META_ACTIVE_FEED_ID";

export function getDocumentName({ pricepoint = "1", productId = "1" }) {
  const id = `pp${pricepoint}_pr${productId}`;
  return { url: `/data/shop/${id}.yaml`, id };
}

export default function useShopData({
  pricepoint = "",
  productId = "",
}: UseShopData) {
  const [data, setData] = React.useState({
    shop: { product: { name: null } },
    timeoutMinutes: 3,
  });
  const [feedId, setFeedId] = React.useState(null);

  React.useEffect(() => {
    const prevFeed = localStorage.getItem(META_FEED_STORAGE_REF) as string;
    console.log({ feedId, prevFeed });

    if (feedId && prevFeed !== feedId) {
      localStorage.clear();
      localStorage.setItem(META_FEED_STORAGE_REF, String(feedId));
    }
  }, [feedId]);

  // @ts-ignore
  React.useEffect(async () => {
    const hasParameters = pricepoint && productId;
    if (!hasParameters) {
      throw new Error("Missing parameter");
    }

    const { url, id } = getDocumentName({ pricepoint, productId });
    console.log({ url, id });

    try {
      if (!url) {
        return () => {};
      }

      let res = await fetch(url).catch((err) => null);

      // TODO: fallback default document
      if (!res?.ok) {
        return;
        // const fallbackUrl = `/data/shop/1.yaml`;
        // // @ts-ignore
        // res = await fetch(fallbackUrl);
      }

      // @ts-ignore
      let doc = await res.text();

      const data = (await yaml.load(doc)) as ShopDataSchema;
      // @ts-ignore
      setData(data);
      // @ts-ignore
      setFeedId(id);
    } catch (e) {
      throw e;
    }
  }, []);

  return {
    data,
    feedId,
  };
}
