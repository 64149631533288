import { FC } from "react";

import {
  IonCard,
  IonCardHeader,
  IonItem,
  IonLabel,
  IonToolbar,
} from "@ionic/react";

import "./DividerItem.scss";

interface DividerProps {
  title: string;
}

const Divider: FC<DividerProps> = ({ title }: DividerProps) => {
  return (
    <IonItem lines="none" className="divider-item">
      {title}
    </IonItem>
  );
};

export default Divider;
