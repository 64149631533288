import React, { createElement } from "react";
import {
  IonButton,
  IonContent,
  IonGrid,
  IonImg,
  IonPage,
  IonRow,
  useIonRouter,
} from "@ionic/react";
import VisibilitySensor from "react-visibility-sensor";

import AudioIcon from "../../assets/icons/vg/audio.svg";

const BannerAd = ({
  id: defaultId,
  src = "",
  hasMargin = false,
  isSeen = false,
  onVisibilityChange = (x) => (y) => {},
  onAdClick = (x) => (y) => {},
  containment,
  aspectRatio = 16 / 9 + "",
  ...rest
}) => {
  const ref = React.useRef(null);
  const [muted, setMuted] = React.useState(true);

  React.useEffect(() => {
    const video = ref?.current;

    if (!video) return;

    var isPlaying =
      video?.currentTime > 0 &&
      !video?.paused &&
      !video?.ended &&
      video?.readyState > video?.HAVE_CURRENT_DATA;

    if (!isPlaying) {
      video?.play();
    }
  }, []);

  const onMuteToggle = React.useCallback(
    (e) => {
      e.stopPropagation();
      setMuted(!muted);
    },
    [muted]
  );
  const id = React.useMemo(
    () => `${defaultId}${isSeen ? "_2" : ""}`,
    [defaultId, isSeen]
  );

  return (
    <VisibilitySensor
      partialVisibility
      offset={{ top: 40, bottom: 40 }}
      containment={containment}
      onChange={onVisibilityChange({ id })}
    >
      <div
        {...rest}
        onClick={onAdClick({ id })}
        style={{ margin: hasMargin ? "1rem 0" : 0 }}
      >
        {/* <IonImg src={src} style={{ width: "100%" }} /> */}
        <div
          style={{
            position: "relative",
            height: "fit-content",
            width: "100%",
            maxWidth: "100%",
            minWidth: "100%",
            aspectRatio: aspectRatio + "",
            overflow: "hidden",
          }}
          id={id}
        >
          <div
            className="vg-video-player-icon"
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              zIndex: 2,
              width: "fit-content",
              padding: "10px",
            }}
            onClick={onMuteToggle}
          >
            <img src={AudioIcon} />
            <p
              style={{
                fontWeight: "700",
                fontSize: "14px",
                lineHeight: "13px",
                color: "white",
                marginLeft: "8px",
              }}
            >
              Skru på lyd
            </p>
          </div>

          <video
            ref={ref}
            autoPlay
            playsInline
            muted={muted}
            loop
            // controls
            style={{
              maxWidth: "100%",

              minWidth: "100%",
              minHeight: "100%",

              /* Setting width & height to auto prevents the browser from stretching or squishing the video */
              width: "auto",
              height: "auto",

              /* Center the video */
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%,-50%)",
            }}
          >
            <source id="mp4" src={src} type="video/mp4" />
            <p>Your user agent does not support the HTML5 Video element.</p>
          </video>
        </div>
      </div>
    </VisibilitySensor>
  );
};

export default BannerAd;
