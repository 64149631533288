import { IonButton, IonContent, IonPage } from "@ionic/react";
import React from "react";
import { LinkWithQuery } from "../components/LinkWithQuery/LinkWithQuery";
import { FEED_STORAGE_REF } from "../hooks/useData";
import useSearchQuery from "../hooks/useLocationSearch";

import "./VgPre.css";
import { clearVideoSeen } from "./VgTV";

const description = `Du blir nå lenket til et nettside hvor du kan bla opp og ned.<br/><br/>
For å gå videre i undersøkelsen må du klikke på en video-artikkel.<br/><br/>
Du kan klikke på <b>"tilbake til forside"</b> nederst på siden etter du har vært inne på video-artikkelen.<br/><br/>
Når du er ferdig trykker du på <b>"fortsett"</b> knappen nederst på forsiden.`;

const Pre: React.FC = () => {
  const search = useSearchQuery();
  const id = search.get("feed") || "1";

  React.useEffect(() => {
    localStorage.clear();

    localStorage.setItem(FEED_STORAGE_REF, String(id));
  }, []);

  return (
    <IonPage>
      <IonContent fullscreen>
        <div className="pre-modal-container">
          <h1 className="title">Velkommen!</h1>
          {description && (
            <p
              className="vg-pre-description"
              dangerouslySetInnerHTML={{ __html: description }}
            ></p>
          )}
          <LinkWithQuery
            to="/feed"
            style={{ width: "100%", marginTop: "1rem" }}
          >
            <IonButton className="pre-continue instructions-cta">
              Fortsett
            </IonButton>
          </LinkWithQuery>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Pre;
