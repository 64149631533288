import React from "react";
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonPage,
  IonRow,
} from "@ionic/react";
import { LinkWithQuery } from "../components/LinkWithQuery/LinkWithQuery";

import "./PreShop.css";

const WelcomeShop: React.FC = () => {
  return (
    <IonPage>
      <IonContent fullscreen>
        <div className="pre-modal-container">
          <h1 className="question">Welcome</h1>
          {/* @ts-ignore */}
          <p className="welcome-description">
            You will now be asked to select a product type of interest.
            <br />
            <br />
            Feel free to scroll through the product page as you normally would,
            and click on any products of interest.
            <br />
            <br />
            Once you are satisfied you can exit the feed at the bottom. Thank
            you.
          </p>
          <LinkWithQuery
            to="/shop/pre"
            style={{ width: "100%", marginTop: "1rem" }}
          >
            <IonButton className="">Next Step</IonButton>
          </LinkWithQuery>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default WelcomeShop;
