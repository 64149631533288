import { FC, SyntheticEvent, useCallback, useRef } from "react";
import { Slideshow, SlideshowItem } from "../../global";

import VisibilitySensor from "react-visibility-sensor";

import useMetric from "../../hooks/useMetric";

import {
  IonAvatar,
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonToolbar,
} from "@ionic/react";
import { ellipsisHorizontal } from "ionicons/icons";

import Arrow from "../../assets/icons/arrow-blue.png";
import Chevron from "../../assets/icons/chevron-right-circle.png";

import "./SlideshowItem.scss";

interface SlideShowProps {
  item: Slideshow;
  onCTAClick?: (item: SlideshowItem) => void;
  onFullCardClick?: (item: SlideshowItem) => void;
}

const SimpleItem = ({
  item,
  onClick,
}: {
  item: SlideshowItem;
  onClick: () => void;
}) => {
  const { title, subtitle } = item;

  return (
    <div className="simple-item" onClick={onClick}>
      <IonLabel className="title">{title}</IonLabel>
      {subtitle && <IonLabel className="subtitle">{subtitle}</IonLabel>}
    </div>
  );
};

const ButtonItem = ({
  item,
  onClick,
}: {
  item: SlideshowItem;
  onClick: () => void;
}) => {
  const { title, buttonText } = item;
  return (
    <div className="button-item" onClick={onClick}>
      <IonLabel className="title">{title}</IonLabel>
      <IonButton strong expand="block">
        {buttonText}
        <IonImg slot="end" src={Arrow} />
      </IonButton>
    </div>
  );
};

const FullItem = ({
  item,
  onClick,
}: {
  item: SlideshowItem;
  onClick: () => void;
}) => {
  const { title, image } = item;
  return (
    <div className="full-item" onClick={onClick}>
      <img src={image} alt={item.title} />
      <IonImg slot="end" src={Chevron} className="cta" />
      <IonLabel className="title">{title}</IonLabel>
    </div>
  );
};

const SlideShow: FC<SlideShowProps> = ({
  item,
  onCTAClick = () => void 0,
  onFullCardClick = () => void 0,
}: SlideShowProps) => {
  const { track } = useMetric();

  const containmentContainer = useRef<HTMLIonContentElement>(null);

  const clickHandler = useCallback(
    (event: SyntheticEvent) => event.preventDefault(),
    []
  );

  const visibilityHandler = useCallback(
    (item: SlideshowItem) => (isVisible: boolean) => {
      if (isVisible) {
        track("ad-on-page", item);
      } else {
        if (containmentContainer.current) {
          track("ad-off-page", item);
        }
      }
    },
    [track]
  );

  return (
    <IonItem className="slideshow-item">
      <IonCard>
        <IonCardHeader>
          <IonToolbar>
            <IonItem>
              {item.avatar && (
                <IonAvatar slot="start">
                  <IonImg src={item.avatar} />
                </IonAvatar>
              )}
              <IonLabel>
                <h2>{item.title}</h2>
                {item.subtitle && <p className="meta">{item.subtitle}</p>}
              </IonLabel>
            </IonItem>
            <IonButton
              slot="end"
              fill="clear"
              onClick={clickHandler}
              color="dark"
            >
              <IonIcon icon={ellipsisHorizontal}></IonIcon>
            </IonButton>
            <IonLabel className="description">{item.description}</IonLabel>
          </IonToolbar>
        </IonCardHeader>
        <IonCardContent ref={containmentContainer}>
          {item.items.map((item, i) => (
            <VisibilitySensor
              partialVisibility
              offset={{ top: 180, bottom: 180 }}
              key={item.id}
              onChange={visibilityHandler(item)}
            >
              <IonCard key={`${item.title}-${i}`}>
                {/* <IonButton shape="round" className="close-action">
                <IonIcon slot="icon-only" size="small" icon={closeOutline} />
              </IonButton> */}
                {item.type !== "full" && (
                  <img
                    src={item.image}
                    alt={item.title}
                    onClick={() => onCTAClick(item)}
                  />
                )}
                <IonCardContent>
                  {item.type !== "full" ? (
                    !item.buttonText ? (
                      <SimpleItem
                        item={item}
                        onClick={() => onCTAClick(item)}
                      />
                    ) : (
                      <ButtonItem
                        item={item}
                        onClick={() => onCTAClick(item)}
                      />
                    )
                  ) : (
                    <FullItem
                      item={item}
                      onClick={() => onFullCardClick(item)}
                    />
                  )}
                </IonCardContent>
              </IonCard>
            </VisibilitySensor>
          ))}
        </IonCardContent>
      </IonCard>
    </IonItem>
  );
};

export default SlideShow;
