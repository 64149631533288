import { IonGrid } from "@ionic/react";

export default function Divider({}) {
  return (
    <IonGrid
      style={{
        height: "8px",
        width: "100%",
        background: "#FAFAFA",
      }}
    />
  );
}
