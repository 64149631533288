import React from "react";
import {
  IonItem,
  IonAvatar,
  IonImg,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonContent,
  IonChip,
  IonButton,
} from "@ionic/react";

import "./Reviews.css";
import Section from "./Section";
import { Comment } from "./Reviews";

export default function Questions({
  title = "",
  cta = "",
  stars = 0,
  count = 0,
  comments = [],
}) {
  return (
    <Section title={title} isOpenDefault={false}>
      <IonRow>
        {comments.map((comment) => (
          <IonCol>
            <Comment {...comment} doesHaveStars={false} />

            {comment.answer && (
              <Comment {...comment.answer} isAnswer doesHaveStars={false} />
            )}
          </IonCol>
        ))}
      </IonRow>

      <IonRow>
        <IonButton className="fb-button secondary-cta" color="transparent">
          {cta}
        </IonButton>
      </IonRow>
    </Section>
  );
}
