import React from "react";
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonPage,
  IonRow,
} from "@ionic/react";
import { Link, useLocation } from "react-router-dom";

import "./PreShop.css";

const SUNGLASSES_URL = "/assets/tmp/sunglasses.png";

const PreShop: React.FC = () => {
  React.useEffect(() => {
    localStorage.clear();
  }, []);

  return (
    <IonPage>
      <IonContent>
        <div className="pre-shop-container">
          <h1 className="question">Which products do you prefer to shop?</h1>
          {/* <p className="answer">I prefer products for:</p> */}

          <IonGrid
            style={{
              width: "100%",
              maxHeight: "100%",
              height: "100%",
              marginBottom: "1rem",
            }}
          >
            <IonRow>
              <IonCol>
                <p className="answer">Apparel Men</p>
              </IonCol>
              <IonCol>
                <p className="answer">Apparel Women</p>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <Image
                  src={
                    "https://storage.googleapis.com/stimuli/Meta_shop/Main%20Product%20/Main__G1_01.jpg"
                  }
                  productId="1"
                />
              </IonCol>
              <IonCol>
                <Image
                  src={
                    "https://storage.googleapis.com/stimuli/Meta_shop/Main%20Product%20/Main_G2_01.jpg"
                  }
                  productId="2"
                />
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <p className="answer">Beauty and Health: Cosmetics</p>
              </IonCol>
              <IonCol>
                <p className="answer">Beauty and Health: Care</p>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <Image
                  src={
                    "https://storage.googleapis.com/stimuli/Meta_shop/Main%20Product%20/Main_G3_01.jpg"
                  }
                  productId="3"
                />
              </IonCol>
              <IonCol>
                <Image
                  src={
                    "https://storage.googleapis.com/stimuli/Meta_shop/Main%20Product%20/Main_G4_01.jpg"
                  }
                  productId="4"
                />
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <p className="answer">Home and Garden</p>
              </IonCol>
              <IonCol>
                <p className="answer">Sportswear</p>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <Image
                  src={
                    "https://storage.googleapis.com/stimuli/Meta_shop/Main%20Product%20/Main_G5_01.png"
                  }
                  productId="5"
                />
              </IonCol>
              <IonCol>
                <Image
                  src={
                    "https://storage.googleapis.com/stimuli/Meta_shop/Main%20Product%20/Main_G6_01.png"
                  }
                  productId="6"
                />
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      </IonContent>
    </IonPage>
  );
};

function Image({ src = "", productId = "" }) {
  const { search } = useLocation();

  const redirectUrl = React.useMemo(() => {
    const currentQueryParams = search.toString();

    const newQueryParms = `productId=${productId}`;

    const url =
      currentQueryParams !== ""
        ? `/fb/shop${currentQueryParams}&${newQueryParms}`
        : `/fb/shop?${newQueryParms}`;

    return url;
  }, [search, productId]);

  return (
    <a href={redirectUrl} style={{ width: "100%" }}>
      <div
        style={{
          aspectRatio: "1",
          background: "#F6F6F6",
          border: "1px solid #f1f1f1",
          borderRadius: "20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          // padding: "1rem",
          overflow: "hidden",
        }}
      >
        <IonImg src={src} />
      </div>
    </a>
  );
}

export default PreShop;
