import React from "react";
import {
  IonItem,
  IonAvatar,
  IonImg,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonContent,
  IonChip,
  IonButton,
} from "@ionic/react";
import VisibilitySensor from "react-visibility-sensor";

import BookmarkOutlineIcon from "../../assets/icons/insta/bookmark-outline.png";
import BookmarkFillIcon from "../../assets/icons/insta/bookmark-fill.png";

import "./SimilarProducts.css";
import SeeAllSection from "./SeeAllSection";
import { shuffle } from "../../pages/Feed";

export default function SimilarProducts({
  title = "",
  products = [],
  onProductClick = () => {},
  onVisibilityChange = () => () => {},
  containment,
}) {
  const [grouped, setGrouped] = React.useState([]);
  React.useEffect(() => {
    const products2 = [
      ...products,
      // ...shuffle(products).map((p, order) => ({ ...p, order })),
    ];
    const groupedProducts = [];
    while (products2.length) {
      groupedProducts.push(products2.splice(0, 2));
    }
    setGrouped(groupedProducts);
  }, [products]);

  console.log({ grouped });
  return (
    <SeeAllSection title={title}>
      <IonGrid className="m-0 p-0">
        {grouped.map((group) => (
          <IonRow className="row-no-wrap">
            {group.map((product) => (
              <VisibilitySensor
                partialVisibility
                offset={{ top: 40, bottom: 40 }}
                containment={containment}
                onChange={onVisibilityChange(product)}
              >
                <ProductCard {...product} onClick={onProductClick(product)} />
              </VisibilitySensor>
            ))}
          </IonRow>
        ))}
      </IonGrid>
    </SeeAllSection>
  );
}

export function ProductCard({
  id = "",
  src = "",
  name = "",
  price = "",
  discount = "",
  isBookmarked = false,
  brand = "",
  onClick = () => {},
}) {
  const [classes, setClasses] = React.useState("");
  const hasDiscount = React.useMemo(() => Boolean(discount), [discount]);

  const onAnimationStart = React.useCallback(() => {
    setTimeout(() => setClasses("fb-product-grow-animation"), 100);
    setTimeout(() => setClasses(""), 1500);
  }, []);

  return (
    <IonCol
      className={classes}
      onClick={() => {
        onClick();
        onAnimationStart();
      }}
    >
      <IonRow className="product-card-img-wrapper">
        <IonImg className="product-card-img" src={src} />
        {hasDiscount && <p className="product-sale-badge">Sale</p>}
      </IonRow>

      <IonRow>
        <IonCol className="product-info-wrapper">
          <IonRow className="ion-justify-content-between">
            <p className="product-card-name">{name}</p>
            {isBookmarked ? (
              <IonImg
                className="product-bookmark-icon"
                src={BookmarkFillIcon}
              />
            ) : (
              <IonImg
                className="product-bookmark-icon"
                src={BookmarkOutlineIcon}
              />
            )}
          </IonRow>

          <IonRow>
            {hasDiscount && <p className="product-card-discount">{discount}</p>}
            <p
              className={`product-card-price ${
                hasDiscount ? "line-through" : ""
              }`}
            >
              {price}
            </p>
          </IonRow>
          {brand && <p className="product-card-price">{brand}</p>}
        </IonCol>
      </IonRow>
    </IonCol>
  );
}
