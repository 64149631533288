import React from "react";
import {
  IonItem,
  IonAvatar,
  IonImg,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonContent,
  IonChip,
  IonButton,
} from "@ionic/react";

import ChevronRightIcon from "../../assets/icons/insta/chevron-right.png";

import "./StoreReminder.css";

export default function StoreReminder({
  title = "",
  username = "",
  avatar = "",
}) {
  return (
    <IonGrid className="m-0 p-0">
      <IonRow className="ion-align-items-center" style={{ margin: "16px" }}>
        <IonImg className="store-reminder-avatar" src={avatar} />

        <IonCol>
          <p className="store-reminder-title">{title}</p>
          <p className="store-reminder-username">{username}</p>
        </IonCol>

        <IonImg className="store-reminder-icon" src={ChevronRightIcon} />
      </IonRow>
    </IonGrid>
  );
}
