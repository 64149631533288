import React from "react";
import {
  IonItem,
  IonAvatar,
  IonImg,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/react";
import VerifiedIcon from "../../assets/icons/insta/verified-insta.png";
import BackIcon from "../../assets/icons/insta/back-arrow.svg";
import CartIcon from "../../assets/icons/insta/cart.svg";
import MenuDotsIcon from "../../assets/icons/insta/menu-dots.svg";

import "./Header.css";

export default function Header({ avatar = "", name = "", isVerified = false }) {
  return (
    <IonGrid className="fb-header">
      <IonRow className="ion-align-items-center ion-justify-content-between">
        <IonRow className="ion-align-items-center">
          <IonImg className="back-icon" src={BackIcon} />

          <IonImg className="avatar" src={avatar} />
          <p className="shop-name">{name}</p>
          {isVerified && (
            <IonImg className="verified-icon" src={VerifiedIcon}></IonImg>
          )}
        </IonRow>

        <IonRow className="ion-align-items-center">
          <IonImg className="cart-icon" src={CartIcon} />
          <IonImg src={MenuDotsIcon} />
        </IonRow>
      </IonRow>
    </IonGrid>
  );
}
