import {
  IonAvatar,
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonToolbar,
} from "@ionic/react";
import { ellipsisHorizontal } from "ionicons/icons";
import { SyntheticEvent, useCallback } from "react";
import type { BrandBox } from "../../global";

import Arrow from "../../assets/icons/arrow.png";
import Follow from "../../assets/icons/follow.png";
import Bookmark from "../../assets/icons/bookmark.png";
import FbRainbow from "../../assets/icons/fb-rainbow.png";
import Star from "../../assets/icons/star.png";

import "./BrandId.scss";

interface BrandIdItemProps {
  item: BrandBox;
  onClick?: (event: SyntheticEvent) => void;
  onContentClick?: (event: SyntheticEvent) => void;
}

const BrandId: React.FC<BrandIdItemProps> = ({
  item: {
    id,
    title,
    subtitle,
    avatar,
    image,
    buttonText,
    insetTitle,
    insetSubtitle,
    joinedText,
    starText,
  },
  onClick = () => void 0,
  onContentClick = () => void 0,
}: BrandIdItemProps) => {
  const clickHandler = useCallback(
    (event: SyntheticEvent) => event.preventDefault(),
    []
  );

  return (
    <IonItem className="brandid-item">
      <IonCard>
        <IonCardHeader>
          <IonToolbar>
            <IonItem>
              <IonLabel>
                <h2>{title}</h2>
                <p className="meta">{subtitle}</p>
              </IonLabel>
            </IonItem>
            <IonButton
              slot="end"
              fill="clear"
              onClick={clickHandler}
              color="dark"
            >
              <IonIcon icon={ellipsisHorizontal}></IonIcon>
            </IonButton>
          </IonToolbar>
        </IonCardHeader>
        <IonCardContent className="content">
          <IonCard>
            <IonImg
              className="background-image"
              src={image}
              onClick={onContentClick}
            ></IonImg>
            <IonCardContent>
              <IonAvatar className="inset" onClick={onContentClick}>
                <IonImg src={avatar}></IonImg>
              </IonAvatar>
              <aside onClick={onContentClick}>
                <IonButton color="light" size="large" shape="round">
                  <IonImg slot="icon-only" src={Follow} />
                </IonButton>
                <IonButton color="light" size="large" shape="round">
                  <IonImg slot="icon-only" src={Bookmark} />
                </IonButton>
              </aside>
              <IonLabel onClick={onContentClick}>
                <h2>{insetTitle}</h2>
                <p className="meta">{insetSubtitle}</p>
              </IonLabel>
              <div className="stats" onClick={onContentClick}>
                <div>
                  <IonImg src={FbRainbow} />
                  <span>{joinedText}</span>
                </div>
                <div>
                  <IonImg src={Star} />
                  <span>{starText}</span>
                </div>
              </div>
              <IonButton
                className="cta-button"
                expand="block"
                size="large"
                onClick={onClick}
              >
                {buttonText}
                <IonImg slot="end" src={Arrow} alt="Arrow"></IonImg>
              </IonButton>
            </IonCardContent>
          </IonCard>
        </IonCardContent>
      </IonCard>
    </IonItem>
  );
};

export default BrandId;
