import React from "react";
import {
  IonItem,
  IonAvatar,
  IonImg,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonContent,
  IonChip,
  IonButton,
} from "@ionic/react";

import Section from "./Section";
import "./AboutStore.css";

export default function AboutStore({
  title = "",
  name = "",
  description = "",
  avatar = "",
  followButton = "",
  followedBy = "",
  viewShopLabel = "",
  viewShopButton = "",
}) {
  return (
    <Section title={title}>
      <IonRow
        className="ion-align-items-center"
        style={{ margin: "10px auto 20px auto" }}
      >
        <IonImg className="about-avatar" src={avatar} />

        <IonCol>
          <IonRow>
            <p className="about-title m-0">{name}</p>
            <p className="about-title m-0">&nbsp;·&nbsp;</p>
            <p className="about-title m-0">{followButton}</p>
          </IonRow>

          {/* <p className="about-description m-0">{description}</p> */}

          <p className="about-description-sm m-0">{followedBy}</p>
          <p className="about-description-sm m-0">{viewShopLabel}</p>
        </IonCol>
      </IonRow>

      <IonButton className="insta-button secondary-cta" color="transparent">
        {viewShopButton}
      </IonButton>
    </Section>
  );
}
