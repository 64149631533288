import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonFooter,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonToolbar,
} from "@ionic/react";
import { ellipsisHorizontal } from "ionicons/icons";
import { SyntheticEvent, useCallback } from "react";
import type { Organic } from "../../global";

import HeartIcon from "../../assets/icons/instagram-heart.png";
import CommentIcon from "../../assets/icons/instagram-comment.png";
import DirectIcon from "../../assets/icons/instagram-direct.png";
import SaveIcon from "../../assets/icons/instagram-save.png";

import "./FeedItemInstagram.scss";

interface FeedItemProps {
  item: Organic;
}

const FeedItemInstagram: React.FC<FeedItemProps> = ({
  item: {
    id,
    title,
    subtitle,
    avatar,
    createdTime,
    description,
    image,
    comments,
    commentsText,
    likesText,
  },
}: FeedItemProps) => {
  const clickHandler = useCallback(
    (event: SyntheticEvent) => event.preventDefault(),
    []
  );

  return (
    <IonItem className="feed-item-instagram">
      <IonCard>
        <IonCardHeader>
          <IonToolbar>
            <IonItem>
              <IonAvatar slot="start">
                <IonImg src={avatar} />
              </IonAvatar>
              <IonLabel>
                <h2>{title}</h2>
              </IonLabel>
            </IonItem>
            <IonButton
              slot="end"
              fill="clear"
              onClick={clickHandler}
              color="dark"
            >
              <IonIcon icon={ellipsisHorizontal}></IonIcon>
            </IonButton>
          </IonToolbar>
        </IonCardHeader>
        <IonCardContent>
          <IonImg src={image}></IonImg>
        </IonCardContent>
        <IonFooter>
          <IonButtons>
            <IonLabel slot="start">
              <IonButton onClick={clickHandler} color="dark">
                <IonImg slot="start" src={HeartIcon} />
              </IonButton>
              <IonButton onClick={clickHandler} color="dark">
                <IonImg slot="start" src={CommentIcon} />
              </IonButton>
              <IonButton onClick={clickHandler} color="dark">
                <IonImg slot="start" src={DirectIcon}></IonImg>
              </IonButton>
            </IonLabel>
            <IonLabel slot="end">
              <IonButton onClick={clickHandler} color="dark">
                <IonImg slot="start" src={SaveIcon}></IonImg>
              </IonButton>
            </IonLabel>
          </IonButtons>
          <IonToolbar>
            <IonLabel slot="start">
              <IonAvatar>
                <IonImg src={avatar} />
              </IonAvatar>
              <IonAvatar>
                <IonImg src="https://buythermopro.com/wp-content/uploads/2019/09/avatar-small-3-400x400.jpg" />
              </IonAvatar>
              <IonAvatar>
                <IonImg src="https://www.boredpanda.com/blog/wp-content/uploads/2018/07/Bldm5TNFrpM-1-png__700.jpg" />
              </IonAvatar>
              <span
                className="likes-text"
                dangerouslySetInnerHTML={{ __html: likesText }}
              ></span>
            </IonLabel>
          </IonToolbar>
          <div className="comments">
            <div className="entries">
              <div className="author">
                {description && (
                  <IonLabel
                    dangerouslySetInnerHTML={{
                      __html: "<strong>monica</strong>" + description,
                    }}
                  ></IonLabel>
                )}
              </div>
              {comments?.map(({ author, text }) => (
                <div>
                  <strong>{author}</strong>
                  {text}
                </div>
              ))}
            </div>
            <div className="more">View all {commentsText}</div>
            <aside>
              <IonAvatar>
                <IonImg src="https://is5-ssl.mzstatic.com/image/thumb/Purple116/v4/34/af/ac/34afacf6-156e-19c5-737f-ccd0b281892e/source/512x512bb.jpg" />
              </IonAvatar>
              <span>Add a comment...</span>
            </aside>
            <small>1 day ago</small>
          </div>
        </IonFooter>
      </IonCard>
    </IonItem>
  );
};

export default FeedItemInstagram;
