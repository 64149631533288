import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonImg,
  IonModal,
  IonToolbar,
} from "@ionic/react";
import {
  close,
  arrowBack,
  arrowForward,
  ellipsisHorizontal,
  lockClosed,
} from "ionicons/icons";

import "./ModalItem.scss";

interface ModalItemProps {
  url?: string;
  image?: string;
  isOpen: boolean;
  onDismiss?: () => void;
  onContentClick?: () => void;
}

const ModalItem: React.FC<ModalItemProps> = ({
  url,
  image,
  isOpen,
  onDismiss = () => void 0,
  onContentClick = () => void 0,
}: ModalItemProps) => {
  return (
    <IonModal
      className="modal"
      breakpoints={[0, 0.2, 0.5, 0.94, 1]}
      initialBreakpoint={0.94}
      isOpen={isOpen}
      onDidDismiss={onDismiss}
    >
      <IonHeader>
        <IonToolbar style={{ "--background": "white" }}>
          <IonButtons slot="start">
            <IonButton color="dark" onClick={onDismiss}>
              <IonIcon icon={close}></IonIcon>
            </IonButton>
          </IonButtons>

          <IonButtons style={{ justifyContent: "space-between", gap: "12px" }}>
            <IonButton color="medium">
              <IonIcon size="small" icon={arrowBack}></IonIcon>
            </IonButton>
            <div
              style={{
                color: "green",
                display: "flex",
                gap: "5px",
                alignItems: "center",
              }}
            >
              <IonIcon icon={lockClosed}></IonIcon>
              {url}
            </div>
            <IonButton color="medium">
              <IonIcon size="small" icon={arrowForward}></IonIcon>
            </IonButton>
          </IonButtons>

          <IonButtons slot="end">
            <IonButton color="dark">
              <IonIcon icon={ellipsisHorizontal}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonImg src={image} onClick={onContentClick} />
      </IonContent>
    </IonModal>
  );
};

export default ModalItem;
