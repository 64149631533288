import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { CSSTransition } from "react-transition-group";

import {
  IonContent,
  IonHeader,
  IonImg,
  IonList,
  IonPage,
  IonToolbar,
} from "@ionic/react";
import useSearchQuery from "../hooks/useLocationSearch";
import { RouteComponentProps, useHistory } from "react-router-dom";

import useData from "../hooks/useData";

import VisibilitySensor from "react-visibility-sensor";
import OrganicItem from "../components/OrganicItem/OrganicItem";
import AdItem from "../components/AdItem/AdItem";
import SlideShow from "../components/SlideShowItem/SlideShowItem";
import SlideShowInstagram from "../components/SlideShowItemInstagram/SlideShowItemInstagram";

import InstagramLogo from "../assets/icons/instagram.png";
import InstagramActions from "../assets/icons/instagram-icons.png";

import useMetric from "../hooks/useMetric";

import { Ad, Organic, Slideshow, SlideshowItem } from "../global";

import FeedItemInstagram from "../components/FeedItemInstagram/FeedItemInstagram";

import ModalItem from "../components/ModalItem/ModalItem";

import "./Feed.scss";
// import useExperienceTimeout from "../hooks/useExperienceTimeout";

import timer from "../utils/timer";
import EndSection from "../components/EndSection/EndSection";
import TopHeader from "../components/Facebook/TopHeader";

interface FeedProps extends RouteComponentProps<{}> {}

const ADS_STORAGE_REF = "fb-ads";
const ORGANIC_POSTS_STORAGE_REF = "fb-organic-posts";

export function shuffle<T>(array: any[] | undefined = []): T[] {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}

const Feed: React.FC<FeedProps> = () => {
  const search = useSearchQuery();
  const id = search.get("feed") || "1";
  let dataUrl = search.get("dataUrl");
  const { data } = useData({ id, dataUrl });
  const history = useHistory();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const GLANCE_PERIOD: number = data?.glancePeriod ?? 0;
  const EXPERIENCE_TIMEOUT_PERIOD: number = 60 * 1000 * 3;

  const adTimerRef = useRef<ReturnType<typeof setTimeout>>(null);
  const containmentContainer = React.useRef<HTMLIonContentElement>(null);

  const [currentRevealId, setCurrentRevealId] = React.useState<string>();
  const [currentModalItem, setCurrentModalItem] =
    React.useState<SlideshowItem>();

  function hasAdblocker() {
    const container = document.querySelector(
      ".creative-item"
    ) as HTMLDivElement;
    return container && container.clientWidth === 0;
  }

  useEffect(() => {
    timer({
      duration: EXPERIENCE_TIMEOUT_PERIOD,
      onStart: () => track("session-start"),
      onEnd: () => {
        track("session-end");
        window.location.href = `/fb/post?${search.toString()}`;
      },
    });
  }, []);

  // @ts-ignore
  const insert = (arr, index, newItem) => [
    // part of the array before the specified index
    ...arr.slice(0, index),
    // inserted item
    newItem,
    // part of the array after the specified index
    ...arr.slice(index),
  ];

  const feedItems = useMemo(() => {
    if (!data?.feed?.["ad-pool"]) {
      return [];
    }

    type AccompanyingAdsIndexes = Record<number, Ad["accompanyingAds"]>;

    let adPool: Ad[] = [];
    const prevAds = (
      localStorage.getItem(ADS_STORAGE_REF)
        ? JSON.parse(localStorage.getItem(ADS_STORAGE_REF) as string)
        : []
    ) as Ad[];

    const hasPrevAds = prevAds?.length > 0;
    if (hasPrevAds) {
      adPool = prevAds;
    } else {
      adPool = shuffle<Ad>([...data.feed["ad-pool"]]);
      localStorage.setItem(ADS_STORAGE_REF, JSON.stringify(adPool));
    }

    let adIndexes: AccompanyingAdsIndexes = {};

    // Fetch previous organic posts (if tab is refreshed), otherwise shuffle posts
    let organicPool: Organic[] = [];
    const prevOrganicPool = (
      localStorage.getItem(ORGANIC_POSTS_STORAGE_REF)
        ? JSON.parse(localStorage.getItem(ORGANIC_POSTS_STORAGE_REF) as string)
        : []
    ) as Organic[];

    const hasPreviousOrganicPosts = prevOrganicPool?.length > 0;
    if (hasPreviousOrganicPosts) {
      organicPool = prevOrganicPool;
    } else {
      organicPool = shuffle<Organic>([
        ...(data?.feed?.items?.filter((item) => item?.type === "organic") ??
          []),
      ]);
      localStorage.setItem(
        ORGANIC_POSTS_STORAGE_REF,
        JSON.stringify(organicPool)
      );
    }

    // (@noahlaux): This is a hack to make sure that the ad-pool is shuffled, but should be refactored!
    let items =
      data?.feed?.items?.map((item, index) => {
        let ad;
        let organic;
        if (item?.type === "adslot") {
          ad = adPool.pop();
          if ((ad as Ad)?.accompanyingAds && (ad as Ad).revealIndex) {
            adIndexes[index + Object.keys(adIndexes).length + 2] = (
              ad as Ad
            )?.accompanyingAds;
          }
        } else if (item?.type === "organic") {
          organic = organicPool.pop();
        }
        return ad ? ad : organic ? organic : item;
      }) ?? [];

    Object.entries(adIndexes).forEach(([index, item]) => {
      // @ts-ignore
      items = insert(items, index, item[0]);
    });

    return items;
  }, [data]);

  const { track } = useMetric();

  const slideShowClickHandler = useCallback((item: SlideshowItem) => {
    setIsModalOpen(true);
    track("ad-cta-click", item);
    setCurrentModalItem(item);
  }, []);

  const onBannerExitHandler = useCallback((banner) => {
    setIsModalOpen(false);
    track("banner-item-click", banner);
    setTimeout(() => history.push(`/post?${search.toString()}`));
  }, []);

  const exploreHandler = useCallback((item: Ad) => {
    if (item?.revealId) {
      track("ad-exposure-triggered", item);
      setCurrentRevealId(item?.revealId);
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (hasAdblocker()) {
        track("has-adblocker");
      }
    }, 500);
  }, [feedItems, track]);

  useEffect(() => {
    // Clear potential intervals when the component unmounts
    return () => {
      if (adTimerRef.current) {
        clearTimeout(adTimerRef.current);
      }
    };
  }, []);

  const visibilityHandler = useCallback(
    (item: Ad) => (isVisible: boolean) => {
      if (isVisible) {
        track("ad-on-page", item);
        // @ts-ignore
        adTimerRef.current = setTimeout(
          () => exploreHandler(item),
          GLANCE_PERIOD
        );
      } else {
        if (adTimerRef.current) {
          track("ad-off-page", item);
          clearTimeout(adTimerRef.current);
        }
      }
    },
    [track, GLANCE_PERIOD, exploreHandler]
  );

  return (
    <>
      <IonPage className="feed">
        {data?.type === "instagram" && (
          <IonHeader>
            <IonToolbar className="instagram">
              <IonImg className="logo" slot="start" src={InstagramLogo} />
              <IonImg className="actions" slot="end" src={InstagramActions} />
            </IonToolbar>
          </IonHeader>
        )}

        <IonContent ref={containmentContainer} fullscreen>
          <IonList>
            {data?.type === "facebook" && <TopHeader />}

            {data &&
              feedItems?.map((item, index) => (
                <div key={`${item?.type}${index}`}>
                  {item?.type === "organic" && data.type === "facebook" && (
                    <OrganicItem item={{ ...item, ...data?.buttons }} />
                  )}
                  {item?.type === "organic" && data.type === "instagram" && (
                    <FeedItemInstagram item={{ ...item, ...data?.buttons }} />
                  )}
                  {item?.type === "ad" && (
                    <VisibilitySensor
                      partialVisibility
                      offset={{ top: 180, bottom: 180 }}
                      containment={containmentContainer.current}
                      onChange={visibilityHandler(item)}
                    >
                      <AdItem
                        item={{ ...item, ...data?.buttons }}
                        performCommentAction={!item?.revealId}
                        onCommentClick={() => {
                          track("ad-comment-click", item);
                        }}
                        onClick={() => {
                          track("ad-click", item);
                          if (item?.revealId) {
                            setCurrentRevealId(item?.revealId);
                          }
                        }}
                        onLikeClick={() => {
                          track("ad-like-click", item);
                          if (item?.revealId) {
                            setCurrentRevealId(item?.revealId);
                          }
                        }}
                      />
                    </VisibilitySensor>
                  )}
                  {item?.type === "slideshow" && (
                    <CSSTransition
                      timeout={300}
                      unmountOnExit
                      in={
                        currentRevealId === item?.id || item?.initiallyVisible
                      }
                    >
                      <>
                        {data.type === "facebook" && (
                          <SlideShow
                            item={item}
                            onCTAClick={slideShowClickHandler}
                            onFullCardClick={(fullCardItem) =>
                              track("ad-click", fullCardItem)
                            }
                          />
                        )}
                        {data.type === "instagram" && (
                          <SlideShowInstagram
                            item={item}
                            onCTAClick={slideShowClickHandler}
                            onImageClick={(item) => track("ad-click", item)}
                          />
                        )}
                      </>
                    </CSSTransition>
                  )}
                </div>
              ))}
            <EndSection />
          </IonList>
        </IonContent>
      </IonPage>
      <ModalItem
        isOpen={isModalOpen}
        url={currentModalItem?.url}
        image={currentModalItem?.screenshot}
        onDismiss={() => setIsModalOpen(false)}
        onContentClick={() => onBannerExitHandler(currentModalItem)}
      />
    </>
  );
};

export default Feed;
