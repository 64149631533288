import React from "react";
import {
  IonItem,
  IonAvatar,
  IonImg,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonContent,
  IonChip,
  IonButton,
} from "@ionic/react";
import BookmarkIcon from "../../assets/icons/insta/bookmark.png";
import ShareIcon from "../../assets/icons/insta/share.png";
import ProtectionIcon from "../../assets/icons/insta/protection-shield.png";
import ChevronDownIcon from "../../assets/icons/insta/chevron-down.png";
import VisibilitySensor from "react-visibility-sensor";

import "./Product.css";
import "../SlideShowItemInstagram/SlideShowItemInstagram.scss";
import { isLabeledStatement, isVariableDeclaration } from "typescript";
import { Stars } from "./Reviews";

export default function Product({
  images = [],
  availableSize = [],
  tags = [],
  colors = [],
  name = "",
  price = "",
  stars = 0,
  reviews = 0,
  purchaseProtection = {},
  size = {},
  length = {},
  containment,
  onCtaClick = () => {},
  onProductImagesClick = () => {},
  onAddToBasketClick = () => {},
  onVisibilityChange = () => {},
}) {
  const [colorId, setColorId] = React.useState(null);

  React.useEffect(() => {
    if (colors?.length > 0) {
      setColorId(colors[0]?.id);
    }
  }, [colors]);

  console.log(images);
  return (
    <>
      <IonItem className="slideshow-item-instagram">
        <IonCard className="p-0">
          <VisibilitySensor
            partialVisibility
            offset={{ top: 40, bottom: 40 }}
            containment={containment}
            onChange={onVisibilityChange({ id: "main-product-images" })}
          >
            <IonCardContent onClick={onProductImagesClick}>
              {images.map((image) => (
                <IonCard className="product-image-wrapper">
                  <IonCardContent>
                    <IonImg className="product-image" src={image.src} />
                  </IonCardContent>
                </IonCard>
              ))}
            </IonCardContent>
          </VisibilitySensor>
        </IonCard>
      </IonItem>

      <IonGrid style={{ padding: "12px 16px", display: "flex" }}>
        <IonCol className="m-0">
          <IonRow className="m-0 ion-justify-content-between">
            <IonCol className="m-0 p-0">
              <p className="product-name">{name}</p>

              <Stars stars={stars} reviews={reviews} />
            </IonCol>

            <IonRow>
              <IonImg className="share-icon" src={ShareIcon}></IonImg>
              <IonImg className="bookmark-icon" src={BookmarkIcon}></IonImg>
            </IonRow>
          </IonRow>

          <p className="product-price">{price}</p>

          <IonRow>
            {tags.map((tag) => (
              <IonChip className="product-tag" outline>
                <IonLabel className="product-tag-label">{tag.name}</IonLabel>
              </IonChip>
            ))}
          </IonRow>
          {/* <IonRow
            className="p-0 ion-justify-content-start"
            style={{ marginTop: "12px" }}
          >
            {Array.isArray(colors) &&
              colors.map((color, index) => {
                const isActive = color.id === colorId;
                return (
                  <IonCol
                    className="p-0 product-color-wrapper "
                    onClick={() => {
                      if (color.isAvailable) setColorId(color.id);
                    }}
                  >
                    <div
                      className={`product-color-image-wrapper ${
                        isActive ? "product-color-image-wrapper_active" : ""
                      }`}
                    >
                      <IonImg className="product-color-image" src={color.src} />

                      {!color.isAvailable && <div className="dimmer" />}
                    </div>
                    {isActive && (
                      <p className="product-color-name">{color.name}</p>
                    )}
                  </IonCol>
                );
              })}
          </IonRow> */}
        </IonCol>
      </IonGrid>

      {size?.isActive && (
        <IonGrid>
          <IonRow
            className="ion-justify-content-center"
            style={{ marginLeft: "10px" }}
          >
            {/* <IonCol>
              <p className="dropdown-label">{length?.label}</p>
              <IonRow className="ion-align-items-center">
                <p className="dropdown-value">{length?.selectedId}</p>
                <IonImg
                  className="dropdown-icon"
                  src={ChevronDownIcon}
                ></IonImg>
              </IonRow>
            </IonCol> */}

            <IonCol>
              <p className="dropdown-label">{size?.label}</p>
              <IonRow className="ion-align-items-center">
                <p className="dropdown-value">{size?.selectedId}</p>
                <IonImg
                  className="dropdown-icon"
                  src={ChevronDownIcon}
                ></IonImg>
              </IonRow>
            </IonCol>
          </IonRow>
        </IonGrid>
      )}

      <IonGrid className="product-cta-bttns">
        <IonCol class="p-0">
          <IonButton
            className="insta-button primary-cta"
            color="#0095f6"
            onClick={onCtaClick}
          >
            Buy Now
          </IonButton>
          <IonButton
            className="insta-button secondary-cta"
            color="transparent"
            style={{ marginTop: "8px" }}
            onClick={onAddToBasketClick}
          >
            Add to Cart
          </IonButton>
          <IonRow
            className="ion-justify-content-center"
            style={{ marginTop: "12px" }}
          >
            <IonImg className="protection-icon" src={ProtectionIcon} />
            <p className="protection-label">{purchaseProtection?.label}</p>
            <a className="protection-link">{purchaseProtection?.link}</a>
          </IonRow>
        </IonCol>
      </IonGrid>
    </>
  );
}
