import React from "react";
import {
  IonItem,
  IonAvatar,
  IonImg,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonContent,
  IonChip,
  IonButton,
} from "@ionic/react";
import BookmarkIcon from "../../assets/icons/insta/bookmark.png";
import ChevronUpIcon from "../../assets/icons/insta/chevron-up.png";

import "./Section.css";

export default function Section({
  title = "",
  isOpenDefault = true,
  customIcon,
  children = null,
}) {
  const [isOpen, setIsOpen] = React.useState(isOpenDefault);

  const toggle = React.useCallback(() => {
    if (!customIcon) setIsOpen(!isOpen);
  }, [customIcon, isOpen]);

  return (
    <IonGrid style={{ padding: "12px 16px", display: "flex" }}>
      <IonCol className="m-16">
        <IonRow
          className="m-0 ion-align-items-center ion-justify-content-between"
          onClick={toggle}
        >
          <p className="section-title">{title}</p>

          <p className="section-see-all-bttn">{isOpen ? "Hide" : "See All"}</p>
        </IonRow>
        {isOpen && <IonCol className="m-0">{children}</IonCol>}
      </IonCol>
    </IonGrid>
  );
}
