import React from "react";
import {
  IonItem,
  IonAvatar,
  IonImg,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonContent,
  IonChip,
  IonButton,
} from "@ionic/react";
import StarOutlineIcon from "../../assets/icons/insta/star-outline.png";
import StarFillIcon from "../../assets/icons/insta/star-fill.png";
import HeartOutlineIcon from "../../assets/icons/insta/heart-outline.png";
import HeartFillIcon from "../../assets/icons/insta/heart-fill.png";

import "./Reviews.css";
import SeeAllSection from "./SeeAllSection";

export default function Reviews({
  title = "",
  stars = 0,
  count = 0,
  comments = [],
}) {
  return (
    <SeeAllSection title={title}>
      <Stars stars={stars} count={count} className="mb-16" />

      <IonGrid>
        {comments.map((comment) => (
          <Comment {...comment} />
        ))}
      </IonGrid>
    </SeeAllSection>
  );
}

function arrayFromN(n) {
  return Array.from(Array(n).keys());
}

export function Comment({
  avatar = "",
  username = "",
  isVerified = false,
  stars = 0,
  verifiedPurchaseLabel = "",
  comment = "",
  createdAt = "",
  isLiked = false,
  likes = "",
  images = [],
  doesHaveStars = true,
  isAnswer = false,
}) {
  return (
    <IonRow style={{ margin: isAnswer ? "0 0 16px 44px" : "0 0 16px 0" }}>
      <IonImg
        src={avatar}
        className={isAnswer ? "comment-avatar-sm" : "comment-avatar"}
      />

      <IonCol className="m-0 p-0">
        <p className="comment-username mb-4">{username}</p>
        {doesHaveStars && (
          <Stars stars={stars} size="sm" noText className="mb-4" />
        )}

        {verifiedPurchaseLabel && (
          <p className="comment-verified mb-16">{verifiedPurchaseLabel}</p>
        )}

        <IonGrid className="p-0">
          <IonRow
            className="ion-align-items-center ion-justify-content-between"
            style={{ marginBottom: "16px !important" }}
          >
            <p className="comment-content">{comment}</p>

            <IonImg
              className="comment-heart-icon"
              src={isLiked ? HeartFillIcon : HeartOutlineIcon}
            />
          </IonRow>
        </IonGrid>
        {images?.length > 0 && (
          <IonRow className="mb-16">
            {images.map((image) => (
              <IonImg className="comment-img" src={image.src} />
            ))}
          </IonRow>
        )}
        <IonRow>
          <p className="comment-created-at">{createdAt}</p>
          <p className="comment-likes">{likes}</p>
        </IonRow>
      </IonCol>
    </IonRow>
  );
}

export function Stars({
  stars = 0,
  count = 0,
  size = "md",
  noText = false,
  ...rest
}) {
  const MAX_STARS = React.useMemo(() => 5, []);

  const starsNorm = React.useMemo(
    () => Math.min(MAX_STARS, Math.floor(stars)),
    [stars]
  );

  return (
    <IonRow {...rest}>
      {arrayFromN(starsNorm).map(() => (
        <IonImg
          className={`star-size-${size}`}
          src={StarFillIcon}
          style={{ marginRight: "2px" }}
        />
      ))}
      {arrayFromN(MAX_STARS - starsNorm).map(() => (
        <IonImg
          className={`star-size-${size}`}
          src={StarOutlineIcon}
          style={{ marginRight: "2px" }}
        />
      ))}

      {!noText && <p className="reviews-rating">{stars}</p>}

      {!noText && <p className="reviews-count">({count})</p>}
    </IonRow>
  );
}
