let isRunning: boolean = false;
let timerRef: ReturnType<typeof setTimeout>;
const LOCALSTORAGE_REF = "timerRef";
const TIMER_ADVANCE_INTERVAL = 1000;

interface Timer {
  duration: number;
  storageId?: string;
  onStart: () => void;
  onEnd: () => void;
}

export default function timer({
  duration,
  onStart = () => void 0,
  onEnd = () => void 0,
  storageId = LOCALSTORAGE_REF,
}: Timer) {
  const wasInitiated: boolean = !!localStorage.getItem(storageId);

  if (isRunning) {
    return;
  }

  if (!wasInitiated) {
    onStart();
  }

  let currentTime = wasInitiated ? Number(localStorage.getItem(storageId)) : 0;

  timerRef = setTimeout(onEndInternal, duration - currentTime);
  isRunning = true;

  const interval = setInterval(() => {
    if (isRunning) {
      currentTime += TIMER_ADVANCE_INTERVAL;
      localStorage.setItem(storageId, String(currentTime));
    }
  }, TIMER_ADVANCE_INTERVAL);

  function onEndInternal() {
    isRunning = false;
    clearTimeout(timerRef);
    clearInterval(interval);
    localStorage.removeItem(storageId);
    onEnd();
  }
}

export function stopTimer() {
  isRunning = false;
}
