import React from "react";
import {
  IonItem,
  IonAvatar,
  IonImg,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonContent,
  IonChip,
  IonButton,
} from "@ionic/react";
import BookmarkIcon from "../../assets/icons/fb/bookmark-outline.png";
import ShareIcon from "../../assets/icons/fb/share.png";
import CartIcon from "../../assets/icons/fb/shop-cart.png";
import VisibilitySensor from "react-visibility-sensor";

import "./Product.css";
import "../SlideShowItemInstagram/SlideShowItemInstagram.scss";
import { Stars } from "./Reviews";

export default function Product({
  images = [],
  tags = [],
  colors = [],
  sizeGuideLabel = [],
  name = "",
  price = "",
  stars = 0,
  reviews = 0,
  purchaseProtection = {},
  size = {},
  length = {},
  containment,
  onCtaClick = () => {},
  onProductImagesClick = () => {},
  onAddToBasketClick = () => {},
  onVisibilityChange = () => {},
}) {
  const [colorId, setColorId] = React.useState(colors.selectedId);
  const [sizeId, setSizeId] = React.useState(size.selectedId);

  const selectedSize = React.useMemo(
    () => size?.options.find((s) => s.id === sizeId),
    [size, sizeId]
  );
  const selectedColor = React.useMemo(
    () => colors?.options.find((c) => c.id === colorId),
    [colors, colorId]
  );

  console.log(images);
  return (
    <>
      <IonItem className="slideshow-item-instagram">
        <IonCard className="p-0">
          <VisibilitySensor
            partialVisibility
            offset={{ top: 40, bottom: 40 }}
            containment={containment}
            onChange={onVisibilityChange({ id: "main-product-images" })}
          >
            <IonCardContent onClick={onProductImagesClick}>
              {images.map((image) => (
                <IonCard className="fb-product-image-wrapper">
                  <IonCardContent>
                    <IonImg className="fb-product-image" src={image.src} />
                  </IonCardContent>
                </IonCard>
              ))}
            </IonCardContent>
          </VisibilitySensor>
        </IonCard>
      </IonItem>

      <IonGrid style={{ padding: "12px 16px" }}>
        <IonRow className="m-0 ion-justify-content-between" size="12">
          <IonCol className="m-0 p-0">
            <p className="fb-product-name">{name}</p>

            <Stars stars={stars} count={reviews} />
          </IonCol>

          <IonRow>
            <IonImg className="fb-bookmark-icon" src={BookmarkIcon}></IonImg>
            <IonImg className="fb-share-icon" src={ShareIcon}></IonImg>
          </IonRow>
        </IonRow>
        <IonRow>
          <p className="fb-product-price">{price}</p>
        </IonRow>
        <IonRow>
          {tags.map((tag) => (
            <IonChip className="fb-product-tag" outline>
              <IonLabel className="fb-product-tag-label">{tag.name}</IonLabel>
            </IonChip>
          ))}
        </IonRow>
        {/* <IonRow
          className="ion-align-items-center"
          style={{ marginTop: "12px" }}
        >
          <p className="fb-product-label">{colors.label}: &nbsp;</p>
          <p className="fb-product-label">{selectedColor?.name}</p>
        </IonRow>
        <IonRow
          className="p-0 ion-justify-content-start"
          style={{ marginTop: "6px" }}
        >
          {colors.options.map((color, index) => {
            const isActive = color.id === colorId;
            return (
              <div
                className={`fb-product-color-image-wrapper ${
                  isActive ? "fb-product-color-image-wrapper__active" : ""
                }`}
                onClick={() => {
                  if (color.isAvailable) setColorId(color.id);
                }}
              >
                <IonImg className="fb-product-color-image" src={color.src} />

                {!color.isAvailable && <div className="dimmer" />}
              </div>
            );
          })}
        </IonRow> */}
        {size?.isActive && (
          <>
            <IonRow
              className="ion-align-items-center"
              style={{ marginTop: "12px" }}
            >
              <p className="fb-product-label">{size.label}: &nbsp;</p>
              <p className="fb-product-label">{selectedSize?.name}</p>
            </IonRow>
            <IonRow
              className="p-0 ion-justify-content-start"
              style={{ marginTop: "6px" }}
            >
              {size.options.map((size, index) => {
                const isActive = size.id === sizeId;
                return (
                  <div
                    className={`fb-product-size-wrapper ${
                      isActive
                        ? "fb-product-size-wrapper__active"
                        : !size.isAvailable
                        ? "fb-product-size-wrapper__unavailable"
                        : ""
                    }`}
                    onClick={() => {
                      if (size.isAvailable) setSizeId(size.id);
                    }}
                  >
                    <p className="fb-product-size">{size.id}</p>
                  </div>
                );
              })}
            </IonRow>

            <IonRow style={{ marginTop: "12px" }}>
              <p className="fb-product-size-guide-cta">{sizeGuideLabel}</p>
            </IonRow>
          </>
        )}
        <IonRow className="fb-product-cta-bttns">
          <IonCol class="p-0">
            <IonButton
              className="fb-button fb-primary-cta"
              color="#0095f6"
              onClick={onCtaClick}
            >
              Buy Now
            </IonButton>
            <IonButton
              className="fb-button fb-secondary-cta"
              color="transparent"
              style={{ marginTop: "8px" }}
              onClick={onAddToBasketClick}
            >
              <IonImg src={CartIcon} className="fb-cart-icon-button" />
              <p>Add to Cart</p>
            </IonButton>
            <IonRow
              className="ion-justify-content-center"
              style={{ marginTop: "12px" }}
            >
              <p className="protection-label">{purchaseProtection?.label}</p>
              <a className="protection-link">{purchaseProtection?.link}</a>
            </IonRow>
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  );
}
