// @ts-nocheck
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { CSSTransition } from "react-transition-group";

import {
  IonContent,
  IonHeader,
  IonImg,
  IonList,
  IonPage,
  IonToolbar,
} from "@ionic/react";
import useSearchQuery from "../hooks/useLocationSearch";
import { RouteComponentProps, useHistory } from "react-router-dom";

import useShopData from "../hooks/useShopData";
import useMetric from "../hooks/useMetric";

import { Ad, Slideshow, ProductCard } from "../global";

import "./Shop.scss";
// import useExperienceTimeout from "../hooks/useExperienceTimeout";

import timer from "../utils/timer";

import Facebook from "../components/Facebook";
import Instagram from "../components/Instagram";
import EndSection from "../components/EndSection/EndSection";

interface FeedProps extends RouteComponentProps<{}> {}

export const META_TIMER_REF = "META_SHOP_TIMER_REF";
export const META_METRICS_REF = "META_SHOP_METRICS_REF";

const Shop: React.FC<FeedProps> = () => {
  const search = useSearchQuery();

  const productId = search.get("productId");
  const pricepoint = search.get("pricepoint");
  const platform = search.get("platform");

  const { data, feedId } = useShopData({
    productId,
    pricepoint,
  });

  console.log({ data, feedId });

  const history = useHistory();

  const EXPERIENCE_TIMEOUT_PERIOD: number =
    (data?.timeoutMinutes ?? 3) * 60 * 1000;

  const adTimerRef = useRef<ReturnType<typeof setTimeout>>(null);
  const containmentContainer = React.useRef<HTMLIonContentElement>(null);

  function hasAdblocker() {
    const container = document.querySelector(
      ".creative-item"
    ) as HTMLDivElement;
    return container && container.clientWidth === 0;
  }

  useEffect(() => {
    if (!feedId) return;

    timer({
      duration: EXPERIENCE_TIMEOUT_PERIOD,
      onStart: () => track("session-start"),
      onEnd: () => {
        track("session-end");
        history.push(
          `/post?metricsRef=${META_METRICS_REF}&${search.toString()}`
        );
      },
      storageId: META_TIMER_REF,
    });

    // initialize all tracked items
    track("ad-off-page", { id: "buy-now-cta" });
    track("ad-off-page", { id: "add-to-basket-cta" });
    track("ad-off-page", { id: "main-product-images" });
    track("ad-off-page", { id: "popup-buy-now-cta" });
  }, [feedId]);

  const { track } = useMetric(META_METRICS_REF);

  useEffect(() => {
    setTimeout(() => {
      if (hasAdblocker()) {
        track("has-adblocker");
      }
    }, 500);
  }, [track]);

  useEffect(() => {
    // Clear potential intervals when the component unmounts
    return () => {
      if (adTimerRef.current) {
        clearTimeout(adTimerRef.current);
      }
    };
  }, []);

  const onVisibilityChange = useCallback(
    (product: ProductCard) => (isVisible: boolean) => {
      if (isVisible) {
        track("ad-on-page", product);
      } else {
        track("ad-off-page", product);
      }
    },
    [track]
  );
  const onProductClick = useCallback(
    (product: ProductCard) => () => track("ad-click", product),
    [track]
  );

  const mainProduct = useMemo(
    () => ({
      // @ts-ignore
      productId: data?.shop?.product?.id,
      name: data?.shop?.product?.name,
    }),
    [data?.shop?.product]
  );
  const onCtaClick = useCallback(
    () => track("ad-click", { ...mainProduct, id: "buy-now-cta" }),
    [track, mainProduct]
  );
  const onAddToBasketClick = useCallback(
    () => track("ad-click", { ...mainProduct, id: "add-to-basket-cta" }),
    [track, mainProduct]
  );
  const onProductImagesClick = useCallback(
    () => track("ad-click", { ...mainProduct, id: "main-product-images" }),
    [track, mainProduct]
  );
  const onPopupCtaClick = useCallback(
    () => track("ad-click", { ...mainProduct, id: "popup-buy-now-cta" }),
    [track, mainProduct]
  );

  const Platform = React.useMemo(
    () => (platform === "facebook" ? Facebook : Instagram),
    [platform]
  );

  console.log("shop");

  const isReady = feedId && productId && pricepoint;
  if (!isReady) return null;

  return (
    <IonPage className="feed">
      <IonContent ref={containmentContainer} fullscreen>
        <Platform.Header {...data?.shop} />
        <Platform.Product
          {...data?.shop?.product}
          // @ts-ignore
          onCtaClick={onCtaClick}
          // @ts-ignore
          onProductImagesClick={onProductImagesClick}
          // @ts-ignore
          onAddToBasketClick={onAddToBasketClick}
          containment={containmentContainer?.current}
          // @ts-ignore
          onVisibilityChange={onVisibilityChange}
        />
        <Platform.Divider />
        <Platform.ProductDetails
          title="Details"
          productDetails={data?.shop?.sections?.productDetails}
          description={data?.shop?.sections?.description}
          shippingAndReturns={data?.shop?.sections?.shippingAndReturns}
        />
        <Platform.Divider />
        {data?.shop?.sections?.questions?.comments?.length > 0 && (
          <Platform.Questions {...data?.shop?.sections?.questions} />
        )}
        <Platform.Divider />

        {platform === "facebook" ? (
          <>
            {data?.shop?.sections?.reviews?.comments?.length > 0 && (
              <Platform.Reviews {...data?.shop?.sections?.reviews} />
            )}
            <Platform.Divider isSmall />
            <Platform.AboutStore
              {...data?.shop?.sections?.aboutStore}
              {...data?.shop}
            />
            <Platform.Divider isSmall />
          </>
        ) : (
          <>
            <Platform.AboutStore
              {...data?.shop?.sections?.aboutStore}
              {...data?.shop}
            />
            <Platform.Divider />
            {data?.shop?.sections?.reviews?.comments?.length > 0 && (
              <Platform.Reviews {...data?.shop?.sections?.reviews} />
            )}
            <Platform.Divider />
          </>
        )}

        {data?.shop?.sections?.bundles?.products?.length > 0 && (
          <Platform.SimilarProducts
            {...data?.shop?.sections?.bundles}
            // @ts-ignore
            onVisibilityChange={onVisibilityChange}
            // @ts-ignore
            onProductClick={onProductClick}
            containment={containmentContainer?.current}
            style={{ paddingBottom: "2rem" }}
          />
        )}

        <Platform.Divider />

        <Platform.SimilarProducts
          {...data?.shop?.sections?.similarProductsFromShop}
          // @ts-ignore
          onVisibilityChange={onVisibilityChange}
          // @ts-ignore
          onProductClick={onProductClick}
          containment={containmentContainer?.current}
        />

        <EndSection
          style={{ paddingBottom: "9rem", background: "#f6f6f6" }}
          isShop
          ctaCopy="Proceed"
          metricsRef={META_METRICS_REF}
          timerRef={META_TIMER_REF}
        />

        <Platform.PurchaseCta
          product={data?.shop?.product}
          {...data?.shop?.sections?.purchaseCta}
          // @ts-ignore
          onCtaClick={onPopupCtaClick}
        />
      </IonContent>
    </IonPage>
  );
};

export default Shop;
