import { IonAvatar, IonImg, IonItem, IonLabel } from "@ionic/react";
import type { Comment } from "../../global";
import decorateHashAndMentions from "../../utils/decorateHashAndMentions";

import { FormattedMessage } from "react-intl";

import "./CommentsItem.scss";

interface CommentItemProps {
  comments: Comment[];
}

const CommentsItem: React.FC<CommentItemProps> = ({
  comments,
}: CommentItemProps) => {
  return (
    <div className="comments-item">
      {comments.map(({ id, avatar, author, text, createdTime }) => (
        <IonItem lines="none" key={id}>
          <IonAvatar className="avatar" slot="start">
            <IonImg src={avatar} />
          </IonAvatar>
          <IonLabel className="comment-box">
            <div className="meta">
              <h2>{author}</h2>
              <p>{decorateHashAndMentions(text)}</p>
            </div>
            <footer>
              <span>{createdTime}</span>
              <span>
                <FormattedMessage defaultMessage="Like" />
              </span>
              <span>
                <FormattedMessage defaultMessage="Reply" />
              </span>
            </footer>
          </IonLabel>
        </IonItem>
      ))}
    </div>
  );
};

export default CommentsItem;
