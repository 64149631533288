import React from "react";
import { IonButton, IonContent, IonPage } from "@ionic/react";
import * as Sentry from "@sentry/react";

import useMetric, { FB_FEED_METRICS_REF } from "../../hooks/useMetric";
import useSearchQuery from "../../hooks/useLocationSearch";

import "./EndSection.css";
import { getRedirectPath } from "../../pages/Post";
import { META_METRICS_REF } from "../../pages/Shop";

const EndSection = ({
  timerRef = "timerRef",
  metricsRef = FB_FEED_METRICS_REF,
  ctaCopy = "Fortsett",
  isShop = false,
  extraClasses = "",
  ...rest
}) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const searchQuery = useSearchQuery();

  const { track, sendMetrics } = useMetric(metricsRef);

  function onEnd() {
    setIsLoading(true);
    track("session-end");
    // TODO: (noahlaux) remove in timer instead
    localStorage.removeItem(timerRef);
    sendMetrics(isShop)
      .then((isSuccess) => {
        setIsLoading(false);

        if (isSuccess) {
          const redirectUrl = getRedirectPath(searchQuery);
          console.log({ redirectUrl });
          if (redirectUrl !== "") {
            window.location.href = redirectUrl;
          }
        }
      })
      .catch((err) => {
        setIsLoading(false);
        Sentry.captureException({ code: "ERR.102", err });
        alert(
          "ERR.102: Something went wrong. Please contact the Neurons team."
        );
      });
  }

  return (
    <div className="end-section" {...rest}>
      <IonButton
        className={"post-continue end-section-cta " + extraClasses}
        onClick={isLoading ? () => {} : onEnd}
      >
        {isLoading ? "Processing..." : ctaCopy}
      </IonButton>
    </div>
  );
};

export default EndSection;
