import React, { createElement } from "react";
import {
  IonButton,
  IonContent,
  IonGrid,
  IonImg,
  IonPage,
  IonRow,
  IonCol,
} from "@ionic/react";

import Logo from "../../assets/icons/vg/vg-logo.svg";
import HomeLogo from "../../assets/icons/vg/vg-home-logo.svg";
import AccountIcon from "../../assets/icons/vg/vg-account.svg";
import SearchIcon from "../../assets/icons/vg/vg-search.svg";
import MenuIcon from "../../assets/icons/vg/menu.svg";

import "./AdsSection.css";

const AdsSection = ({
  title = "",
  leftAd,
  rightAd,
  isDark = false,
  portion = 0.5,
}) => {
  return (
    <IonGrid
      className={isDark ? "vg-ads-section-dark" : "vg-ads-section-light"}
    >
      {title && (
        <IonRow className="m-0 ion-align-items-center ">
          <div className="vg-ads-section-circle" />
          <p className="vg-ads-section-title">{title}</p>
        </IonRow>
      )}
      <IonRow className="m-0">
        <IonCol size={Math.round(portion * 12)}>{leftAd}</IonCol>
        <IonCol size={12 - Math.round(portion * 12)}>{rightAd}</IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default AdsSection;
