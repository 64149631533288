import {
  IonButton,
  IonContent,
  IonGrid,
  IonImg,
  IonPage,
  IonRow,
} from "@ionic/react";
import VideoCard from "./VideoCard";

const VideoSection = ({ title = "Videos", videos = [] }) => {
  return (
    <IonGrid className="m-0" style={{ padding: "1rem", width: "100%" }}>
      <IonRow
        className="ion-justify-content-between ion-align-items-center"
        style={{ marginBottom: "1rem" }}
      >
        <h2
          style={{
            color: "#E8E8E8",
            fontSize: "1.5rem",
            fontWeight: "700",
            margin: 0,
          }}
        >
          {title}
        </h2>
        <p
          style={{
            color: "#E8E8E8",
            fontSize: ".875rem",
            fontWeight: "700",
            margin: 0,
          }}
        >
          SE ALLE
        </p>
      </IonRow>

      <div
        className="m-0 p-0"
        style={{
          overflowX: "auto",
          display: "flex",
          flexDirection: "row",
        }}
      >
        {videos.map((video, key) => (
          <VideoCard key={key} {...video} />
        ))}
      </div>
    </IonGrid>
  );
};

export default VideoSection;
