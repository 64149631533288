import React from "react";
import {
  IonItem,
  IonAvatar,
  IonImg,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/react";
import VerifiedIcon from "../../assets/icons/fb/verified.png";
import BackIcon from "../../assets/icons/fb/chevron-left.png";
import CartIcon from "../../assets/icons/fb/shop-cart.png";
import MenuDotsIcon from "../../assets/icons/fb/3-dots-header.png";

import "./Header.css";

export default function Header({
  avatar = "",
  name = "",
  isVerified = false,
  followLabel = "",
  followers = "",
}) {
  return (
    <IonGrid className="fb-header">
      <IonRow className="ion-align-items-center ion-justify-content-between">
        <IonRow className="ion-align-items-center">
          <IonImg className="back-icon" src={BackIcon} />

          <IonImg className="fb-avatar" src={avatar} />

          <IonCol className="fb-shop-info p-0">
            <IonRow className="ion-align-items-center">
              <p className="fb-shop-name">{name}</p>
              {isVerified && (
                <IonImg
                  className="fb-verified-icon"
                  src={VerifiedIcon}
                ></IonImg>
              )}
            </IonRow>
            <IonRow className="ion-align-items-center">
              <p className="fb-shop-followers">{followers} &nbsp;·&nbsp;</p>
              <p className="fb-shop-follow-cta">{followLabel}</p>
            </IonRow>
          </IonCol>
        </IonRow>

        <IonRow className="ion-align-items-center">
          <IonImg className="fb-cart-icon" src={CartIcon} />
          <IonImg src={MenuDotsIcon} />
        </IonRow>
      </IonRow>
    </IonGrid>
  );
}
