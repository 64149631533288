import Section from "./Section";

export default function SeeAllSection({ ...rest }) {
  return (
    <Section
      customIcon={<p className="section-see-all-bttn">See All</p>}
      {...rest}
    />
  );
}
