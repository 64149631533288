import React from "react";
import {
  IonButton,
  IonContent,
  IonGrid,
  IonImg,
  IonPage,
  IonRow,
} from "@ionic/react";

import ViewsIcon from "../../assets/icons/vg/views.svg";
import DurationIcon from "../../assets/icons/vg/duration.svg";

import "./VideoInfo.css";

const VideoInfo = ({
  title = "Lene i villsvin-drama i Spania",
  description = "Lene Vagle og venninna Cathrine Nesvik fikk sjokk da de så hva som kom gående opp av havet.",
  views = "217.504",
  duration = "1:28",
  createdAt = "04.06.22",
  tags = ["Spania", "Norge", "Dyrene"],
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <IonGrid className="m-0" style={{ padding: "1rem", width: "100%" }}>
      <IonRow
        className="m-0 ion-justify-content-between ion-align-items-center"
        style={{ marginBottom: "1rem" }}
      >
        <h2 className="vg-video-info-title">{title}</h2>
      </IonRow>
      <IonRow className="m-0 ion-justify-content-between ion-align-items-center">
        <p
          className="vg-video-info-subtitle"
          style={{ marginBottom: isOpen ? "1rem" : 0 }}
        >
          {description}
        </p>
      </IonRow>

      {isOpen ? (
        <>
          <IonRow
            className="m-0 ion-align-items-center"
            style={{ marginBottom: "1rem" }}
          >
            <IonImg className="vg-video-info-icon" src={ViewsIcon} />
            <p className="vg-video-info-label-sm">{views}</p>

            <IonImg className="vg-video-info-icon" src={DurationIcon} />
            <p className="vg-video-info-label-sm">{duration}</p>
            <p className="vg-video-info-label-sm">{createdAt}</p>
          </IonRow>

          <IonRow
            className="ion-align-items-center"
            style={{ marginTop: "1rem" }}
          >
            {tags.map((tag) => (
              <div key={tag} className="vg-video-info-tag">
                {tag}
              </div>
            ))}
          </IonRow>
        </>
      ) : (
        <IonRow
          className="m-0 ion-justify-content-end ion-align-items-center"
          style={{ marginBottom: "1rem" }}
        >
          <p className="vg-video-info-subtitle">Se mer</p>
          <p
            className="vg-video-info-subtitle"
            style={{ marginBottom: ".5rem" }}
            onClick={() => setIsOpen(!isOpen)}
          >
            &nbsp; ⌄
          </p>
        </IonRow>
      )}
    </IonGrid>
  );
};

export default VideoInfo;
