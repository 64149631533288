import React from "react";
import {
  IonItem,
  IonAvatar,
  IonImg,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonContent,
  IonChip,
  IonButton,
} from "@ionic/react";

import Section from "./Section";

export default function ShippingDetails({ title = "", options = [] }) {
  return (
    <Section title={title}>
      {options.map((option) =>
        option.isLink ? (
          <IonRow>
            <p className="fb-section-content-p m-0">
              {option.label}&nbsp;·&nbsp;
            </p>

            <p className="fb-section-content-p-sm-secondary m-0">
              {option.value}
            </p>
          </IonRow>
        ) : (
          <>
            <p className="fb-section-content-p" style={{ marginTop: "1rem" }}>
              {option.label}
            </p>

            <IonRow style={{ marginTop: "2px" }}>
              <p className="fb-section-content-p-secondary m-0">
                {option.value}
              </p>
              {option.link && (
                <a
                  className="fb-section-content-cta"
                  style={{ marginLeft: "4px" }}
                >
                  {option.link}
                </a>
              )}
            </IonRow>
          </>
        )
      )}
      {/* <IonRow style={{ marginTop: "10px" }}>
        <a className="section-link">{moreInfo}</a>
      </IonRow> */}
    </Section>
  );
}
