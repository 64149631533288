import React from "react";
import {
  IonItem,
  IonAvatar,
  IonImg,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonContent,
  IonChip,
  IonButton,
} from "@ionic/react";

import FbLogo from "../../assets/icons/fb/logo-fb.png";
import RoomIcon from "../../assets/icons/fb/room.svg";
import PhotoGroupIcon from "../../assets/icons/fb/photo-group.svg";
import LiveIcon from "../../assets/icons/fb/live.svg";
import MessengerIcon from "../../assets/icons/fb/messenger.svg";
import SearchIcon from "../../assets/icons/fb/search.svg";
import Avatar from "../../assets/icons/fb/avatar.jfif";

import "./TopHeader.css";

export default function TopHeader({}) {
  return (
    <IonGrid className="m-0 p-0">
      <IonRow
        className="m-0 ion-align-items-center ion-justify-content-between"
        style={{ padding: "12px" }}
      >
        <IonImg className="top-header-fb-logo" src={FbLogo}></IonImg>

        <IonRow className="m-0 ion-align-items-center">
          <div className="top-header-button-left">
            <IonImg className="top-header-button-icon" src={SearchIcon} />
          </div>
          <div className="top-header-button-right">
            <IonImg className="top-header-button-icon" src={MessengerIcon} />
          </div>
        </IonRow>
      </IonRow>

      <IonRow className="m-0 top-header-respond-container">
        <IonImg className="top-header-respond-avatar" src={Avatar}></IonImg>

        <div className="top-header-respond-input">Hva tenker du på? </div>
      </IonRow>
      <IonRow className="m-0 top-header-actions">
        <IonCol
          className="m-0  p-0 "
          style={{ borderRight: "1.3px solid #ebebeb" }}
        >
          <IonRow className="m-0 p-0 ion-align-items-center ion-justify-content-center">
            <IonImg className="top-header-actions-icon" src={LiveIcon}></IonImg>
            <p className="top-header-actions-label">Direkte</p>
          </IonRow>
        </IonCol>
        <IonCol
          className="m-0 p-0  "
          style={{ borderRight: "1.3px solid #ebebeb" }}
        >
          <IonRow className="m-0 p-0 ion-align-items-center ion-justify-content-center">
            <IonImg
              className="top-header-actions-icon"
              src={PhotoGroupIcon}
            ></IonImg>
            <p className="top-header-actions-label">Bilde</p>
          </IonRow>
        </IonCol>
        <IonCol className="m-0  p-0 ">
          <IonRow className="m-0 p-0 ion-align-items-center ion-justify-content-center">
            <IonImg className="top-header-actions-icon" src={RoomIcon}></IonImg>
            <p className="top-header-actions-label">Rom</p>
          </IonRow>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
}
