import React from "react";
import {
  IonItem,
  IonAvatar,
  IonImg,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonContent,
  IonChip,
  IonButton,
} from "@ionic/react";

import SeeAllSection from "./SeeAllSection";

import "./Community.css";
import "../SlideShowItemInstagram/SlideShowItemInstagram.scss";

export default function Community({ title = "", cards = [] }) {
  return (
    <SeeAllSection title={title}>
      <IonItem className="slideshow-item-instagram">
        <IonCard className="p-0" style={{ borderRadius: "0" }}>
          <IonCardContent style={{ background: "white", borderRadius: "0" }}>
            {cards.map((card) => (
              <IonCard className="community-card">
                <IonCardContent className="community-card-content p-0">
                  <IonImg className="community-img" src={card.src} />

                  <IonGrid className="community-overlay" />

                  <p className="community-username">{card.username}</p>
                </IonCardContent>
              </IonCard>
            ))}
          </IonCardContent>
        </IonCard>
      </IonItem>
    </SeeAllSection>
  );
}
