const config = {
  1: {
    ads: {
      isRandom: true,
      feed: [
        {
          id: "aoi_Gartner-inbanner-plain",
          src: "https://storage.googleapis.com/stimuli/Gartner-inbanner-plain.mp4",
          aspectRatio: 1.433,
        },
        {
          id: "aoi_STOREBRAND_Inbanner_Solution",
          src: "https://storage.googleapis.com/stimuli/STOREBRAND_Inbanner_Solution_final.mp4",
          aspectRatio: 16 / 9,
        },
      ],
      preroll: {
        id: "aoi_Ruter_preroll",
        src: "https://storage.googleapis.com/stimuli/Ruter_preroll.mp4",
        aspectRatio: 16 / 9,
      },
    },
    mainVideo: {
      src: "https://storage.googleapis.com/brandbird/demo-images/mountains.mp4",
    },
  },
  2: {
    ads: {
      isRandom: true,
      feed: [
        {
          id: "aoi_Ruter-inbanner_plain",
          src: "https://storage.googleapis.com/stimuli/Ruter-inbanner_plain.mp4",
          aspectRatio: 16 / 9,
        },
        {
          id: "aoi_Gartner-inbanner-solution",
          src: "https://storage.googleapis.com/stimuli/Gartner-inbanner-solution.mp4",
          aspectRatio: 1.433,
        },
      ],
      preroll: {
        id: "aoi_STOREBRAND_Preroll",
        src: "https://storage.googleapis.com/stimuli/STOREBRAND_Preroll_16_9_15SEK_Les_Mer%20(1).mp4",
        aspectRatio: 16 / 9,
      },
    },
    mainVideo: {
      src: "https://storage.googleapis.com/brandbird/demo-images/mountains.mp4",
    },
  },
  3: {
    ads: {
      isRandom: true,
      feed: [
        {
          id: "aoi_STOREBRAND_Inbanner_plain",
          src: "https://storage.googleapis.com/stimuli/STOREBRAND_Inbanner_plain.mp4",
          aspectRatio: 16 / 9,
        },
        {
          id: "aoi_Ruter_Inbanner_Solution",
          src: "https://storage.googleapis.com/stimuli/Ruter_Inbanner_Solution.mp4",
          aspectRatio: 16 / 9,
        },
      ],
      preroll: {
        id: "aoi_Gartner_preroll",
        src: "https://storage.googleapis.com/stimuli/Gartner_preroll.mp4",
        aspectRatio: 16 / 9,
      },
    },
    mainVideo: {
      src: "https://storage.googleapis.com/brandbird/demo-images/mountains.mp4",
    },
  },
  storebrand: {
    ads: {
      isRandom: false,
      feed: [
        {
          id: "aoi_STOREBRAND_Inbanner_plain",
          src: "https://storage.googleapis.com/stimuli/STOREBRAND_Inbanner_plain.mp4",
          aspectRatio: 16 / 9,
        },
        {
          id: "aoi_STOREBRAND_Inbanner_Solution",
          src: "https://storage.googleapis.com/stimuli/STOREBRAND_Inbanner_Solution_final.mp4",
          aspectRatio: 16 / 9,
        },
      ],
      preroll: {
        id: "aoi_STOREBRAND_Preroll",
        src: "https://storage.googleapis.com/stimuli/STOREBRAND_Preroll_16_9_15SEK_Les_Mer%20(1).mp4",
        aspectRatio: 16 / 9,
      },
    },
    mainVideo: {
      src: "https://storage.googleapis.com/brandbird/demo-images/mountains.mp4",
    },
  },

  ruter: {
    ads: {
      isRandom: false,
      feed: [
        {
          id: "aoi_Ruter-inbanner_plain",
          src: "https://storage.googleapis.com/stimuli/Ruter-inbanner_plain.mp4",
          aspectRatio: 16 / 9,
        },
        {
          id: "aoi_Ruter_Inbanner_Solution",
          src: "https://storage.googleapis.com/stimuli/Ruter_Inbanner_Solution.mp4",
          aspectRatio: 16 / 9,
        },
      ],
      preroll: {
        id: "aoi_Ruter_preroll",
        src: "https://storage.googleapis.com/stimuli/Ruter_preroll.mp4",

        aspectRatio: 16 / 9,
      },
    },
    mainVideo: {
      src: "https://storage.googleapis.com/brandbird/demo-images/mountains.mp4",
    },
  },

  gartner: {
    ads: {
      isRandom: false,
      feed: [
        {
          id: "aoi_Gartner-inbanner-plain",
          src: "https://storage.googleapis.com/stimuli/Gartner-inbanner-plain.mp4",
          aspectRatio: 1.433,
        },
        {
          id: "aoi_Gartner-inbanner-solution",
          src: "https://storage.googleapis.com/stimuli/Gartner-inbanner-solution.mp4",
          aspectRatio: 1.433,
        },
      ],
      preroll: {
        id: "aoi_Gartner_preroll",
        src: "https://storage.googleapis.com/stimuli/Gartner_preroll.mp4",
        aspectRatio: 1.433,
      },
    },
    mainVideo: {
      src: "https://storage.googleapis.com/brandbird/demo-images/mountains.mp4",
    },
  },
};

export function getConfigByFeed(feed) {
  const c = config[feed];
  return c ? c : config["1"];
}

export default config;
