import { Redirect, Route } from "react-router-dom";
import {
  setupIonicReact,
  IonApp,
  IonBadge,
  IonIcon,
  IonImg,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from "@ionic/react";

import { IonReactRouter } from "@ionic/react-router";
// import { notifications, home, menu, people, film } from "ionicons/icons";
import Feed from "./pages/Feed";
import Pre from "./pages/Pre";
import VgTV from "./pages/VgTV";
import Post from "./pages/Post";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";

import HomeIcon from "./assets/icons/home.png";
import HomeFbOutlineIcon from "./assets/icons/home-fb-outline.png";
import WatchIcon from "./assets/icons/watch.png";
import GroupIcon from "./assets/icons/group.png";
import BellIcon from "./assets/icons/bell.png";
import ShopIcon from "./assets/icons/shop-fb.png";
import HamburgerIcon from "./assets/icons/hamburger.png";

import HomeIconInstagram from "./assets/icons/home-instagram.png";
import HomeIconInstagramActive from "./assets/icons/home-instagram-active.png";
import SearchIconInstagram from "./assets/icons/search-instagram.png";
import WatchIconInstagram from "./assets/icons/watch-instagram.png";
import ShopIconInstagram from "./assets/icons/shop-instagram.png";
import ShopIconInstagramActive from "./assets/icons/shop-instagram-active.png";
import ProfileIconInstagram from "./assets/icons/profile-instagram.png";
// import GroupIcon from "./assets/icons/group.png";
// import BellIcon from "./assets/icons/bell.png";
// import HamburgerIcon from "./assets/icons/hamburger.png";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

import "./App.scss";

/* Theme variables */
import "./theme/variables.css";
import useSearchQuery from "./hooks/useLocationSearch";
import useData from "./hooks/useData";
import AdDetail from "./pages/AdDetail";

import { IntlProvider } from "react-intl";

import en from "./lang/en.json";
import no from "./lang/no.json";
import Shop from "./pages/Shop";
import PreShop from "./pages/PreShop";
import VgPre from "./pages/VgPre";
import VgFeed from "./pages/VgFeed";
import WelcomeShop from "./pages/WelcomeShop";

const messages = {
  en,
  no,
};

setupIonicReact();

const App: React.FC = () => {
  const search = useSearchQuery();
  const id = search.get("feed") || "1";
  const folder = search.get("folder") || "";

  let dataUrl = search.get("dataUrl");

  const { data } = useData({ id, dataUrl, folder });

  const language = data?.language ?? "en";

  const platform = search.get("platform") ?? data?.type;

  return data ? (
    <IntlProvider locale="en" messages={messages[language]} defaultLocale="en">
      <ErrorBoundary>
        <IonApp className={`app ${platform}`}>
          <IonReactRouter basename="/vg-tv">
            <Route exact path="/feed">
              <VgFeed />
            </Route>
            <Route exact path="/article">
              <VgTV />
            </Route>
            <Route exact path="/pre">
              <VgPre />
            </Route>
          </IonReactRouter>
          <IonReactRouter basename="/fb">
            <Route exact path="/pre">
              <Pre />
            </Route>
            <Route exact path="/post">
              <Post />
            </Route>

            {/* <Redirect exact from="/" to="/pre" /> */}
            <Route path="/feed">
              <IonTabs>
                <IonRouterOutlet>
                  <Route exact path="/feed" component={Feed} />
                  <Route exact path="/feed/:itemId" component={AdDetail} />
                </IonRouterOutlet>

                {platform === "facebook" && (
                  <IonTabBar slot="bottom" className="facebook">
                    <IonTabButton tab="feed" href="/feed">
                      <IonImg className="icon" src={HomeIcon} />
                    </IonTabButton>
                    <IonTabButton tab="pre">
                      <IonImg className="icon" src={WatchIcon} />
                    </IonTabButton>
                    <IonTabButton tab="post">
                      <IonImg className="icon" src={GroupIcon} />
                    </IonTabButton>

                    <IonTabButton tab="tab4">
                      <IonImg className="icon" src={BellIcon} />
                      <IonBadge color="danger">6</IonBadge>
                    </IonTabButton>
                    <IonTabButton tab="tab5">
                      <IonImg className="icon" src={HamburgerIcon} />
                    </IonTabButton>
                  </IonTabBar>
                )}
                {platform === "instagram" && (
                  <IonTabBar slot="bottom" className="instagram">
                    <IonTabButton tab="feed" href="/feed">
                      <IonImg className="icon" src={HomeIconInstagramActive} />
                    </IonTabButton>
                    <IonTabButton tab="pre">
                      <IonImg className="icon" src={SearchIconInstagram} />
                    </IonTabButton>
                    <IonTabButton tab="pre">
                      <IonImg className="icon" src={WatchIconInstagram} />
                    </IonTabButton>
                    <IonTabButton tab="tab4">
                      <IonImg className="icon" src={ShopIconInstagram} />
                    </IonTabButton>
                    <IonTabButton tab="tab5">
                      <IonImg className="icon" src={ProfileIconInstagram} />
                    </IonTabButton>
                  </IonTabBar>
                )}
              </IonTabs>
            </Route>

            <Route path="/shop">
              <IonTabs>
                <IonRouterOutlet>
                  <Route exact path="/shop" component={Shop} />
                </IonRouterOutlet>

                {platform === "facebook" && (
                  <IonTabBar slot="bottom" className="facebook">
                    <IonTabButton tab="feed">
                      <IonImg className="icon" src={HomeFbOutlineIcon} />
                    </IonTabButton>
                    <IonTabButton tab="pre">
                      <IonImg className="icon" src={WatchIcon} />
                    </IonTabButton>
                    <IonTabButton tab="post">
                      <IonImg className="icon" src={GroupIcon} />
                    </IonTabButton>
                    <IonTabButton tab="tab4">
                      <div
                        style={{
                          width: "40px",
                          height: "2px",
                          background: "#1B74E4",
                          transform: "translateY(-7px)",
                        }}
                      />
                      <IonImg className="icon" src={ShopIcon} />
                    </IonTabButton>
                    <IonTabButton tab="tab5">
                      <IonImg className="icon" src={BellIcon} />
                    </IonTabButton>
                    <IonTabButton tab="tab6">
                      <IonImg className="icon" src={HamburgerIcon} />
                    </IonTabButton>
                  </IonTabBar>
                )}
                {platform === "instagram" && (
                  <IonTabBar slot="bottom" className="instagram">
                    <IonTabButton tab="feed">
                      <IonImg className="icon" src={HomeIconInstagram} />
                    </IonTabButton>
                    <IonTabButton tab="search">
                      <IonImg className="icon" src={SearchIconInstagram} />
                    </IonTabButton>
                    <IonTabButton tab="watch">
                      <IonImg className="icon" src={WatchIconInstagram} />
                    </IonTabButton>
                    <IonTabButton tab="shop">
                      <IonImg className="icon" src={ShopIconInstagramActive} />
                    </IonTabButton>
                    <IonTabButton tab="profile">
                      <IonImg className="icon" src={ProfileIconInstagram} />
                    </IonTabButton>
                  </IonTabBar>
                )}
              </IonTabs>
              <Route exact path="/shop/welcome" component={WelcomeShop} />
              <Route exact path="/shop/pre" component={PreShop} />
            </Route>
          </IonReactRouter>
        </IonApp>
      </ErrorBoundary>
    </IntlProvider>
  ) : null;
};

export default App;
