import {
  IonButton,
  IonContent,
  IonGrid,
  IonImg,
  IonPage,
  IonRow,
} from "@ionic/react";

import Logo from "../../assets/icons/vg/vg-logo.svg";
import HomeLogo from "../../assets/icons/vg/vg-home-logo.svg";
import AccountIcon from "../../assets/icons/vg/vg-account.svg";
import SearchIcon from "../../assets/icons/vg/vg-search.svg";
import MenuIcon from "../../assets/icons/vg/menu.svg";
import "./Header.css";

const Header = ({ isHome = false }) => {
  return (
    <IonGrid className="m-0 p-0">
      <IonRow
        className="ion-justify-content-between"
        style={{
          padding: "1rem",
          background: isHome ? "#DE0100" : "transparent",
        }}
      >
        <IonImg
          className={isHome ? "vg-home-logo" : "vg-tv-logo"}
          src={isHome ? HomeLogo : Logo}
        />
        <IonRow className="ion-align-items-center">
          <IonImg
            className={
              isHome ? "vg-home-header-account-icon" : "vg-tv-header-icon"
            }
            src={AccountIcon}
            style={{ marginRight: "1.5rem" }}
          />
          {isHome ? (
            <IonImg
              className={isHome ? "vg-home-header-icon" : "vg-tv-header-icon"}
              src={MenuIcon}
            />
          ) : (
            <IonImg
              className={isHome ? "vg-home-header-icon" : "vg-tv-header-icon"}
              src={SearchIcon}
            />
          )}
        </IonRow>
      </IonRow>
    </IonGrid>
  );
};

export default Header;
