import { FC, SyntheticEvent, useCallback, useRef } from "react";
import { Slideshow, SlideshowItem } from "../../global";
import VisibilitySensor from "react-visibility-sensor";

import useMetric from "../../hooks/useMetric";

import {
  IonAvatar,
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonToolbar,
} from "@ionic/react";
import { ellipsisHorizontal } from "ionicons/icons";

import "./SlideShowItemInstagram.scss";

interface SlideShowProps {
  item: Slideshow;
  onCTAClick?: (item: SlideshowItem) => void;
  onImageClick?: (item: SlideshowItem) => void;
}

const SimpleItem = ({ item }: { item: SlideshowItem }) => {
  return (
    <div className="simple-item">
      <IonLabel>{item.title}</IonLabel>
    </div>
  );
};

const FullItem = ({
  item,
  onClick,
}: {
  item: SlideshowItem;
  onClick: () => void;
}) => {
  return (
    <div className="full-item">
      {/* <IonLabel>{item.title}</IonLabel> */}
      <IonButton strong expand="block" onClick={onClick}>
        {item.buttonText}
      </IonButton>
    </div>
  );
};

const SlideShow: FC<SlideShowProps> = ({
  item,
  onCTAClick = () => void 0,
  onImageClick = () => void 0,
}: SlideShowProps) => {
  const { track } = useMetric();
  const containmentContainer = useRef<HTMLIonContentElement>(null);
  const clickHandler = useCallback(
    (event: SyntheticEvent) => event.preventDefault(),
    []
  );

  const visibilityHandler = useCallback(
    (item: SlideshowItem) => (isVisible: boolean) => {
      if (isVisible) {
        track("ad-on-page", item);
      } else {
        if (containmentContainer.current) {
          track("ad-off-page", item);
        }
      }
    },
    [track]
  );

  return (
    <IonItem className="slideshow-item-instagram">
      <IonCard>
        <IonCardHeader>
          <IonToolbar>
            <IonItem>
              <IonLabel>
                <h2>{item.title}</h2>
                <p className="meta">{item.subtitle}</p>
              </IonLabel>
            </IonItem>
            <IonButton
              slot="end"
              fill="clear"
              onClick={clickHandler}
              color="dark"
            >
              <a>See all</a>
            </IonButton>
          </IonToolbar>
        </IonCardHeader>
        <IonCardContent ref={containmentContainer}>
          {item.items.map((item, i) => (
            <VisibilitySensor
              partialVisibility
              offset={{ left: 138, right: 138 }}
              key={item.id}
              onChange={visibilityHandler(item)}
            >
              <IonCard>
                <IonCardHeader>
                  <IonToolbar>
                    <IonItem>
                      <IonAvatar slot="start">
                        <IonImg src={item.avatar} />
                      </IonAvatar>
                      <IonLabel>
                        <h2>{item.title}</h2>
                      </IonLabel>
                    </IonItem>
                    <IonButton
                      slot="end"
                      fill="clear"
                      color="dark"
                      shape="round"
                      className="close-action"
                    >
                      <IonIcon
                        slot="icon-only"
                        size="small"
                        icon={ellipsisHorizontal}
                      />
                    </IonButton>
                  </IonToolbar>
                </IonCardHeader>
                <IonImg
                  onClick={() => onImageClick(item)}
                  src={item.image}
                  alt={item.title}
                  className="item-image"
                ></IonImg>
                <IonCardContent>
                  {!item.buttonText && <SimpleItem item={item} />}
                  {item.buttonText && (
                    <FullItem item={item} onClick={() => onCTAClick(item)} />
                  )}
                </IonCardContent>
              </IonCard>
            </VisibilitySensor>
          ))}
        </IonCardContent>
      </IonCard>
    </IonItem>
  );
};

export default SlideShow;
