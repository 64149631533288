import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { QueryClient, QueryClientProvider } from "react-query";
import { IonReactRouter } from "@ionic/react-router";

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
  dsn: "https://c072648eb9ac49ff9b9fa40af6f6a008@o575319.ingest.sentry.io/6752654", // Neurons
  // dsn: "https://effb4f5e251948a29dd03cbf9ad23884@o1412156.ingest.sentry.io/6750969", Mine
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  ignoreErrors: [
    "TypeError: Failed to fetch",
    "TypeError: NetworkError when attempting to fetch resource.",
  ],
  tracesSampleRate: 1.0,
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

ReactDOM.render(
  // <React.StrictMode>
  <QueryClientProvider client={queryClient}>
    <IonReactRouter basename="/fb">
      <App />
    </IonReactRouter>
  </QueryClientProvider>,
  // </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
