import React from "react";
import {
  IonItem,
  IonAvatar,
  IonImg,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonContent,
  IonChip,
  IonButton,
} from "@ionic/react";
import BookmarkOutlineIcon from "../../assets/icons/insta/bookmark-outline.png";
import BookmarkFillIcon from "../../assets/icons/insta/bookmark-fill.png";

import "./PurchaseCta.css";

export default function PurchaseCta({
  price = "",
  discount = "",
  cta = "",
  src = "",
  name = "",
  onCtaClick = () => {},
  product = {},
}) {
  return (
    <IonGrid className="fb-purchase-cta-wrapper ">
      <IonRow className="ion-align-items-center">
        <IonImg className="fb-purchase-cta-img" src={product?.images[0]?.src} />
        <IonCol>
          <p className="fb-purchase-cta-name">{product?.name}</p>
          <IonRow className="ion-align-items-center">
            <p className="fb-purchase-cta-discount">{product?.price}</p>
            {/* <p className="fb-purchase-cta-discount">{discount}</p> */}
            {/* <p className="fb-purchase-cta-price">{product?.price}</p> */}
          </IonRow>
        </IonCol>
        <p className="fb-purchase-cta-button" onClick={onCtaClick}>
          {cta}
        </p>
      </IonRow>
    </IonGrid>
  );
}
