import React, { createElement } from "react";
import {
  IonButton,
  IonContent,
  IonGrid,
  IonImg,
  IonPage,
  IonRow,
  useIonRouter,
} from "@ionic/react";

import PlayIcon from "../../assets/icons/vg/play.svg";

import "./ArticleCard.css";
import { getTimeLeft } from "./VideoPlayer";
import {
  VG_TV_ARTICLE_VIDEO_REF,
  VG_TV_ARTICLE_TITLE_REF,
} from "../../pages/VgFeed";

const ArticleCard = ({ title = [], ...rest }) => {
  const ref = React.useRef(null);

  const { src, video, subtitle, isDark = false, isVideo = false } = rest;

  const parentWidth = React.useMemo(
    () => ref?.current?.clientWidth ?? window?.innerWidth,
    [ref]
  );

  return (
    <IonGrid
      ref={ref}
      className={
        "m-0 p-0 " + (isDark ? "vg-article-card-dark" : "vg-article-card-light")
      }
      onClick={() => {
        localStorage.setItem(VG_TV_ARTICLE_VIDEO_REF, video);
        localStorage.setItem(VG_TV_ARTICLE_TITLE_REF, title.join(" "));
      }}
    >
      <IonRow
        className="m-0 p-0"
        style={{ position: "relative", height: "100%" }}
      >
        {isVideo && (
          <div
            style={{
              position: "absolute",
              bottom: "0.5rem",
              left: "0.5rem",
              background: "#00000088",
              color: "white",
              display: "flex",
              padding: "4px 6px 4px 4px",
              borderRadius: "5px",
              fontSize: "13px",
              fontWeight: "700",
            }}
          >
            <img src={PlayIcon} />
            &nbsp;&nbsp; 00:24
          </div>
        )}

        <IonImg className="vg-article-card-img" src={src} />
      </IonRow>
      {subtitle && (
        <IonRow className="m-0 p-0">
          <p className="vg-article-card-subtitle">{subtitle}</p>
        </IonRow>
      )}
      <IonRow className="ion-justify-content-between vg-article-card-title-container">
        <div className="vg-article-card-title-container-2">
          {title.map((p) => (
            <Line parentWidth={parentWidth}>{p}</Line>
          ))}
        </div>
      </IonRow>
    </IonGrid>
  );
};

const INIT_FONTSIZE = 16;
const TIMEOUT_DELAY = 300;

function Line({ parentWidth, children }) {
  const ref = React.useRef(null);
  const [fontSize, setFontSize] = React.useState(INIT_FONTSIZE);

  React.useEffect(() => {
    const interval = setInterval(adjustFontSize, 1000);
    setTimeout(() => clearInterval(interval), 6000);

    window.addEventListener("resize", adjustFontSize);
    return () => {
      window.removeEventListener("resize", adjustFontSize);
      clearInterval(interval);
    };
  }, []);

  const adjustFontSize = React.useCallback(() => {
    const p = ref.current;

    if (!p) {
      setTimeout(adjustFontSize, TIMEOUT_DELAY);
      return;
    }

    const offset = 1;
    const grandparentWidth = p.parentNode.parentNode.clientWidth;
    const parentWidth = p.parentNode.clientWidth;
    const width = Math.min(p.clientWidth, parentWidth);
    const count = children?.length;

    if (!(width > 0 && parentWidth > 0)) {
      setTimeout(adjustFontSize, TIMEOUT_DELAY);
      return;
    }

    const letterWidth = width / count;
    const newLetterWidth = parentWidth / count;

    const fontSize = Number(
      window.getComputedStyle(p).fontSize.replace("px", "")
    );

    const newFontSize = Math.floor(
      (offset * (fontSize * newLetterWidth)) / letterWidth
    );

    // if (children.includes("leve med")) {
    //   console.log({ grandparentWidth, parentWidth, width, count, fontSize });
    //   console.log({ newFontSize });
    // }

    setFontSize(newFontSize);
  }, [ref, children]);

  React.useEffect(() => {
    const p = ref.current;
    if (p && parentWidth) {
      const isOnScreen = p.clientWidth < 0.95 * parentWidth;

      if (!isOnScreen) {
        setFontSize(fontSize - 2);
      }
    }
  }, [fontSize, parentWidth]);

  return (
    <p
      ref={ref}
      className="vg-article-card-title"
      style={{ fontSize: fontSize + "px" }}
    >
      {children}
    </p>
  );
}

function useOnScreen(ref) {
  const [isIntersecting, setIntersecting] = React.useState(false);

  const observer = new IntersectionObserver(([entry]) =>
    setIntersecting(entry.isIntersecting)
  );

  React.useEffect(() => {
    observer.observe(ref.current);
    // Remove the observer as soon as the component is unmounted
    return () => {
      observer.disconnect();
    };
  }, []);

  return isIntersecting;
}

function VideoPreview({ src }) {
  const ref = React.useRef(null);
  const isVisible = useOnScreen(ref);

  const [isPlaying, setIsPlaying] = React.useState(false);
  const [timeLeft, setTimeLeft] = React.useState("00:00");

  React.useLayoutEffect(() => {
    // play();
    const video = ref.current;
    if (video) {
      setTimeLeft(getTimeLeft(video));
    }
  }, []);

  React.useEffect(() => {
    const video = ref.current;
    if (!video) return;
    const timeLeft = getTimeLeft(video);
    setTimeLeft(timeLeft);
    if (isVisible) {
      setTimeout(() => {
        setIsPlaying(true);
        video.play();
      }, 1000);
    } else {
      setIsPlaying(false);
      video.pause();
    }
  }, [isVisible]);

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
        maxHeight: "100%",
        maxWidth: "100%",
        // aspectRatio: 16 / 9 + "",
        overflow: "hidden",
      }}
    >
      <video
        ref={ref}
        autoPlay
        muted
        playsInline
        style={{
          minWidth: "100%",
          minHeight: "100%",
          maxWidth: "120%",
          maxWidth: "120%",

          /* Setting width & height to auto prevents the browser from stretching or squishing the video */
          width: "auto",
          height: "100%",

          /* Center the video */
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
        }}
      >
        <source id="mp4" src={src} type="video/mp4" />
        <p>Your user agent does not support the HTML5 Video element.</p>
      </video>

      {!isPlaying && (
        <div
          style={{
            position: "absolute",
            bottom: "0.5rem",
            left: "0.5rem",
            background: "#00000088",
            color: "white",
            display: "flex",
            padding: "4px 6px 4px 4px",
            borderRadius: "5px",
            fontSize: "13px",
            fontWeight: "700",
          }}
        >
          <img src={PlayIcon} />
          &nbsp;&nbsp;
          {timeLeft}
        </div>
      )}
    </div>
  );
}

export default ArticleCard;
