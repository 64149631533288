import React from "react";
import {
  IonButton,
  IonRow,
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonPage,
} from "@ionic/react";
import { useHistory } from "react-router";
import Header from "../components/VgTV/Header";

import Footer from "../assets/icons/vg/vg-footer.png";

import "./VgTV.css";
import VideoInfo from "../components/VgTV/VideoInfo";
import ArticleCard from "../components/VgTV/ArticleCard";
import AdsSection from "../components/VgTV/AdsSection";
import BannerAd from "../components/VgTV/BannerAd";
import useSearchQuery from "../hooks/useLocationSearch";
import useMetric from "../hooks/useMetric";
import {
  isVideoSeen,
  VG_TV_METRICS_REF,
  VG_TV_TIMEOUT_PERIOD,
  VG_TV_TIMER_REF,
} from "./VgTV";
import timer from "../utils/timer";
import EndSection from "../components/EndSection/EndSection";
import { getConfigByFeed } from "../components/VgTV/config";
import { shuffle } from "./Feed";
import { Link } from "react-router-dom";

const ADS_STORAGE_REF = "VG_TV_ADS";
const FEED_STORAGE_REF = "VG_TV_FEED";
export const VG_TV_ARTICLE_VIDEO_REF = "VG_TV_ARTICEL_VIDEO_REF";
export const VG_TV_ARTICLE_TITLE_REF = "VG_TV_ARTICLE_TITLE_REF";

const VgFeed: React.FC = () => {
  const history = useHistory();
  const search = useSearchQuery();
  const { track } = useMetric(VG_TV_METRICS_REF);

  const isSeen = React.useMemo(() => isVideoSeen(), []);

  React.useEffect(() => {
    if (isSeen) {
      history.push("/feed" + window.location.search + "&visitCount=2");
    }
  }, [isSeen]);

  const [ads, setAds] = React.useState([]);
  const feedId = React.useMemo(() => search.get("feed"), [search]);

  React.useEffect(() => {
    const prevAds = localStorage.getItem(ADS_STORAGE_REF)
      ? JSON.parse(localStorage.getItem(ADS_STORAGE_REF) as string)
      : [];

    console.log({ prevAds });

    const hasPrevAds = prevAds?.length > 0;
    if (hasPrevAds) {
      setAds(prevAds);
    } else {
      const { ads } = getConfigByFeed(feedId);

      const { isRandom } = ads;

      console.log(isRandom);

      const newAds = (isRandom ? shuffle(ads.feed) : ads.feed) ?? [];
      localStorage.setItem(ADS_STORAGE_REF, JSON.stringify(newAds));
      // @ts-ignore
      setAds(newAds);
    }
  }, [feedId]);

  const containmentContainer = React.useRef<HTMLIonContentElement>(null);

  React.useEffect(() => {
    timer({
      duration: VG_TV_TIMEOUT_PERIOD,
      onStart: () => track("session-start"),
      onEnd: () => {
        track("session-end");

        window.location.href = `/fb/post?metricsRef=${VG_TV_METRICS_REF}&${search.toString()}`;
        // const isLocalhost = window.location.hostname.includes("localhost");

        // if (!isLocalhost) {
        //   window.location.href = `/fb/post?metricsRef=${VG_TV_METRICS_REF}&${search.toString()}`;
        // }
      },
      storageId: VG_TV_TIMER_REF,
    });

    // initialize all tracked items
    track("ad-off-page", { id: "preroll-video" });
  }, []);

  const onVisibilityChange = React.useCallback(
    (payload: object) => (isVisible: boolean) => {
      if (isVisible) {
        track("ad-on-page", payload);
      } else {
        track("ad-off-page", payload);
      }
    },
    [track]
  );

  const onAdClick = React.useCallback(
    (payload: object) => () => track("ad-click", payload),
    [track]
  );

  return (
    <IonPage>
      <IonContent
        ref={containmentContainer}
        forceOverscroll
        scrollY
        scrollEvents
      >
        <div id="scrollable-container" className="vg-feed-container">
          <Header isHome />
          <IonGrid className="m-0 p-0">
            <ArticleCard
              {...articles[0]}
              // isDark={false}
            />

            <AdsSection
              leftAd={
                <ArticleCard
                  {...articles[1]}
                  // isDark={false}
                />
              }
              rightAd={
                <Link
                  to={"/article" + window.location.search + "&visitCount=2"}
                  className="a-remove-style"
                >
                  <ArticleCard
                    {...videos[0]}
                    isVideo
                    // src="https://storage.googleapis.com/brandbird/demo-images/preroll.mp4"
                    // isDark={false}
                  />
                </Link>
              }
            />

            {ads?.length > 1 && (
              <BannerAd
                // @ts-ignore
                {...ads[0]}
                // @ts-ignore
                isSeen={isSeen}
                onVisibilityChange={onVisibilityChange}
                onAdClick={onAdClick}
                containment={containmentContainer.current}
              />
            )}

            <AdsSection
              isDark
              title="MASSESKYTINGEN I OSLO"
              portion={0.6}
              leftAd={<ArticleCard {...articles[3]} />}
              rightAd={<ArticleCard {...articles[12]} />}
            />
            <ArticleCard {...articles[5]} />

            {/* {articles.map((article) => (
              <ArticleCard {...article} />
            ))} */}

            {ads?.length > 1 && (
              <BannerAd
                // @ts-ignore
                {...ads[1]}
                // @ts-ignore
                isSeen={isSeen}
                onVisibilityChange={onVisibilityChange}
                onAdClick={onAdClick}
                containment={containmentContainer.current}
                hasMargin
              />
            )}

            <ArticleCard {...articles[6]} />

            <AdsSection
              portion={0.5}
              leftAd={<ArticleCard {...articles[7]} />}
              rightAd={
                <Link
                  to={"/article" + window.location.search + "&visitCount=2"}
                  className="a-remove-style"
                >
                  <ArticleCard {...videos[1]} isVideo />
                </Link>
              }
            />
            <AdsSection
              portion={0.6}
              leftAd={<ArticleCard {...articles[9]} />}
              rightAd={<ArticleCard {...articles[10]} />}
            />
            <ArticleCard {...articles[11]} />
          </IonGrid>

          <IonImg src={Footer} style={{ width: "100%", minHeight: "100px" }} />

          {isSeen && (
            <EndSection
              extraClasses="vg-tv-end-section-cta-lg"
              metricsRef={VG_TV_METRICS_REF}
              timerRef={VG_TV_TIMER_REF}
            />
          )}
        </div>
      </IonContent>
      {/* @ts-ignore */}
      <style jsx global>{`
        html,
        body {
          height: auto !important;
        }
        .ion-page {
          position: relative !important;
          width: 100% !important;
          height: auto !important;
          min-height: 100vh;
        }
        #scrollable-container {
          height: auto;
        }
      `}</style>
    </IonPage>
  );
};

export default VgFeed;

// [...document.querySelectorAll(".article-container")].map((ad) => {
//   return {
//    src: ad.querySelector("img")?.srcset?.split(" ")[0],
//     video: ad.querySelector("video")?.src,
//     subtitle: ad.querySelector(".insert-title ")?.textContent,
//     title: [...ad.querySelectorAll(".d-block ")].map((b) => b?.textContent),
//   };
// }).filter(ad => ad.img && ad.subtitle && ad.title);
// }).filter(ad => ad.video);

const videos = [
  {
    src: "https://akamai.vgc.no/drfront/images/2022/08/22/c=150,13,827,702;w=319;h=271;705001.jpg?format=auto",
    video:
      "https://amd-vgtv.akamaized.net/vgtv/vod/2022/08/63025353555b770008b1c993/720_3500_pkg.mp4",
    title: ["Mistet lappen", "på stedet"],
  },
  {
    src: "https://akamai.vgc.no/drfront/images/2022/08/22/c=22,48,650,483;w=376;h=279;705031.jpg?format=auto",
    video:
      "https://amd-vgtv.akamaized.net/vgtv/vod/2022/08/63036ee789c118000816bb33/1080_6000_pkg.mp4",
    title: ["- Nydelige bilder"],
  },
  {
    src: "https://akamai.vgc.no/drfront/images/2022/08/02/c=196,101,526,315;w=319;h=191;701160.jpg?format=auto",
    video:
      "https://amd-vgtv.akamaized.net/vgtv/vod/2022/08/62e83a7641907611ecf3a1a3/1080_6000_pkg.mp4",
    title: ["Guri (61) blir", "sett av millioner", "på TikTok"],
  },
];

const articles = [
  {
    src: "https://akamai.vgc.no/drfront/images/2022/08/22/c=0,-3,992,678;w=319;h=218;705029.jpg?format=auto",
    subtitle: "Får kritikk:",
    title: ["Satte sitt eget barn i", "veien for å teste selvkjøring"],
  },
  {
    src: "https://akamai.vgc.no/drfront/images/2022/08/22/c=62,0,564,407;w=262;h=189;705060.jpg?format=auto",
    subtitle: "Medier:",
    title: ["Flytter lagmøte"],
  },
  {
    src: "https://www.vg.no/spill/ordstjernen/preview/today-nice.png",
    subtitle: "Ny i dag:",
    title: ["Klarer du dagens", "ordstjerne?"],
  },
  {
    src: "https://akamai.vgc.no/drfront/images/2022/08/22/c=324,0,780,691;w=262;h=232;705024.jpg?format=auto",
    subtitle: "Audun (25) døde:",
    title: ["- Aldri kjent meg", "så hjelpeløs"],
  },
  {
    src: "https://akamai.vgc.no/drfront/images/2022/08/22/c=395,75,493,465;w=262;h=247;705055.jpg?format=auto",
    subtitle: "Sandra Thoen:",
    title: ["Har fått", "kjæreste"],
  },
  {
    src: "https://akamai.vgc.no/drfront/images/2022/08/22/c=35,0,711,346;w=376;h=183;705023.jpg?format=auto",
    subtitle: "Aksjeråd:",
    title: ["Tjen penger på de høye", "strømprisene"],
  },
  {
    src: "https://akamai.vgc.no/drfront3/images/b89f7f5d50d883b203f90402ede5684f.jpg?format=auto",
    subtitle: "Trenger du nye løpesko?",
    title: ["Med denne får du skoene", "som er perfekt for deg"],
  },
  {
    src: "https://akamai.vgc.no/drfront/images/2022/08/18/c=41,18,1705,871;w=376;h=192;704300.jpg?format=auto",
    subtitle: "Michael Mosley:",
    title: ["Mat de gode", "bakteriene -", "forebygg kreft"],
  },
  {
    src: "https://akamai.vgc.no/drfront/images/2022/08/22/c=33,120,1028,610;w=376;h=223;705015.jpg?format=auto",
    subtitle: "Kjapt forklart:",
    title: ["Streik første", "skoledag"],
  },
  {
    src: "https://akamai.vgc.no/drfront3/images/dd4b748d04b4ac1fed048d3992e061a0.jpg?format=auto",
    subtitle: "Denne pastaen er helt spesiell:",
    title: ["Disse oppskriftene", "kommer du til å elske"],
  },
  {
    src: "https://akamai.vgc.no/drfront/images/2022/08/22/c=60,44,428,287;w=376;h=252;705004.jpg?format=auto",
    subtitle: "«Skal vi danse»-profil:",
    title: ["På sykehus"],
  },
  {
    src: "https://akamai.vgc.no/drfront/images/2022/08/22/c=0,0,1280,839;w=319;h=209;705009.jpg?format=auto",
    subtitle: "Norge øker NATO-bidraget:",
    title: ["- Utgjør en viktig rolle"],
  },
  {
    src: "https://akamai.vgc.no/drfront/images/2022/08/22/c=0,166,1888,988;w=319;h=167;704967.jpg?format=auto",
    subtitle: "Ut mot Høyre:",
    title: ["- Rett og slett", "hjerterått"],
  },
  {
    src: "https://akamai.vgc.no/drfront/images/2022/08/19/c=245,0,2009,1978;w=262;h=258;704550.jpg?format=auto",
    video: "",
    subtitle: "«Royalteen»:",
    title: ["- Føles som", "et angrep"],
  },
  {
    src: "https://akamai.vgc.no/drfront/images/2022/08/18/c=35,65,1213,161;w=654;h=87;704327.jpg?format=auto",
    subtitle: "Man. United – Liverpool:",
    title: ["Fra 19.00: Følg stor-", "kampen med LFCTV"],
  },
  {
    src: "https://akamai.vgc.no/drfront3/images/11d4322d8af63af62d3543a0750b0227.jpg?format=auto",
    subtitle: "Kristin (32) fant en løsning mot tynt hår:",
    title: ["Frisøren hadde aldri", "sett så mye ny hårvekst"],
  },
  {
    src: "https://akamai.vgc.no/drfront3/images/12a0c62403c15b2ca98b9659a127125c.jpg?format=auto",
    subtitle: "Apotekansatt om hårvitaminer:",
    title: ["- Et multiprodukt", "som passer de fleste"],
  },
  {
    src: "https://akamai.vgc.no/drfront3/images/c22c675e560e153633377939141a01dd.jpg?format=auto",
    subtitle: "Kristin (32) slet lenge med tynt og trasig hår:",
    title: ["Så kom hun over dette", "vitamintilskuddet"],
  },
  {
    src: "https://akamai.vgc.no/drfront/images/2022/08/22/c=60,44,428,287;w=376;h=252;705004.jpg?format=auto",
    subtitle: "«Skal vi danse»-profil:",
    title: ["På sykehus"],
  },
  {
    src: "https://akamai.vgc.no/drfront/images/2022/08/22/c=155,0,967,612;w=319;h=202;704993.jpg?format=auto",
    subtitle: "Latterliggjøres:",
    title: ["- Kostet dette", "milliarder?"],
  },
  {
    src: "https://akamai.vgc.no/drfront/images/2022/08/16/c=281,71,646,460;w=319;h=227;703896.jpg?format=auto",
    subtitle: "Skolevegring:",
    title: ["- Man føler det", "er urettferdig"],
  },
  {
    src: "https://akamai.vgc.no/drfront/images/2022/08/22/c=490,87,1109,876;w=319;h=252;704950.jpg?format=auto",
    subtitle: "Eksilpolitiker om bilbomben:",
    title: ["Hevder russisk", "gruppe står bak"],
  },
  {
    src: "https://akamai.vgc.no/drfront/images/2022/08/22/c=895,133,3579,2103;w=654;h=384;705003.jpg?format=auto",
    subtitle: "KrF i Bergen:",
    title: ["- Feil prioritering av", "skattebetalernes penger"],
  },
  {
    src: "https://akamai.vgc.no/drfront/images/2022/08/22/c=0,0,800,497;w=376;h=234;704971.jpg?format=auto",
    subtitle: "Norges beryktede sexklubb:",
    title: ["- Dette er min", "friplass"],
  },
  {
    src: "https://akamai.vgc.no/drfront/images/2022/08/22/c=108,177,1011,520;w=319;h=164;704949.jpg?format=auto",
    subtitle: "Beslaglagt superyacht:",
    title: ["Selges på", "auksjon"],
  },
  {
    src: "https://akamai.vgc.no/drfront/images/2022/08/19/c=1135,485,3291,2146;w=319;h=208;704579.jpg?format=auto",
    subtitle: "Test:",
    title: ["Har alt vi", "ønsker oss"],
  },
  {
    src: "https://akamai.vgc.no/drfront/images/2016/05/27/c=384,528,4512,2436;w=376;h=203;280602.jpg?format=auto",
    subtitle: "Tidligere Vimpelcom-sjef:",
    title: ["Trekker erstatnings-", "saken mot staten"],
  },
  {
    src: "https://akamai.vgc.no/drfront/images/2022/08/22/c=273,91,2984,2357;w=262;h=207;704961.jpg?format=auto",
    subtitle: "Tidligere statsminister:",
    title: ["Siktet etter", "terrorlovgivning"],
  },
  {
    src: "https://akamai.vgc.no/drfront/images/2022/08/16/c=171,158,802,499;w=376;h=234;703900.jpg?format=auto",
    subtitle: "Silje Sandmæl:",
    title: ["- Vi er et veldig", "bra team"],
  },
  {
    src: "https://akamai.vgc.no/drfront/images/2022/08/19/c=9,0,1777,1153;w=319;h=207;704600.jpg?format=auto",
    subtitle: "Ekspert:",
    title: ["Dette kan trekke", "prisen ned"],
  },
  {
    src: "https://akamai.vgc.no/drfront/images/2022/08/16/c=206,90,945,684;w=376;h=272;703862.jpg?format=auto",
    subtitle: "Fødselsskade:",
    title: ["- Flaut"],
  },
  {
    src: "https://akamai.vgc.no/drfront/images/2022/08/21/c=53,118,657,377;w=211;h=121;704852.jpg?format=auto",
    subtitle: "Opplysninger til VG:",
    title: ["Derfor er han mistenkt", "for Tina-drapet"],
  },
  {
    src: "https://akamai.vgc.no/drfront/images/2021/03/28/c=319,0,654,694;w=262;h=278;596237.jpg?format=auto",
    subtitle: "Barnevernet:",
    title: ["Politiet må", "varsle"],
  },
  {
    src: "https://akamai.vgc.no/drfront/images/2022/08/20/c=233,20,807,698;w=319;h=276;704791.jpg?format=auto",
    video: "",
    subtitle: "Vukicevic:",
    title: ["- Over meg", "av følelser"],
  },
  {
    src: "https://akamai.vgc.no/drfront/images/2022/08/21/c=306,313,933,709;w=262;h=199;704833.jpg?format=auto",
    subtitle: "Norske søsken:",
    title: ["Fikk sitt livs", "opplevelse", "foran 80.000"],
  },
  {
    src: "https://akamai.vgc.no/drfront/images/2022/08/17/c=670,417,722,463;w=376;h=240;704008.jpg?format=auto",
    subtitle: "Styrk forholdet:",
    title: ["Seks sekunder hver", "dag gjør susen"],
  },
  {
    src: "https://akamai.vgc.no/drfront/images/2022/08/19/c=86,23,1823,1200;w=376;h=248;704585.jpg?format=auto",
    subtitle: "Fant kjole på loftet:",
    title: ["Verdt hundre-", "tusener"],
  },
  {
    src: "https://akamai.vgc.no/drfront/images/2022/08/19/c=1142,85,2797,3256;w=262;h=305;704584.jpg?format=auto",
    subtitle: "Test:",
    title: ["Trolig den", "kuleste mobilen"],
  },
  {
    src: "https://akamai.vgc.no/drfront/images/2022/08/21/c=383,60,687,769;w=262;h=293;704830.jpg?format=auto",
    subtitle: "Måtte droppe bryllup:",
    title: ["- Kjipt"],
  },
  {
    src: "https://akamai.vgc.no/drfront/images/2022/08/21/c=296,177,708,343;w=376;h=182;704905.jpg?format=auto",
    subtitle: "Avgjørende dommerfeil:",
    title: ["- Trøste", "og bære"],
  },
  {
    src: "https://akamai.vgc.no/drfront/images/2022/08/21/w=654;h=368;704862.jpg?format=auto",
    subtitle: "Må hente ned «Instagram-jegere»:",
    title: ["Følger stier", "laget av sau"],
  },
  {
    src: "https://akamai.vgc.no/drfront/images/2022/08/21/c=0,0,1240,1000;w=376;h=303;704817.jpg?format=auto",
    subtitle: "Alvorlig kreftsyk:",
    title: ["- En menneskerett", "å beholde håpet"],
  },
  {
    src: "https://akamai.vgc.no/drfront/images/2022/01/21/c=462,9,1405,1066;w=319;h=242;660007.jpg?format=auto",
    subtitle: "Vinner av Amandaprisen:",
    title: ["Petters vei til", "å velge livet"],
  },
  {
    src: "https://smp.vgc.no/v2/images/17658112-f28d-4b8f-8eec-7205cd48588f?fit=crop&h=400&w=400&s=d2130a995b82927c449330b0db79049c566b2954",
    subtitle: "Serietips:",
    title: [],
  },
  {
    src: "https://akamai.vgc.no/drfront/images/2022/08/18/c=30,42,589,419;w=319;h=227;704222.jpg?format=auto",
    subtitle: "Nye demensfunn:",
    title: [],
  },
  {
    src: "https://smp.vgc.no/v2/images/8c160da5-7119-4a23-8c07-6253894f8533?fit=crop&h=400&w=400&s=9c646765225175842c2f7512948843a77b5e232c",
    subtitle: "«Forbrukerfrue»:",
    title: [],
  },
  {
    src: "https://imbo.vgc.no/s/VHhpaL5",
    subtitle: "Geirs mareritt:",
    title: [],
  },
  {
    src: "https://akamai.vgc.no/drfront/images/2022/08/19/c=842,335,2380,1424;w=319;h=191;704535.jpg?format=auto",
    subtitle: "Test:",
    title: [],
  },
  {
    src: "https://smp.vgc.no/v2/images/c93eeae3-a1e1-4075-b5cf-7d1b110cf50e?fit=crop&h=400&w=400&s=461c332a62b8b36043e46791cdf52f51fa85621f",
    subtitle: "«Jackass»-stjernene:",
    title: [],
  },
  {
    src: "https://akamai.vgc.no/drfront/images/2022/08/19/c=247,0,951,893;w=262;h=246;704540.jpg?format=auto",
    subtitle: "Test:",
    title: [],
  },
  {
    src: "https://akamai.vgc.no/drfront/images/2022/08/08/c=1548,1428,2826,1954;w=376;h=260;702282.jpg?format=auto",
    subtitle: "Gettoen for pedofile:",
    title: [],
  },
  {
    src: "https://smp.vgc.no/v2/images/a3312896-9e79-42c4-a151-2c45305b3264?fit=crop&h=400&w=400&s=2cb8e0564184c09f3614cff7160153b24548ef10",
    subtitle: "Joggeprogram:",
    title: [],
  },
  {
    src: "https://smp.vgc.no/v2/images/368ad292-9175-4938-b129-a1835731b561?fit=crop&h=400&w=400&s=559087e631ba99a5ac5c224402b30a1d5c6c6aeb",
    subtitle: "Renault Megane E-tech:",
    title: [],
  },
  {
    src: "https://akamai.vgc.no/drfront/images/2022/08/17/c=433,6,723,897;w=262;h=325;703995.jpg?format=auto",
    subtitle: "Eyeliner-triks:",
    title: [],
  },
  {
    src: "https://smp.vgc.no/v2/images/a7520b28-ab08-4316-bbb8-efe38a543272?fit=crop&h=400&w=400&s=c99719158dab66c72d80ca866ec38b7ebadb5819",
    subtitle: "Trygve Hegnar:",
    title: [],
  },
  {
    src: "https://akamai.vgc.no/drfront/images/2022/08/10/c=107,42,1899,1476;w=319;h=248;702669.jpg?format=auto",
    subtitle: "Sukkerfrie energidrikker:",
    title: [],
  },
  {
    src: "https://akamai.vgc.no/drfront/images/2022/08/09/c=16,0,565,350;w=376;h=233;702550.jpg?format=auto",
    subtitle: "Tabualderen:",
    title: [],
  },
  {
    src: "https://akamai.vgc.no/drfront/images/2022/08/01/c=70,25,1059,685;w=376;h=243;700969.jpg?format=auto",
    subtitle: "Dårlig forhold til mamma?",
    title: [],
  },
  {
    src: "https://akamai.vgc.no/drfront/images/2022/08/15/c=0,80,800,404;w=319;h=161;703726.jpg?format=auto",
    subtitle: "Peder Kjøs:",
    title: [],
  },
  {
    src: "https://akamai.vgc.no/drfront/images/2022/08/12/c=1674,50,3985,3735;w=319;h=299;703087.jpg?format=auto",
    subtitle: "Rå lyd:",
    title: [],
  },
  {
    src: "https://smp.vgc.no/v2/images/5464d0e7-512f-46f7-863b-479b142d0e67?fit=crop&h=400&w=400&s=fac864205e245d1aaca46d8893a4d1174efe7b47",
    subtitle: "13 tegn:",
    title: [],
  },
  {
    src: "https://smp.vgc.no/v2/images/e9e9dc32-eb73-43a9-8763-ba529b8a6aca?fit=crop&h=400&w=400&s=7978f0b461fc2c7a800302460e1000acae710aa2",
    subtitle: "«FIFA 23»:",
    title: [],
  },
  {
    src: "https://smp.vgc.no/v2/images/1f64464d-6761-485c-8b27-286c4ab5f7bd?fit=crop&h=400&w=400&s=a34693de1f822e42931ee5a80bc9dd915ccf09b3",
    subtitle: "Ny studie:",
    title: [],
  },
  {
    src: "https://smp.vgc.no/v2/images/505d9013-148d-4715-81b4-564e6dd0cdfe?fit=crop&h=400&w=400&s=e0a955d20d36a9f42440eff8ebfc6c11102d8e3d",
    subtitle: "Kvadsheim svarer:",
    title: [],
  },
];
