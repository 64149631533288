import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonFooter,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonToolbar,
} from "@ionic/react";
import { ellipsisHorizontal } from "ionicons/icons";
import { SyntheticEvent, useCallback, useMemo, useState } from "react";
import type { Organic } from "../../global";

import LikeIcon from "../../assets/icons/like.png";
import LikeIconFill from "../../assets/icons/like-fill.png";
import CommentIcon from "../../assets/icons/comment.png";
import SendIcon from "../../assets/icons/send.png";

import LikeIconSmall from "../../assets/icons/like-small.svg";
import HeartIconSmall from "../../assets/icons/heart-small.svg";
import CareIconSmall from "../../assets/icons/care-small.svg";

import GlobeIcon from "../../assets/icons/globe.png";

import decorateHashAndMentions from "../../utils/decorateHashAndMentions";

import { FormattedMessage } from "react-intl";

import "./OrganicItem.scss";

interface OrganicItemProps {
  item: Organic;
}

const OrganicItem: React.FC<OrganicItemProps> = ({
  item: {
    id,
    title,
    subtitle,
    avatar,
    createdTime,
    description,
    image,
    comments,
    commentsText,
    likesText,
    likeLabel,
    commentLabel,
    sendLabel,
  },
}: OrganicItemProps) => {
  const [isLiked, setIsLiked] = useState(false);

  const clickHandler = useCallback(
    (event: SyntheticEvent) => event.preventDefault(),
    []
  );

  const likes = useMemo(() => 5 + Math.round(110 * Math.random()), []);

  const onLike = useCallback(() => setIsLiked(!isLiked), [isLiked]);

  return (
    <IonItem lines="none" className="organic-item">
      <IonCard>
        <IonCardHeader>
          <IonToolbar>
            <IonItem>
              <IonAvatar slot="start">
                <IonImg src={avatar} />
              </IonAvatar>
              <IonLabel>
                <h2>{title}</h2>
                <p className="meta">
                  {createdTime} •{" "}
                  <IonImg className="globe-icon" src={GlobeIcon}></IonImg>
                </p>
              </IonLabel>
            </IonItem>
            <IonButton
              slot="end"
              fill="clear"
              onClick={clickHandler}
              color="dark"
            >
              <IonIcon icon={ellipsisHorizontal}></IonIcon>
            </IonButton>
          </IonToolbar>
        </IonCardHeader>
        <IonCardContent>
          {description && (
            <IonLabel
              dangerouslySetInnerHTML={{
                __html: decorateHashAndMentions(description),
              }}
            />
          )}
          <img
            src={image}
            alt={title}
            // loading="lazy"
          />
        </IonCardContent>
        <IonFooter>
          <IonToolbar>
            <IonLabel slot="start">
              <IonIcon src={LikeIconSmall}></IonIcon>
              <IonIcon src={HeartIconSmall}></IonIcon>
              <IonIcon src={CareIconSmall}></IonIcon>
              {isLiked ? `You and ${likes} others` : likes}
            </IonLabel>
            <IonLabel slot="end">{commentsText}</IonLabel>
          </IonToolbar>
          <IonButtons>
            <IonButton size="small" onClick={onLike} color="dark">
              <IonImg
                className={`${isLiked ? "like-animation" : ""}`}
                slot="start"
                src={isLiked ? LikeIconFill : LikeIcon}
              />
              <IonLabel className={`${isLiked ? "liked" : ""}`}>
                {likeLabel || "Like"}
              </IonLabel>
            </IonButton>
            <IonButton size="small" onClick={clickHandler} color="dark">
              <IonImg slot="start" src={CommentIcon} />
              <IonLabel>{commentLabel || "Comment"}</IonLabel>
            </IonButton>
            <IonButton size="small" onClick={clickHandler} color="dark">
              <IonImg slot="start" src={SendIcon}></IonImg>
              <IonLabel>{sendLabel || "Send"}</IonLabel>
            </IonButton>
          </IonButtons>
        </IonFooter>
      </IonCard>
    </IonItem>
  );
};

export default OrganicItem;
