import React from "react";
import { IonButton, IonContent, IonPage } from "@ionic/react";

import useMetric from "../../hooks/useMetric";
import useSearchQuery from "../../hooks/useLocationSearch";

import "./EndSection.css";
import { getRedirectPath } from "../../pages/Post";
import { LinkWithQuery } from "../LinkWithQuery/LinkWithQuery";
import { Link } from "react-router-dom";

const EndSection = ({
  classId = "post-continue",
  onEnd = () => {},
  cta = "Next",
}) => {
  return (
    <div className="vg-tv-end-section">
      <Link to={"/feed" + window.location.search}>
        <IonButton
          className={"vg-tv-end-section-cta " + classId}
          //onClick={onEnd}
        >
          {cta}
        </IonButton>
      </Link>
    </div>
  );
};

export default EndSection;
