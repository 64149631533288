import Header from "./Header";
import Product from "./Product";
import Reviews from "./Reviews";
import Divider from "./Divider";
import Questions from "./Questions";
import Community from "./Community";
import AboutStore from "./AboutStore";
import PurchaseCta from "./PurchaseCta";
import Description from "./Description";
import StoreReminder from "./StoreReminder";
import ProductDetails from "./ProductDetails";
import ShippingDetails from "./ShippingDetails";
import SimilarProducts from "./SimilarProducts";

export default {
  Header,
  Product,
  Reviews,
  Divider,
  Questions,
  Community,
  AboutStore,
  PurchaseCta,
  Description,
  StoreReminder,
  ProductDetails,
  ShippingDetails,
  SimilarProducts,
};
