import { Banner, Organic, SlideshowItem } from "../global.d";
import { META_METRICS_REF } from "../pages/Shop";
import { VG_TV_METRICS_REF } from "../pages/VgTV";
import { stopTimer } from "../utils/timer";
import * as Sentry from "@sentry/react";

export const FB_FEED_METRICS_REF = "metrics";

let LOCALSTORAGE_REF = VG_TV_METRICS_REF;

export type EventType =
  | "session-end"
  | "session-start"
  | "slideshow-item-click"
  | "banner-on-page"
  | "banner-off-page"
  | "banner-item-click"
  | "ad-exposure-triggered"
  | "ad-on-page"
  | "ad-off-page"
  | "ad-click"
  | "ad-comment-click"
  | "ad-like-click"
  | "ad-cta-click"
  | "inline-cta-click"
  | "popup-cta-click"
  | "product-images-click"
  | "add-to-basket-click"
  | "has-adblocker"
  | "session-end";

interface record<T = unknown> {
  type: EventType;
  timestamp: number;
  payload?: T;
}

interface Metric {
  name: string;
  timestamp?: string;
  time_spend_ms?: number;
  sticky_on_page_ms?: number;
  amount_of_clicks?: number;
  amount_of_comments_clicks?: number;
  amount_of_likes_clicks?: number;
  amount_of_modal_clicks?: number;
  amount_of_cta_clicks?: number;
  inline_cta_clicks?: number;
  popup_cta_clicks?: number;
  has_adblocker?: boolean;
}

type aggregation = (records: record[]) => any;

type embeddedMetric = Metric & {
  [key: string]: string | number | boolean;
};

function getRecords() {
  const records: record[] = localStorage.getItem(LOCALSTORAGE_REF)
    ? JSON.parse(localStorage.getItem(LOCALSTORAGE_REF) as string)
    : [];

  return records;
}

function embedSearchParams(metric: Metric) {
  const searchParams = new URLSearchParams(window.location.search);
  searchParams.delete("RedirectURL");
  searchParams.delete("folder");

  return {
    ...metric,
    ...Object.fromEntries(searchParams.entries()),
    ...{ created_at: new Date().toJSON() },
  };
}

function getExitItem(records: record[]): record<Banner | SlideshowItem> {
  return records.find((e) =>
    ["banner-item-click", "slideshow-item-click"].includes(e.type)
  ) as record<SlideshowItem | Banner>;
}

const aggregations: Record<string, aggregation> = {
  timeStart: (records) =>
    records.find((e) => e.type === "session-start")?.timestamp,

  timeSpend: (records: record[]): number =>
    records.find((e) => e.type === "session-end")!.timestamp -
    records.find((e) => e.type === "session-start")!.timestamp,

  exitItemId: (records: record[]): string | undefined => {
    const exitItem = getExitItem(records);

    return exitItem?.payload?.id;
  },

  exitItemClickTime: (records: record[]): number => {
    const exitItem = getExitItem(records);
    const startTime = records.find(
      (e) => e.type === "session-start"
    )!.timestamp;

    return exitItem && exitItem.timestamp - startTime;
  },

  adTriggerId: (records: record[]): string | number | undefined => {
    const item = records.find(
      (e) => e.type === "ad-exposure-triggered"
    ) as record<Organic>;

    return item?.payload?.id;
  },

  adClicked: (records: record[]): number =>
    records.filter((e) => e.type === "ad-click").length,

  adCommentClicked: (records: record[]): number =>
    records.filter((e) => e.type === "ad-comment-click").length,

  adLikeClicked: (records: record[]): number =>
    records.filter((e) => e.type === "ad-like-click").length,

  adModalClicked: (records: record[]): number =>
    records.filter((e) =>
      ["banner-item-click", "slideshow-item-click"].includes(e.type)
    ).length,

  adCTAClicked: (records: record[]): number =>
    records.filter((e) => ["ad-cta-click"].includes(e.type)).length,

  inlineCtaClicked: (records: record[]): number =>
    records.filter((e) => ["inline-cta-click"].includes(e.type)).length,

  popupCtaClicked: (records: record[]): number =>
    records.filter((e) => ["popup-cta-click"].includes(e.type)).length,

  adOnScreen: (records: record[]): number => {
    return records
      .filter((e) =>
        ["ad-on-page", "ad-off-page", "session-end"].includes(e.type)
      )
      .reduce((acc, item, index, items) => {
        if (item.type === "ad-on-page") {
          const onscreen = item.timestamp;
          //   const offscreenItem = items[index + 1] ?? ;
          const offscreen = items[index + 1].timestamp;
          acc += offscreen - onscreen;
        }
        return acc;
      }, 0);
  },

  stickyOnScreen: (records: record[]): number => {
    return records
      .filter((e) =>
        ["banner-on-page", "banner-off-page", "session-end"].includes(e.type)
      )
      .reduce((acc, item, index, items) => {
        if (item.type === "banner-on-page") {
          const onscreen = item.timestamp;
          //   const offscreenItem = items[index + 1] ?? ;
          const offscreen = items[index + 1].timestamp;
          acc += offscreen - onscreen;
        }
        return acc;
      }, 0);
  },

  hasAdblocker: (records: record[]): boolean => {
    return records.find((e) => e.type === "has-adblocker") !== undefined;
  },
};

function getStudyId() {
  switch (LOCALSTORAGE_REF) {
    case FB_FEED_METRICS_REF:
      return "fb";
    case META_METRICS_REF:
      return "shop";
    case VG_TV_METRICS_REF:
      return "vg-tv";
    default:
      return "unknown";
  }
}

async function sendMetric(metrics: any) {
  try {
    const id = getStudyId();

    const all = metrics.map((metric: Metric) =>
      fetch(`https://ex.neuronsinc.com/api/put-data?path=${id}`, {
        method: "POST",
        body: JSON.stringify(metric),
      })
    );

    const res = await Promise.all(all);
    // const data = await res.json();
    console.info("Send metric", metrics, res);

    // Stop timer & clear local storage
    stopTimer();
    localStorage.clear();

    return true;
  } catch (err) {
    Sentry.captureException({
      metrics: metrics.map((m: Metric) => JSON.stringify(m)),
      code: "ERR.101",
      err,
    });
    // alert(
    //   "ERR.101: Something went wrong. Please contact the Neurons team. \n" +
    //     JSON.stringify(err)
    // );

    stopTimer();
    localStorage.clear();
    console.error("failed to sendMetric()", err);
  }
}

/**
 * Track event
 *
 * @param type
 * @param payload
 */
function track(type: EventType, payload?: unknown) {
  const record: record = {
    type,
    timestamp: new Date().getTime(),
    payload: payload ?? {},
  };

  const records = getRecords();
  records.push(record);
  localStorage.setItem(LOCALSTORAGE_REF, JSON.stringify(records));
  console.info("tracked", record);
}

/**
 * Send the metrics
 */
function sendMetrics(isShop: boolean = false) {
  var records = getRecords();

  try {
    console.log("sendMetrics", { records });

    const adIds = Array.from(
      new Set(records.map((e: any) => e.payload.id).filter((e) => e))
    );

    // Check if session-start exists, otherwise append the oldest event as session-start
    const hasStart = records.some((r) => r.type === "session-start");
    if (!hasStart) {
      const firstRecord = records.reduce((prev, curr) => {
        return prev?.timestamp < curr?.timestamp ? prev : curr;
      });

      if (firstRecord) {
        records = [{ ...firstRecord, type: "session-start" }, ...records];
      }

      console.log({ newRecords: records, firstRecord });
    }

    const metrics: Metric[] = adIds.map((adId) => {
      const adRecords = records.filter(
        (e: any) => e.payload.id === adId || ["session-end"].includes(e.type)
      );

      if (isShop) {
        const extraParam =
          adRecords?.length > 0
            ? {
                // @ts-ignore
                price: adRecords[0].payload?.price ?? "",
                // @ts-ignore
                discount: adRecords[0].payload?.discount ?? "",
                // @ts-ignore
                product_name: adRecords[0].payload?.name ?? "",
              }
            : {};
        const metric: Metric = {
          name: adId,
          time_spend_ms: aggregations.adOnScreen(adRecords),
          amount_of_clicks: aggregations.adClicked(adRecords),
          has_adblocker: aggregations.hasAdblocker(adRecords),
          ...extraParam,
        };

        return metric;
      } else {
        const metric: Metric = {
          name: adId,
          time_spend_ms: aggregations.adOnScreen(adRecords),
          sticky_on_page_ms: aggregations.stickyOnScreen(adRecords),
          amount_of_clicks: aggregations.adClicked(adRecords),
          amount_of_comments_clicks: aggregations.adCommentClicked(adRecords),
          amount_of_likes_clicks: aggregations.adLikeClicked(adRecords),
          amount_of_modal_clicks: aggregations.adModalClicked(adRecords),
          amount_of_cta_clicks: aggregations.adCTAClicked(adRecords),
          has_adblocker: aggregations.hasAdblocker(adRecords),
        };

        return metric;
      }
    });

    console.log({ metrics, records });

    if (isShop) {
      metrics.push({
        name: "Total",
        time_spend_ms: aggregations.timeSpend(records),
        amount_of_clicks: 0,
        has_adblocker: aggregations.hasAdblocker(records),
      });
    } else {
      metrics.push({
        name: "Total",
        time_spend_ms: aggregations.timeSpend(records),
        sticky_on_page_ms: 0,
        amount_of_clicks: 0,
        amount_of_comments_clicks: 0,
        amount_of_likes_clicks: 0,
        amount_of_modal_clicks: 0,
        amount_of_cta_clicks: 0,
        has_adblocker: aggregations.hasAdblocker(records),
      });
    }

    metrics.push({
      name: "Start Time",
      timestamp: aggregations.timeStart(records),
    });

    const embeddedMetrics = metrics.map(embedSearchParams);
    console.table(embeddedMetrics);
    return sendMetric(embeddedMetrics);
  } catch (err) {
    Sentry.captureException({
      records: records.map((r) => JSON.stringify(r)),
      code: "ERR.103",
      err,
    });
    alert("ERR.103: Something went wrong. Please contact the Neurons team.");

    return new Promise((resolve, reject) => {
      resolve(false);
    });
  }
}

export default function useMetric(storageId?: string) {
  if (storageId) {
    LOCALSTORAGE_REF = storageId;
  }

  const records = getRecords();
  console.log({ LOCALSTORAGE_REF, records });

  return {
    track,
    sendMetrics,
    records,
  };
}
