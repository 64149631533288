import React from "react";
import {
  IonItem,
  IonAvatar,
  IonImg,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonContent,
  IonChip,
  IonButton,
} from "@ionic/react";
import StarOutlineIcon from "../../assets/icons/fb/star-outline.png";
import StarFillIcon from "../../assets/icons/fb/star-fill.png";
import HeartOutlineIcon from "../../assets/icons/fb/heart-outline.png";
import HeartFillIcon from "../../assets/icons/fb/heart-fill.png";
import LikeIcon from "../../assets/icons/fb/like.png";
import DotsMenuIcon from "../../assets/icons/fb/3-dots-comment.png";

import "./Reviews.css";
import Section from "./Section";

export default function Reviews({
  title = "",
  stars = 0,
  count = 0,
  comments = [],
}) {
  return (
    <Section title="Reviews">
      {/* <Stars stars={stars} count={count} className="mb-16" /> */}

      <IonGrid>
        {comments.map((comment) => (
          <Comment {...comment} />
        ))}
      </IonGrid>
    </Section>
  );
}

function arrayFromN(n) {
  return Array.from(Array(n).keys());
}

export function Comment({
  avatar = "",
  username = "",
  isVerified = false,
  stars = 0,
  verifiedPurchaseLabel = "",
  comment = "",
  createdAt = "",
  isLiked = false,
  likes = "",
  images = [],
  doesHaveStars = true,
  isAnswer = false,
  moreLabel,
}) {
  return (
    <IonRow
      style={{
        marginLeft: isAnswer ? "44px" : "0",
        marginBottom: doesHaveStars ? "32px" : "16px",
      }}
    >
      <IonImg
        src={avatar}
        className={isAnswer ? "fb-comment-avatar-sm" : "fb-comment-avatar"}
      />

      <IonCol className="m-0 p-0">
        <IonRow className="m-0 p-0 ion-align-items-start">
          <IonCol className="m-0 p-0">
            <p className="fb-comment-username mb-4">{username}</p>
            {doesHaveStars && (
              <Stars stars={stars} size="sm" noText className="mb-4" />
            )}

            {verifiedPurchaseLabel && (
              <p className="fb-comment-verified mb-16">
                {verifiedPurchaseLabel}
              </p>
            )}
          </IonCol>

          {doesHaveStars && (
            <IonImg className="fb-comment-dots-menu-icon" src={DotsMenuIcon} />
          )}
        </IonRow>

        <IonRow
          className="ion-justify-content-between"
          style={{ marginBottom: "16px !important" }}
        >
          <p className="fb-comment-content">
            {comment}

            <span className="fb-comment-content-more">{moreLabel}</span>
          </p>

          {!doesHaveStars && (
            <IonImg
              className="fb-comment-heart-icon"
              src={isLiked ? HeartFillIcon : HeartOutlineIcon}
            />
          )}
        </IonRow>

        {images?.length > 0 && (
          <IonRow className="mb-16">
            {images.map((image) => (
              <IonImg className="fb-comment-img" src={image.src} />
            ))}
          </IonRow>
        )}
        {doesHaveStars ? (
          <IonRow className="ion-justify-content-between">
            <IonRow>
              <p className="fb-comment-created-at">{createdAt}</p>
              {isLiked ? (
                <p className="fb-comment-liked">Liked</p>
              ) : (
                <p className="fb-comment-created-at">Like</p>
              )}
            </IonRow>

            <IonRow>
              <p className="fb-comment-likes">{likes}</p>
              <IonImg className="fb-comment-like-icon" src={LikeIcon} />
            </IonRow>
          </IonRow>
        ) : (
          <IonRow className="">
            <p className="fb-comment-created-at">{createdAt}</p>
            <p className="fb-comment-likes">{likes}</p>
          </IonRow>
        )}
      </IonCol>
    </IonRow>
  );
}

export function Stars({
  stars = 0,
  count = 0,
  size = "md",
  noText = false,
  ...rest
}) {
  const MAX_STARS = React.useMemo(() => 5, []);

  const starsNorm = React.useMemo(
    () => Math.min(MAX_STARS, Math.floor(stars)),
    [stars]
  );

  return (
    <IonRow {...rest} className="ion-align-items-center">
      {arrayFromN(starsNorm).map(() => (
        <IonImg
          className={`fb-star-size-${size}`}
          src={StarFillIcon}
          style={{ marginRight: "2px" }}
        />
      ))}
      {arrayFromN(MAX_STARS - starsNorm).map(() => (
        <IonImg
          className={`fb-star-size-${size}`}
          src={StarOutlineIcon}
          style={{ marginRight: "2px" }}
        />
      ))}

      {/* {!noText && <p className="reviews-rating">{stars}</p>} */}

      {!noText && <p className="fb-reviews-count">{count}</p>}
    </IonRow>
  );
}
