import { IonButton, IonContent, IonPage } from "@ionic/react";
import React, { useEffect } from "react";
import useSearchQuery from "../hooks/useLocationSearch";
import useMetric from "../hooks/useMetric";
import * as Sentry from "@sentry/react";

import "./post.css";

export function getRedirectPath(searchParams: URLSearchParams): string {
  const redirectUrl = searchParams.get("RedirectURL");

  if (!redirectUrl) return "";

  searchParams.delete("RedirectURL");
  searchParams.delete("feed");
  searchParams.delete("folder");
  searchParams.delete("metricsRef");

  const parameters = searchParams.toString();
  const hasQuery = redirectUrl?.includes("?");
  const delimiter = hasQuery ? "&" : "?";

  return `${decodeURIComponent(redirectUrl!)}${delimiter}${parameters}`;
}

const Post: React.FC = () => {
  const [isReady, setIsReady] = React.useState(false);
  const searchQuery = useSearchQuery();
  const metricsRef = searchQuery.get("metricsRef") ?? undefined;

  const { track, sendMetrics, records } = useMetric(metricsRef);

  console.log({ records });

  useEffect(() => {
    if (records.length === 0) {
      return;
    }

    track("session-end");
    // TODO: (noahlaux) remove in timer instead
    localStorage.removeItem("timerRef");
    sendMetrics()
      .then((isSuccess = false) => {
        if (isSuccess) {
          setIsReady(true);
        }
      })
      .catch((err) => {
        Sentry.captureException({ code: "ERR.104", err });

        setIsReady(false);
        alert(
          "ERR.104: Something went wrong. Please contact the Neurons team."
        );
      });
  }, []);

  const onContinue = React.useCallback(() => {
    const redirectUrl = getRedirectPath(searchQuery);
    if (redirectUrl !== "") {
      window.location.href = redirectUrl;
    }
  }, []);

  return (
    <IonPage>
      <IonContent fullscreen>
        <div className="container">
          <h1>Thank you</h1>
          <IonButton
            className="post-continue end-section-cta"
            onClick={onContinue}
            disabled={!isReady}
          >
            {isReady ? "Continue" : "Processing..."}
          </IonButton>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Post;
