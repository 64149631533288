import React from "react";
import {
  IonItem,
  IonAvatar,
  IonImg,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonContent,
  IonChip,
  IonButton,
} from "@ionic/react";
import StarOutlineIcon from "../../assets/icons/insta/star-outline.png";
import StarFillIcon from "../../assets/icons/insta/star-fill.png";
import HeartOutlineIcon from "../../assets/icons/insta/heart-outline.png";
import HeartFillIcon from "../../assets/icons/insta/heart-fill.png";

import "./Reviews.css";
import Section from "./Section";
import { Comment } from "./Reviews";

export default function Questions({
  title = "",
  cta = "",
  stars = 0,
  count = 0,
  comments = [],
}) {
  return (
    <Section title={title} isOpenDefault={false}>
      <IonCol>
        {comments.map((comment) => (
          <IonCol>
            <Comment {...comment} doesHaveStars={false} />

            {comment.answer && (
              <Comment {...comment.answer} isAnswer doesHaveStars={false} />
            )}
          </IonCol>
        ))}

        <IonButton className="insta-button secondary-cta" color="transparent">
          {cta}
        </IonButton>
      </IonCol>
    </Section>
  );
}
