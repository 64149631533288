import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonImg,
  IonItemDivider,
  IonList,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";

import { RouteComponentProps, useHistory } from "react-router-dom";

import useData from "../hooks/useData";

import VisibilitySensor from "react-visibility-sensor";

import AdItem from "../components/AdItem/AdItem";
import SlideShow from "../components/SlideShowItem/SlideShowItem";
import SlideShowInstagram from "../components/SlideShowItemInstagram/SlideShowItemInstagram";
import Divider from "../components/dividerItem/DividerItem";

import InstagramLogo from "../assets/icons/instagram.png";
import InstagramActions from "../assets/icons/instagram-icons.png";

import useMetric, { EventType } from "../hooks/useMetric";

import { Ad, BrandBox } from "../global";

import BrandId from "../components/BrandId/BrandId";
import BannerItem from "../components/BannerItem/BannerItem";
import ModelItem from "../components/ModalItem/ModalItem";

import "./AdDetail.scss";
import useLocationSearch from "../hooks/useLocationSearch";
import timer from "../utils/timer";
const EXPERIENCE_TIMEOUT_PERIOD: number = 60 * 1000 * 3;
interface FeedProps extends RouteComponentProps<{}> {}

function hasAdblocker() {
  const container = document.querySelector(".creative-item") as HTMLDivElement;
  return container && container.clientWidth === 0;
}

const AdDetail: React.FC<FeedProps> = ({ match }) => {
  const history = useHistory();
  const search = useLocationSearch();
  const { track } = useMetric();

  const id = search.get("feed") || "1";
  const dataUrl = search.get("dataUrl");
  const { data } = useData({ id, dataUrl });

  const currentAd = useMemo(() => {
    let ad = data?.feed?.items?.find(
      // @ts-ignore
      (ad) => ad.id === match.params.itemId
    );

    if (!ad) {
      ad = data?.feed?.["ad-pool"]?.find(
        // @ts-ignore
        (ad) => ad.id === match.params.itemId
      );
    }

    return ad as Ad;
  }, [data, match.params]);

  const containmentContainer = React.useRef<HTMLIonContentElement>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const onBannerExitHandler = useCallback((item) => {
    setIsModalOpen(false);
    track("banner-item-click", item);
    setTimeout(() => history.push(`/post?${search.toString()}`));
  }, []);

  const onSlideshowExitHandler = useCallback((slideshow) => {
    setIsModalOpen(true);
    track("ad-cta-click", slideshow);
  }, []);

  const visibilityHandler = useCallback(
    (item: Ad | BrandBox, onTrack: EventType, offTrack: EventType) =>
      (isVisible: boolean) => {
        if (isVisible) {
          track(onTrack, item);
        } else {
          if (containmentContainer.current) {
            track(offTrack, item);
          }
        }
      },
    [track]
  );

  useEffect(() => {
    setTimeout(() => {
      if (hasAdblocker()) {
        track("has-adblocker");
      }
    }, 500);
  }, [data, track]);

  useEffect(() => {
    timer({
      duration: EXPERIENCE_TIMEOUT_PERIOD,
      onStart: () => track("session-start"),
      onEnd: () => {
        track("session-end");
        history.push(`/post?${search.toString()}`);
      },
    });
  }, []);

  return (
    currentAd && (
      <>
        <IonPage className="creative-detail">
          {data?.type === "instagram" && (
            <IonHeader>
              <IonToolbar className="instagram">
                <IonImg className="logo" slot="start" src={InstagramLogo} />
                <IonImg className="actions" slot="end" src={InstagramActions} />
              </IonToolbar>
            </IonHeader>
          )}
          <IonHeader>
            <IonToolbar className="nav">
              <IonButtons slot="start">
                <IonBackButton color="dark" text="" />
              </IonButtons>
              <IonTitle>{currentAd.detailTitle}</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent ref={containmentContainer} fullscreen>
            <IonList>
              {currentAd?.accompanyingAds?.map((item, index) => (
                <div key={`${item.type}${index}`}>
                  {item.type === "ad" && (
                    <VisibilitySensor
                      partialVisibility
                      offset={{ top: 180, bottom: 180 }}
                      containment={containmentContainer.current}
                      onChange={visibilityHandler(
                        item,
                        "ad-on-page",
                        "ad-off-page"
                      )}
                    >
                      <AdItem
                        item={item}
                        performCommentAction={false}
                        showComments
                        onCommentClick={() => track("ad-comment-click", item)}
                        onClick={() => track("ad-click", item)}
                      />
                    </VisibilitySensor>
                  )}
                  {item.type === "slideshow" && (
                    <>
                      {data?.type === "facebook" && (
                        <SlideShow
                          item={item}
                          onCTAClick={onSlideshowExitHandler}
                        />
                      )}
                      {data?.type === "instagram" && (
                        <SlideShowInstagram
                          item={item}
                          onCTAClick={onSlideshowExitHandler}
                        />
                      )}
                    </>
                  )}
                  {item.type === "divider" && <Divider title={item.title} />}
                  {item.type === "brandBox" && (
                    <VisibilitySensor
                      partialVisibility
                      offset={{ top: 180, bottom: 180 }}
                      containment={containmentContainer.current}
                      onChange={visibilityHandler(
                        item,
                        "ad-on-page",
                        "ad-off-page"
                      )}
                    >
                      <BrandId
                        item={item}
                        onClick={() => {
                          setIsModalOpen(true);
                          track("ad-cta-click", item);
                        }}
                        onContentClick={() => track("ad-click", item)}
                      />
                    </VisibilitySensor>
                  )}
                </div>
              ))}
              {currentAd.banner && (
                <IonItemDivider
                  sticky
                  style={{
                    bottom: 0,
                    "--padding-start": 0,
                    "--padding-end": 0,
                    "--inner-padding-end": 0,
                  }}
                >
                  <VisibilitySensor
                    containment={containmentContainer.current}
                    delayedCall={true}
                    onChange={visibilityHandler(
                      currentAd,
                      "banner-on-page",
                      "banner-off-page"
                    )}
                  >
                    <BannerItem
                      banner={currentAd.banner}
                      onClick={() => {
                        if (currentAd?.accompanyingAds) {
                          track("ad-cta-click", currentAd?.accompanyingAds[0]);
                        }
                        setIsModalOpen(true);
                      }}
                    />
                  </VisibilitySensor>
                </IonItemDivider>
              )}
            </IonList>
          </IonContent>
        </IonPage>
        {currentAd.modal && (
          <ModelItem
            isOpen={isModalOpen}
            url={currentAd.modal.url}
            image={currentAd.modal.image}
            onContentClick={() => onBannerExitHandler(currentAd)}
            onDismiss={() => setIsModalOpen(false)}
          />
        )}
      </>
    )
  );
};

export default AdDetail;
