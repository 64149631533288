import React from "react";
import {
  IonItem,
  IonAvatar,
  IonImg,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonContent,
  IonChip,
  IonButton,
} from "@ionic/react";

import Section from "./Section";

export default function ProductDetails({
  title = "",
  description = {},
  productDetails = {},
  shippingAndReturns = {},
}) {
  return (
    <Section title={title} isOpenDefault={false}>
      <IonRow className="ion-align-items-center">
        <p
          className="section-content-p"
          dangerouslySetInnerHTML={{ __html: description.content }}
        ></p>
      </IonRow>
      <IonRow className="ion-align-items-center">
        <p className="section-content-p">{description?.moreInfo}</p>
      </IonRow>
      <IonRow className="ion-align-items-center">
        <IonButton
          className="insta-button secondary-cta"
          color="transparent"
          style={{ marginBottom: "1rem" }}
        >
          {description?.cta}
        </IonButton>
      </IonRow>

      {productDetails?.options.map((option) =>
        option.isLink ? (
          <IonRow className="ion-align-items-center">
            <p className="section-content-p m-0">{option.label}&nbsp;·&nbsp;</p>

            <p
              className="section-content-p-sm-secondary m-0"
              style={{
                color: "#0095F6",
                fontWeight: "600",
                fontSize: "14px",
                textTransform: "capitalize",
              }}
              dangerouslySetInnerHTML={{ __html: option.value }}
            ></p>
          </IonRow>
        ) : (
          <IonCol className="ion-align-items-center">
            <p className="section-content-p m-0">{option.label}</p>
            <p
              className="section-content-p-secondary m-0"
              dangerouslySetInnerHTML={{ __html: option.value }}
            ></p>
          </IonCol>
        )
      )}

      {shippingAndReturns.options.map((option) =>
        option.isLink ? (
          <IonRow>
            <p className="section-content-p m-0">{option.label}&nbsp;·&nbsp;</p>

            <p className="section-content-p-sm-secondary m-0">{option.value}</p>
          </IonRow>
        ) : (
          <IonCol>
            <p className="section-content-p m-0">{option.label}</p>
            <IonRow>
              <p className="section-content-p-secondary m-0">{option.value}</p>
              {option.link && (
                <a className="section-link" style={{ marginLeft: "4px" }}>
                  {option.link}
                </a>
              )}
            </IonRow>
          </IonCol>
        )
      )}
    </Section>
  );
}
