import React from "react";
import { IonButton, IonContent, IonImg, IonPage } from "@ionic/react";
import Header from "../components/VgTV/Header";
import VideoSection from "../components/VgTV/VideoSection";

import Footer from "../assets/icons/vg/vg-footer.png";
import timer from "../utils/timer";

import "./VgTV.css";
import VideoPlayer from "../components/VgTV/VideoPlayer";
import VideoInfo from "../components/VgTV/VideoInfo";
import EndSection from "../components/VgTV/EndSection";
import { useHistory } from "react-router";
import useMetric from "../hooks/useMetric";
import useSearchQuery from "../hooks/useLocationSearch";
import { getConfigByFeed } from "../components/VgTV/config";
import { VG_TV_ARTICLE_VIDEO_REF, VG_TV_ARTICLE_TITLE_REF } from "./VgFeed";

export const VG_TV_TIMEOUT_PERIOD: number = 3 * 60 * 1000;
export const VG_TV_METRICS_REF: string = "VG_TV_METRICS";
export const VG_TV_TIMER_REF: string = "VG_TV_TIMER";
export const VG_TV_VIDEO_SEEN_REF: string = "VG_TV_VIDEO_SEEN";

export function clearVideoSeen() {
  localStorage.removeItem(VG_TV_VIDEO_SEEN_REF);
}

function setVideoSeen() {
  localStorage.setItem(VG_TV_VIDEO_SEEN_REF, "true");
}
export function isVideoSeen() {
  const isSeen = localStorage.getItem(VG_TV_VIDEO_SEEN_REF) as string;
  return isSeen === "true";
}

const VgTV: React.FC = () => {
  const history = useHistory();
  const search = useSearchQuery();
  const { track } = useMetric(VG_TV_METRICS_REF);
  const [articleVideo, setArticleVideo] = React.useState();

  const [title, setTitle] = React.useState("Lene i villsvin-drama i Spania");

  const { ads, mainVideo } = getConfigByFeed(search.get("feed"));
  const preroll = React.useMemo(() => ads.preroll, [ads]);

  const prerollId = React.useMemo(() => preroll?.id, [preroll?.id]);

  const containmentContainer = React.useRef<HTMLIonContentElement>(null);

  React.useEffect(() => {
    setVideoSeen();

    timer({
      duration: VG_TV_TIMEOUT_PERIOD,
      onStart: () => track("session-start"),
      onEnd: () => {
        track("session-end");
        window.location.href = `/fb/post?metricsRef=${VG_TV_METRICS_REF}&${search.toString()}`;
      },
      storageId: VG_TV_TIMER_REF,
    });
  }, []);

  React.useEffect(() => {
    const video = localStorage.getItem(VG_TV_ARTICLE_VIDEO_REF)
      ? localStorage.getItem(VG_TV_ARTICLE_VIDEO_REF)
      : mainVideo?.src;

    const title = localStorage.getItem(VG_TV_ARTICLE_TITLE_REF)
      ? localStorage.getItem(VG_TV_ARTICLE_TITLE_REF)
      : null;

    if (title) setTitle(title);
    setArticleVideo(video);
  }, [mainVideo]);

  console.log({ articleVideo });

  const onVisibilityChange = React.useCallback(
    (isVisible: boolean) => {
      if (isVisible) {
        track("ad-on-page", { prerollId, id: "preroll-video" });
      } else {
        track("ad-off-page", { prerollId, id: "preroll-video" });
      }
    },
    [track, prerollId]
  );

  const onPrerollClick = React.useCallback(
    () => track("ad-click", { prerollId, id: "preroll-video" }),
    [track, prerollId]
  );

  const onPrerollEnd = React.useCallback(() => {
    track("ad-off-page", { prerollId, id: "preroll-video" });
  }, [prerollId]);

  // const onBack = React.useCallback(() => {
  //   window.location.href = "/vg-tv/feed" + window.location.search;
  // }, []);

  return (
    <IonPage>
      <IonContent fullscreen ref={containmentContainer}>
        <div id="scrollable-container" className="vg-tv-container">
          <Header />

          <VideoPlayer
            prerollId={preroll?.id}
            preVideo={preroll?.src}
            mainVideo={articleVideo}
            onVisibilityChange={onVisibilityChange}
            onPrerollClick={onPrerollClick}
            containmentContainer={containmentContainer.current}
            onPrerollEnd={onPrerollEnd}
          />

          <VideoInfo title={title} />

          <VideoSection title="Alle videoer" videos={videos.slice(0, 4)} />
          <VideoSection title="VG Forklarer" videos={videos.slice(4, 8)} />
          <VideoSection title="På date med " videos={videos.slice(8, 12)} />

          <IonImg src={Footer} />

          <EndSection
            // onEnd={onBack}
            cta="Tilbake til feed"
            classId="vg-tv-end-section-cta-lg mid-continue"
          />
        </div>
      </IonContent>

      {/* @ts-ignore */}
      <style jsx global>{`
        html,
        body {
          height: auto !important;
        }
        .ion-page {
          position: relative !important;
          width: 100% !important;
          height: auto !important;
          min-height: 100vh;
        }
        #scrollable-container {
          height: auto;
        }
      `}</style>
    </IonPage>
  );
};

export default VgTV;

// [...document.querySelectorAll("[class^='AssetVertical_container']")].map(
//   (ad) => {
//     return {
//       src: ad.querySelector(".Image_image__1Ms87")?.srcset?.split(" ")[0],
//       createdAt: ad.querySelector(".Label_label__1-xDZ")?.textContent,
//       title: ad.querySelector(".Title_assetTitle__247HU")?.textContent,
//     };
//   }
// );

const videos = [
  {
    src: "https://imbo.vgtv.no/users/vgtv/images/NyewuWkWGLGG.jpg?t[]=386x218q50",
    createdAt: "24 minutter siden",
    title: "SISTE NYTT: Sønnen Eric med hyllest",
  },
  {
    src: "https://imbo.vgtv.no/users/vgtv/images/76hdQdNf7PMb.jpg?t[]=386x218q50",
    createdAt: "3 timer siden",
    title: "Svensk riksmekler: - Dette er jo rekord",
  },
  {
    src: "https://imbo.vgtv.no/users/vgtv/images/fOuTwIDDavsW.jpg?t[]=386x218q50",
    createdAt: "3 timer siden",
    title:
      "SAS-forhandlingene trekker ut: - Villig til å bruke den tiden det tar",
  },
  {
    src: "https://imbo.vgtv.no/users/vgtv/images/WQxMLKN9P0Lt.jpg?t[]=386x218q50",
    createdAt: "4 timer siden",
    title: "Her går det galt på direkten: «Første gang i livet»",
  },
  {
    src: "https://imbo.vgtv.no/users/vgtv/images/p7MnycDFZ974.jpg?t[]=386x218q50",
    createdAt: "6 timer siden",
    title: "Flere fastklemt: 14 år gammel jente døde",
  },
  {
    src: "https://imbo.vgtv.no/users/vgtv/images/9RYlqeG3YLwq.jpg?t[]=386x218q50",
    createdAt: "8 timer siden",
    title: "SE: Siste nytt om tivoliulykken",
  },
  {
    src: "https://imbo.vgtv.no/users/vgtv/images/YDkAjcFoLgeO.jpg?t[]=386x218q50",
    createdAt: "9 timer siden",
    title: "Pressekonferanse  om russiske krigsforbrytelser fra Haag",
  },
  {
    src: "https://imbo.vgtv.no/users/vgtv/images/ZNLWCRGkkviQ.jpg?t[]=386x218q50",
    createdAt: "9 timer siden",
    title: "Forhandlingsleder: - Det er bevegelse i forhandlingen",
  },
  {
    src: "https://imbo.vgtv.no/users/vgtv/images/Lao5ybld2hpV.jpg?t[]=386x218q50",
    createdAt: "10 timer siden",
    title: "Zelenskyj taler i Haag",
  },
  {
    src: "https://imbo.vgtv.no/users/vgtv/images/d7bEf_XugqRq.jpg?t[]=386x218q50",
    createdAt: "4 dager siden",
    title: "VG Forklarer: Martine-saken",
  },
  {
    src: "https://imbo.vgtv.no/users/vgtv/images/vrerjCCxtw43.jpg?t[]=386x218q50",
    createdAt: "Forbruker · 8 dager siden",
    title: "VG Forklarer: Slik kan du redde ferien",
  },
  {
    src: "https://imbo.vgtv.no/users/vgtv/images/CH1Vc3AU1Rf7.jpg?t[]=386x218q50",
    createdAt: "13 dager siden",
    title: "Slik unngår du at dine enheter misbrukes",
  },
  {
    src: "https://imbo.vgtv.no/users/vgtv/images/mkcEbnfgiWDl.jpg?t[]=386x218q50",
    createdAt: "23 dager siden",
    title: "VG Forklarer: Flyplass-rådene du bør følge",
  },
  {
    src: "https://imbo.vgtv.no/users/vgtv/images/dyQMJc7cAYHz.jpg?t[]=386x218q50",
    createdAt: "en måned siden",
    title: "Nye regler for elsparkesykkel: Dette kan gi bot på 13.000 kroner",
  },
  {
    src: "https://imbo.vgtv.no/users/vgtv/images/U7-vOPj7X2OQ.jpg?t[]=386x218q50",
    createdAt: "en måned siden",
    title: "VG Forklarer: Dette betyr fargene",
  },
  {
    src: "https://imbo.vgtv.no/users/vgtv/images/pjssLE1KOujQ.jpg?t[]=386x218q50",
    createdAt: "2 måneder siden",
    title: "VG Forklarer: Våpen i USA",
  },
  {
    src: "https://imbo.vgtv.no/users/vgtv/images/bCGlUD8A0W3Z.jpg?t[]=386x218q50",
    createdAt: "2 måneder siden",
    title: "VG Forklarer: Husk dette før ferien",
  },
  {
    src: "https://imbo.vgtv.no/users/vgtv/images/w0FEf729Bpym.jpg?t[]=386x218q50",
    createdAt: "2 måneder siden",
    title: "VG Forklarer: Symptomene på apekopper",
  },
  {
    src: "https://imbo.vgtv.no/users/vgtv/images/CLjEtueXyyED.jpg?t[]=386x218q50",
    createdAt: "2 måneder siden",
    title: "Marerittet i Kongsberg: Slik var Bråthens drapsferd",
  },
  {
    src: "https://imbo.vgtv.no/users/vgtv/images/pOJ5sghsfkee.jpg?t[]=386x218q50",
    createdAt: "8 dager siden",
    title: "Slik fremstilles norsk by i ny storfilm",
  },
  {
    src: "https://imbo.vgtv.no/users/vgtv/images/dXFGYzSf5GHv.jpg?t[]=386x218q50",
    createdAt: "15 dager siden",
    title: "- Ikke ditt vanlige Disney-eventyr",
  },
  {
    src: "https://imbo.vgtv.no/users/vgtv/images/dIYtF6fACat3.jpg?t[]=386x218q50",
    createdAt: "17 dager siden",
    title: "Netflix-film: - Alt er spilt inn i Norge",
  },
  {
    src: "https://imbo.vgtv.no/users/vgtv/images/IICPryCqrdYj.jpg?t[]=386x218q50",
    createdAt: "22 dager siden",
    title: "Rykte spredte seg: – Skapte frykt",
  },
  {
    src: "https://imbo.vgtv.no/users/vgtv/images/wldGHUyQcfYZ.jpg?t[]=386x218q50",
    createdAt: "25 dager siden",
    title: "- Aldri sett lignende",
  },
  {
    src: "https://imbo.vgtv.no/users/vgtv/images/V7EJjrX4Bu2I.jpg?t[]=386x218q50",
    createdAt: "en måned siden",
    title: "- Ser ut som en som har sex",
  },
  {
    src: "https://imbo.vgtv.no/users/vgtv/images/9If1Cw4pskAU.jpg?t[]=386x218q50",
    createdAt: "en måned siden",
    title: "Johnny Depp hyrte Netflix-profil etter dette",
  },
  {
    src: "https://imbo.vgtv.no/users/vgtv/images/rsmkNkhDVZ2d.jpg?t[]=386x218q50",
    createdAt: "en måned siden",
    title: "Norsk trollfilm vises i 190 land",
  },
  {
    src: "https://imbo.vgtv.no/users/vgtv/images/7pePqDM8VJ31.jpg?t[]=386x218q50",
    createdAt: "en måned siden",
    title: "Stjernen elsker Norge: - Jeg er gal etter det",
  },
  {
    src: "https://imbo.vgtv.no/users/vgtv/images/VhUNewWU1hIK.jpg?t[]=386x218q50",
    createdAt: "en måned siden",
    title: "«Druknet» skuespillerne før «Top Gun»-innspilling",
  },
  {
    src: "https://imbo.vgtv.no/users/vgtv/images/3_zAaAqbXtCG.jpg?t[]=386x218q50",
    createdAt: "3 måneder siden",
    title: "Solberg svarer på coronakritikk",
  },
  {
    src: "https://imbo.vgtv.no/users/vgtv/images/xIOdFO3KJkOw.jpg?t[]=386x218q50",
    createdAt: "3 måneder siden",
    title: "SE: Koronakommisjonen legger frem rapport",
  },
  {
    src: "https://imbo.vgtv.no/users/vgtv/images/107f23b1a9c872d851bf138d7c083e43.jpg?t[]=386x218q50",
    createdAt: "3 måneder siden",
    title: "Millioner innestengt - her kommer dronen",
  },
  {
    src: "https://imbo.vgtv.no/users/vgtv/images/8a9b0db65fb93763432bd15de2d2146f.jpg?t[]=386x218q50",
    createdAt: "3 måneder siden",
    title: "VG Forklarer: «Long covid»",
  },
  {
    src: "https://imbo.vgtv.no/users/vgtv/images/be7b0173c8e6cb5ad75544a7bfd367cc.jpg?t[]=386x218q50",
    createdAt: "4 måneder siden",
    title: "FHI om «deltakron»-smitten",
  },
  {
    src: "https://imbo.vgtv.no/users/vgtv/images/ce4754c7fd005ada401fd9b9ac229f25.jpg?t[]=386x218q50",
    createdAt: "4 måneder siden",
    title: "DIRTEKTE: FHIs ukesrapport om coronaviruset",
  },
  {
    src: "https://imbo.vgtv.no/users/vgtv/images/b74fcc71e685cf664e853062681f5e11.jpg?t[]=386x218q50",
    createdAt: "5 måneder siden",
    title: "Støre: - Hver og en må tenke selv",
  },
  {
    src: "https://imbo.vgtv.no/users/vgtv/images/e33165aa99c25c14ae4463b295ba0224.png?t[]=386x218q50",
    createdAt: "5 måneder siden",
    title: "Coronasmittet trenger ikke lenger være i isolasjon",
  },
  {
    src: "https://imbo.vgtv.no/users/vgtv/images/3f1f6d4651b0d5217e8bc8d138b600fc.png?t[]=386x218q50",
    createdAt: "5 måneder siden",
    title: "Her fjerner studioverten pleksiglasset",
  },
  {
    src: "https://imbo.vgtv.no/users/vgtv/images/09b16e602e3df6968d9b434c5e1b1420.jpg?t[]=386x218q50",
    createdAt: "5 måneder siden",
    title: "Regjeringen holder pressekonferanse om coronasituasjonen",
  },
  {
    src: "https://imbo.vgtv.no/users/vgtv/images/eb8d45cf8693fcd13eadd82226c00ef2.jpg?t[]=386x218q50",
    createdAt: "8 måneder siden",
    title: "På date med Bollestad: Ler av bolle-spørsmål",
  },
  {
    src: "https://imbo.vgtv.no/users/vgtv/images/a622605f88c0e40a287a04ebacce9a89.jpg?t[]=386x218q50",
    createdAt: "10 måneder siden",
    title: "På date med Bastholm: Her tar hun en spansk en",
  },
  {
    src: "https://imbo.vgtv.no/users/vgtv/images/d637fa3bf621ebe6ecd70cbe3cb72bfe.png?t[]=386x218q50",
    createdAt: "ett år siden",
    title: "På kebabdate med Listhaug: Dette sier hun om smilet",
  },
  {
    src: "https://imbo.vgtv.no/users/vgtv/images/04dc469de7f7aeae307cea084ccb3a91.png?t[]=386x218q50",
    createdAt: "ett år siden",
    title: "På date med Moxnes: Presses om kommunisme",
  },
  {
    src: "https://imbo.vgtv.no/users/vgtv/images/06d041a37c49be566ebad385c65fbefa.jpg?t[]=386x218q50",
    createdAt: "ett år siden",
    title: "På date med Solberg: Her måker hun ned Støre",
  },
  {
    src: "https://imbo.vgtv.no/users/vgtv/images/76c7648a86b275c5f1c714f7cbc45d53.jpg?t[]=386x218q50",
    createdAt: "ett år siden",
    title: "På date med Vedum: – Har du blitt stormannsgal?",
  },
  {
    src: "https://imbo.vgtv.no/users/vgtv/images/9ee6bf631df14622f0a0f8b82593ae69.jpg?t[]=386x218q50",
    createdAt: "ett år siden",
    title: "På date med Støre: – Vil i samme hullet som meg",
  },
  {
    src: "https://imbo.vgtv.no/users/vgtv/images/77e4e80beac201b20e15ea198e25d999.jpg?t[]=386x218q50",
    createdAt: "ett år siden",
    title: "På date med Melby: – Har du prøvd hasj?",
  },
  {
    src: "https://imbo.vgtv.no/users/vgtv/images/270dbc7f777b61c456e6730bfbb0e932.jpg?t[]=386x218q50",
    createdAt: "ett år siden",
    title: "På date med Lysbakken: Mener Moxnes er kjekkere",
  },
  {
    src: "https://imbo.vgtv.no/users/vgtv/images/2b00f32ed4ee3af3e69ad308b76bd2e9.jpg?t[]=386x218q50",
    createdAt: "ett år siden",
    title: "På date med Ropstad: – Ikke si noe til kona",
  },
  {
    src: "https://imbo.vgtv.no/users/vgtv/images/9RYlqeG3YLwq.jpg?t[]=386x218q50",
    createdAt: "8 timer siden",
    title: "SE: Siste nytt om tivoliulykken",
  },
  {
    src: "https://imbo.vgtv.no/users/vgtv/images/YDkAjcFoLgeO.jpg?t[]=386x218q50",
    createdAt: "9 timer siden",
    title: "Pressekonferanse  om russiske krigsforbrytelser fra Haag",
  },
  {
    src: "https://imbo.vgtv.no/users/vgtv/images/Lao5ybld2hpV.jpg?t[]=386x218q50",
    createdAt: "10 timer siden",
    title: "Zelenskyj taler i Haag",
  },
  {
    src: "https://imbo.vgtv.no/users/vgtv/images/VDjMGe8EGo2K.jpg?t[]=386x218q50",
    createdAt: "12 timer siden",
    title: "Siste nytt om SAS-streiken",
  },
  {
    src: "https://imbo.vgtv.no/users/vgtv/images/J9hpQctZh39k.jpg?t[]=386x218q50",
    createdAt: "15 timer siden",
    title: "SE NÅ: Siste nytt fra SAS-forhandlingene i Stockholm",
  },
  {
    src: "https://imbo.vgtv.no/users/vgtv/images/ezmNFxAwiaF_.jpg?t[]=386x218q50",
    createdAt: "en dag siden",
    title: "Fortsatt ingen løsning: - Tolk det som du vil",
  },
  {
    src: "https://imbo.vgtv.no/users/vgtv/images/flUaaH1m2A6F.jpg?t[]=386x218q50",
    createdAt: "en dag siden",
    title: "Siste nytt om SAS-streiken",
  },
  {
    src: "https://imbo.vgtv.no/users/vgtv/images/IRaNy3tShPAN.jpg?t[]=386x218q50",
    createdAt: "en dag siden",
    title: "SE: Jens Stoltenberg talte i EU",
  },
  {
    src: "https://imbo.vgtv.no/users/vgtv/images/PcFx8A5VWhaQ.jpg?t[]=386x218q50",
    createdAt: "en dag siden",
    title: "Lyn og torden på Østlandet - her slår lynet ned nå",
  },
  {
    src: "https://imbo.vgtv.no/users/vgtv/images/WUXnlk6sH60w.jpg?t[]=386x218q50",
    createdAt: "2 dager siden",
    title: "SE: Siste om SAS-streiken",
  },
];
