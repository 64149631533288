import { IonGrid } from "@ionic/react";

export default function Divider({ isSmall = false }) {
  return (
    <IonGrid
      style={{
        height: isSmall ? "4px" : "8px",
        width: "100%",
        background: "#F0F2F5",
      }}
    />
  );
}
