import {
  IonAvatar,
  IonButton,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
} from "@ionic/react";

import { chevronForward } from "ionicons/icons";

import type { Banner } from "../../global";

import "./BannerItem.scss";

interface BannerItemProps {
  banner: Banner;
  onClick?: (banner: Banner) => void;
}

const BannerItem: React.FC<BannerItemProps> = ({
  banner,
  onClick = () => void 0,
}: BannerItemProps) => {
  const { title, description, image, textColor, backgroundColor, buttonText } =
    banner;

  return (
    <IonItem
      onClick={() => onClick(banner)}
      lines="none"
      className="banner-item"
      style={{ "--background": backgroundColor, "--color": textColor }}
    >
      <IonAvatar className="avatar" slot="start">
        <IonImg src={image}></IonImg>
      </IonAvatar>
      <header>
        <h2>{title}</h2>
        <p>{description && <IonLabel>{description}</IonLabel>}</p>
      </header>
      <div slot="end" className="action">
        <IonButton fill="clear" style={{ color: textColor }}>
          <IonIcon size="large" icon={chevronForward} />
        </IonButton>
      </div>
    </IonItem>
  );
};

export default BannerItem;
