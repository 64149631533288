import {
  IonButton,
  IonContent,
  IonGrid,
  IonImg,
  IonPage,
  IonRow,
} from "@ionic/react";

import "./VideoCard.css";

const VideoCard = ({
  createdAt = "3 HOURS AGO",
  title = "Surprised by rare sight: - Makes the boat look small",
  src = "/assets/tmp/video-thumbnail.jpeg",
  duration = "00:" + Math.round(Math.random() * 60),
}) => {
  return (
    <IonGrid
      className="p-0 vg-tv-video-card-container"
      style={{
        width: "233px",
        maxWidth: "233px",
        minWidth: "233px",
      }}
    >
      <IonRow
        style={{
          position: "relative",
          borderRadius: "4px",
          overflow: "hidden",
        }}
      >
        <IonImg className="vg-tv-video-card-img" src={src} />

        <div
          style={{
            position: "absolute",
            right: 0,
            left: 0,
            bottom: 0,
            height: "50%",
            backgroundImage:
              "linear-gradient(1turn,rgba(0,0,0,.5) -.92%,rgba(0,0,0,.4958) 10.87%,rgba(0,0,0,.4838) 20.47%,rgba(0,0,0,.4648) 28.2%,rgba(0,0,0,.4397) 34.42%,rgba(0,0,0,.4093) 39.45%,rgba(0,0,0,.3744) 43.64%,rgba(0,0,0,.3359) 47.33%,rgba(0,0,0,.2947) 50.84%,rgba(0,0,0,.2516) 54.52%,rgba(0,0,0,.2074) 58.71%,rgba(0,0,0,.1629) 63.75%,rgba(0,0,0,.12) 69.96%,rgba(0,0,0,.0769) 77.7%,rgba(0,0,0,.0367) 87.29%,transparent 99.08%)",
          }}
        />
        <div
          style={{
            position: "absolute",
            right: "1rem",
            bottom: "0.5rem",
            color: "white",
            fontSize: "12px",
            fontWeight: "bold",
          }}
        >
          {duration}
        </div>
      </IonRow>

      <IonRow className="m-0 p-0">
        <p className="vg-tv-video-card-created-at">{createdAt}</p>
      </IonRow>
      <IonRow className="m-0 p-0">
        <div className="vg-tv-video-card-title">{title}</div>
      </IonRow>
    </IonGrid>
  );
};

export default VideoCard;
