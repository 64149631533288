import React, { useLayoutEffect } from "react";
import {
  IonButton,
  IonContent,
  IonGrid,
  IonImg,
  IonPage,
  IonRow,
} from "@ionic/react";
import VisibilitySensor from "react-visibility-sensor";

import PlayIcon from "../../assets/icons/vg/play.svg";
import PauseIcon from "../../assets/icons/vg/pause.svg";
import AudioIcon from "../../assets/icons/vg/audio.svg";
import ShareIcon from "../../assets/icons/vg/video-share.svg";
import OpenAdIcon from "../../assets/icons/vg/open-ad.svg";
import FullscreenIcon from "../../assets/icons/vg/fullscreen.svg";

import "./VideoPlayer.css";

let interval = null;

async function preloadVideo(src) {
  const res = await fetch(src);
  const blob = await res.blob();
  return URL.createObjectURL(blob);
}

export function getTimeLeft(video, onEnd) {
  const timeLeft = video?.duration - video?.currentTime;

  const minutes = parseInt(timeLeft / 60, 10);
  const seconds = Math.round(timeLeft % 60);

  if (Number.isNaN(minutes) || Number.isNaN(seconds)) {
    setTimeout(() => getTimeLeft(video, onEnd), 100);
    return null;
  }

  clearInterval(interval);
  interval = setInterval(() => getTimeLeft(video, onEnd), 1000);

  const time = `${pad(minutes)}:${pad(seconds)}`;
  onEnd(time);
  return time;
}

function pad(str) {
  return String(str).padStart(2, "0");
}

const VideoPlayer = ({
  prerollId = null,
  preVideo = "https://storage.googleapis.com/brandbird/demo-images/preroll.mp4",
  mainVideo, // = "https://storage.googleapis.com/brandbird/demo-images/mountains.mp4",
  onVisibilityChange = (isVisible) => {},
  onPrerollClick = () => {},
  onPrerollEnd = () => {},
  containmentContainer,
}) => {
  const ref = React.useRef(null);
  const [finalVideo, setFinalVideo] = React.useState();

  const [isPlaying, setIsPlaying] = React.useState(true);
  const [isVisible, setIsVisible] = React.useState(true);
  const [timeLeft, setTimeLeft] = React.useState("00:00");
  const [isPreroll, setIsPreroll] = React.useState(true);

  const [video, setVideo] = React.useState(preVideo);

  React.useEffect(() => {
    // preloadVideo(mainVideo).then(setFinalVideo);
  }, []);

  React.useLayoutEffect(() => {
    // play();
    const video = ref.current;
    if (video) {
      getTimeLeft(video, setTimeLeft);

      video.addEventListener("ended", onVideoEnd, false);
    }

    return () => clearInterval(interval);
  }, []);

  // React.useEffect(() => {
  //   const video = ref.current;
  //   if (video && isPreroll) {
  //     video.addEventListener("ended", onVideoEnd, false);
  //   }
  // }, [ref]);

  React.useEffect(() => {
    if (!isPreroll) {
      setVideo(mainVideo);
      clearInterval(interval);
    }
  }, [isPreroll, mainVideo]);

  function onVideoEnd() {
    console.log("Preroll video ended!");
    onPrerollEnd();
    setIsPreroll(false);
    setVideo(mainVideo);
    const video = ref.current;
    if (video) {
      video.load();
      video.play();
      video.currentTime = 0;
    }
  }

  function onVideoPlayInterval() {
    const video = ref.current;
    if (video && video?.readyState > 0) {
      getTimeLeft(video, setTimeLeft);
    }
  }

  const play = React.useCallback(
    (e) => {
      e.stopPropagation();
      const video = ref.current;

      if (video) {
        video.play();
        setIsPlaying(true);

        if (isPreroll) {
          interval = setInterval(onVideoPlayInterval, 1000);
        }

        setTimeout(() => setIsVisible(false), 3000);
      }
    },
    [ref, isPreroll]
  );

  const pause = React.useCallback(
    (e) => {
      e.stopPropagation();
      const video = ref.current;

      if (video) {
        clearInterval(interval);
        video.pause();
        setIsPlaying(false);
      }
    },
    [ref]
  );

  function stopPropagation(e) {
    e?.stopPropagation();
  }

  return (
    <VisibilitySensor
      partialVisibility
      offset={{ top: 40, bottom: 40 }}
      containment={containmentContainer}
      onChange={isPreroll ? onVisibilityChange : () => {}}
    >
      <div
        id={prerollId}
        style={{
          position: "relative",
          height: "fit-content",
          width: "100%",
          maxWidth: "100%",
          aspectRatio: 16 / 9 + "",
          overflow: "hidden",
        }}
      >
        <video
          ref={ref}
          // controls={!isPreroll}
          autoPlay
          // muted
          playsInline
          style={{
            maxWidth: "100%",

            minWidth: "100%",
            minHeight: "100%",

            /* Setting width & height to auto prevents the browser from stretching or squishing the video */
            width: "auto",
            height: "auto",

            /* Center the video */
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
          }}
        >
          <source id="mp4" src={video} type="video/mp4" />
          <p>Your user agent does not support the HTML5 Video element.</p>
        </video>

        <div
          onClick={() => setIsVisible(!isVisible)}
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            zIndex: 1,
          }}
        />

        <div
          className="vg-video-player-icon"
          style={{
            position: "absolute",
            top: "0",
            left: isVisible ? "0" : "-3rem",
            zIndex: 2,
          }}
          onClick={stopPropagation}
        >
          <img src={AudioIcon} />
        </div>
        <div
          className="vg-video-player-icon"
          style={{
            position: "absolute",
            bottom: "0",
            left: isVisible ? "0" : "-3rem",
            zIndex: 2,
          }}
          onClick={isPlaying ? pause : play}
        >
          <img src={isPlaying ? PauseIcon : PlayIcon} />
        </div>

        {isPreroll && (
          <div
            className="vg-video-player-ad"
            style={{
              position: "absolute",
              bottom: "0",
              left: isVisible ? "3rem" : "0",
              display: "flex",
              zIndex: 2,
            }}
            onClick={(e) => {
              e.stopPropagation();
              onPrerollClick();
            }}
          >
            <img src={OpenAdIcon} />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: "0.5rem",
                zIndex: 2,
              }}
            >
              <p className="vg-video-player-ad-popup-title">
                ANNONSE {timeLeft ? `(${timeLeft})` : ""}
              </p>
              <p className="vg-video-player-ad-popup-subtitle">
                Gå til annonsør
              </p>
            </div>
          </div>
        )}
        <div
          className="vg-video-player-icon"
          style={{
            position: "absolute",
            top: "0",
            right: isVisible ? "0" : "-3rem",
            zIndex: 2,
          }}
          onClick={stopPropagation}
        >
          <img src={ShareIcon} />
        </div>
        <div
          className="vg-video-player-icon"
          style={{
            position: "absolute",
            bottom: "0",
            right: isVisible ? "0" : "-3rem",
            zIndex: 2,
          }}
          onClick={stopPropagation}
        >
          <img src={FullscreenIcon} />
        </div>
      </div>
    </VisibilitySensor>
  );
};

export default VideoPlayer;
